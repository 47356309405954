import React from "react";
import Section from "../layouts/Section";
import SEO from "../layouts/SEO";
import BentoGrid from "../components/Bento/BentoGrid";
import BentoCard from "../components/Bento/BentoCard";
import images from "../data/images";
import BorderBeam from "../components/BorderBeam";
import MeshBg from "../components/MeshBg";
import ContactGrid from "../components/ContactGrid";

const Security = () => {
  return (
    <Section
      id="Security"
      title={"Security"}
      className="lg:mt-[10rem] mt-[8rem]"
      placeholder="Privacy is not just a policy—it's a core value that guides everything we do. Our mission is to provide robust, reliable, and compliant solutions that protect your data and information."
    >
      <SEO title={"Security | InterSight"} />
      <section className="flex-wrap justify-center px-5 lg:px-20 mt-16 lg:mt-20 w-full space-y-16">
        <div className="flex gap-5 justify-center items-center mt-4 pb-4 max-md:flex-wrap max-md:mr-2.5 max-md:max-w-full">
          {/* <Link
            target="_blank"
            to="https://cloud.google.com/find-a-partner/partner/emailanalytics?redirect="
          > */}
          <img
            loading="lazy"
            src={images.google}
            alt={"Google logo"}
            className={"shrink-0 max-w-full h-[4rem]"}
          />
          {/* </Link> */}
          {/* <Link
            target="_blank"
            to="https://appsource.microsoft.com/en-US/marketplace/partner-dir/7a58ba96-b835-4d9c-ac72-32371b0225e2/overview"
          > */}
          <img
            loading="lazy"
            src={images.microsoft}
            alt={"Microsoft logo"}
            className={"shrink-0 max-w-full h-[5rem]"}
          />
          {/* </Link> */}
        </div>
        <BentoGrid className="auto-rows-auto grid-cols-4">
          <div className="col-span-4 lg:col-span-2 p-6 pb-0">
            <h2 className="text-xl font-bold">{"Welcome Aboard"}</h2>
            <p className="max-w-xl mt-2">
              {
                "Intersight doesn’t access private information such as the body of attachments of emails. We obtain specific permission to process email metadata as: from, to, CC, subject, and timestamp that is encrypted and stays in Google or Microsoft clouds as well as the process of transforming it into statistics dashboards."
              }
            </p>
            <img
              loading="lazy"
              src={images.security}
              alt="security"
              className=" rounded-lg object-cover object-center aspect-video mt-8"
            />
          </div>
          <div className="col-span-4 lg:col-span-2">
            <BentoGrid className="auto-rows-auto">
              <div className="col-span-3 flex gap-4 items-center lg:justify-start justify-center">
                <img
                  loading="lazy"
                  src={images.GDPR}
                  alt="GDPR"
                  className="size-24 lg:size-28"
                />
                {/* <img
                loading="lazy"
                  src={images.CCPA}
                  alt="GDPR"
                  className="size-24 lg:size-28"
                /> */}
                <img
                  loading="lazy"
                  src={images.ISO27001}
                  alt="GDPR"
                  className="size-24 lg:size-28"
                />
              </div>
              <BentoCard
                type="motion"
                children={
                  <img
                    src={images.GDPR}
                    alt="GDPR"
                    className="size-24"
                    loading="lazy"
                  />
                }
                index={2}
                description={
                  "We align our security protocols with the guidelines provided by Google and Microsoft which are audited by GDPR and ISO 27001. This ensures that your data benefits from the latest advancements in cybersecurity and data protection."
                }
                descriptionStyle={"font-semibold !text-black"}
                className="col-span-3 bg-primaryBlue/5"
              />
              <BentoCard
                type="motion"
                index={3}
                description={
                  "We are fully committed to ensuring the highest levels of security and data protection, By leveraging the robust security frameworks provided by Google and Microsoft, we ensure that your data is encrypted, protected, and handled with the utmost care. Our proactive approach includes continuous monitoring and regular updates to our security measures, ensuring that we stay ahead of potential threats."
                }
                descriptionStyle={"font-semibold !text-black"}
                className="col-span-3 bg-primaryBlue/5"
              />
            </BentoGrid>
          </div>
        </BentoGrid>
        <div className="relative space-y-8">
          <div className="relative flex px-6 py-12 flex-col items-center justify-center overflow-hidden rounded-2xl border">
            <span className="pointer-events-none whitespace-pre-wrap bg-gradient-to-b from-primaryBlue to-lightBlue/40 bg-clip-text text-center text-4xl lg:text-8xl font-semibold leading-none text-transparent">
              Transparency and Trust
            </span>
            <BorderBeam
              size={250}
              duration={12}
              delay={9}
              colorFrom={"#67B8E3"}
              colorTo={"#669CF4"}
            />
            <p className="lg:max-w-[50%] text-center mt-8 text-lg">
              {
                "At any point in time, you can access your settings account and delete your data, or your data will be deleted automatically by canceling the account.\nWe access historical data up to the last 12 months and storage up to 24 months to improve our services."
              }
            </p>
          </div>
          <ContactGrid />
          <MeshBg className="-top-20 sm:-top-60" />
        </div>
      </section>
    </Section>
  );
};

export default Security;
