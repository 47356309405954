import React, { ReactNode } from "react";
import { BsArrowRight } from "react-icons/bs";
import { Link } from "react-router-dom";

const CTACard = ({
  name,
  className,
  background,
  Icon,
  description,
  descriptionStyle,
  href,
  cta,
}: {
  name?: string;
  className?: string;
  background?: ReactNode;
  Icon?: any;
  description?: string;
  descriptionStyle?: string;
  href?: string;
  cta?: string;
}) => (
  <div
    key={name}
    className={`group relative col-span-3 flex flex-col justify-between overflow-hidden rounded-xl
        bg-white [box-shadow:0_0_0_1px_rgba(0,0,0,.03),0_2px_4px_rgba(0,0,0,.05),0_12px_24px_rgba(0,0,0,.05)]
        transform-gpu  ${className}`}
  >
    <div>{background}</div>
    <div className="pointer-events-none z-10 flex transform-gpu flex-col gap-1 p-6 transition-all duration-300 group-hover:-translate-y-10">
      {Icon ? (
        <Icon className="h-12 w-12 origin-left transform-gpu text-neutral-700 transition-all duration-300 ease-in-out group-hover:scale-75" />
      ) : null}
      <h3 className="text-xl font-semibold text-neutral-700">{name}</h3>
      <p className={`max-w-lg text-neutral-400 ${descriptionStyle}`}>
        {description}
      </p>
    </div>
    {href && cta ? (
      <div
        className={`pointer-events-none absolute bottom-0 flex w-full translate-y-10 transform-gpu flex-row items-center p-4 opacity-0 transition-all duration-300 group-hover:translate-y-0 group-hover:opacity-100`}
      >
        {/* <Button variant="ghost" asChild size="sm" className="pointer-events-auto"> */}
        <Link to={href}>
          {cta}
          <BsArrowRight className="ml-2 h-4 w-4" />
        </Link>
        {/* </Button> */}
      </div>
    ) : null}
    <div className="pointer-events-none absolute inset-0 transform-gpu transition-all duration-300 group-hover:bg-black/[.03]" />
  </div>
);

export default CTACard;
