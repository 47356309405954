import React from "react";
import { PiTrendUpBold } from "react-icons/pi";
import { cn } from "../../utils/utils";

const StatValue = ({
  text,
  isDecreased,
  percent,
}: {
  text: string;
  isDecreased: boolean;
  percent: number;
}) => {
  return (
    <div className="flex gap-2 items-end">
      <span className="text-lg font-bold">{text}</span>
      <div
        className={cn(
          "flex items-center gap-1 rounded-full text-center font-bold",
          isDecreased ? "text-red-700" : "text-green-700"
        )}
      >
        <PiTrendUpBold size={15} className={cn(isDecreased && "-rotate-180")} />
        <span className="items-baseline tabular-nums">{percent}%</span>
      </div>
    </div>
  );
};

export default StatValue;
