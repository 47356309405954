import React from "react";
import Section from "../../layouts/Section";
import SEO from "../../layouts/SEO";
import Blogs from "./Blogs";
import FAQ from "../Home/FAQ";
import CaseStudies from "./CaseStudies";
import BorderBeam from "../../components/BorderBeam";
import Button from "../../components/Button";
import { useNavigate } from "react-router-dom";

const Resources = () => {
  const navigate = useNavigate();
  return (
    <Section id="Resources">
      <SEO title={"Resources | InterSight"} />
      <section className="px-5 lg:px-20 mt-10 lg:mt-16 w-full">
        <Blogs />
        <CaseStudies />
        <div
          className="mx-auto cursor-pointer max-w-7xl relative bg-primaryBlue flex group px-6 py-12 flex-col items-center justify-center overflow-hidden rounded-2xl border"
          onClick={() => navigate("/get-started/sign-up")}
        >
          <span className="pointer-events-none whitespace-pre-wrap bg-gradient-to-b transition-all duration-300 ease-in-out group-hover:scale-75 from-white to-white/5 bg-clip-text text-center text-4xl lg:text-8xl font-semibold leading-none text-transparent">
            Getting Started
          </span>
          <BorderBeam
            size={250}
            duration={12}
            delay={9}
            borderWidth={4}
            colorFrom={"#adffd8"}
            colorTo={"#669CF4"}
          />
          <p className="lg:max-w-[50%] text-center mt-8 text-lg text-white">
            {"Try InterSight with your team for free"}
          </p>
          <Button
            variant="secondary"
            text="Start a Free Trial"
            className="mt-8 !bg-white"
            onClick={() => navigate("/get-started/sign-up")}
          />
        </div>
        <FAQ
          faqs={[
            {
              question: "How does the pricing work for teams",
              answer:
                "Lorem Ipsum is Lorem Ipsum and it is Lorem Ipsum is Lorem Ipsum is Lore Lorem",
              active: true,
            },
            {
              question: "How does the pricing work for teams",
              answer:
                "Lorem Ipsum is Lorem Ipsum and it is Lorem Ipsum is Lorem Ipsum is Lore Lorem",
              active: false,
            },
          ]}
        />
      </section>
    </Section>
  );
};

export default Resources;
