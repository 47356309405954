import React, { useEffect } from "react";
import SEO from "../layouts/SEO";
import HeroSection from "./Home/HeroSection";
import Features from "./Home/Features";
import Tools from "./Home/Tools";
import System from "./Home/System";
import Newsletter from "./Home/Newsletter";
import CommonActions from "../modules/common/actions";
import { useAppDispatch } from "../hooks/useStore";
import About from "./Home/About";
const Home = () => {
  const { getPricing } = CommonActions();
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(getPricing());
  }, []);

  return (
    <section id="home" className="w-full">
      <SEO
        title={
          "InterSight | Innovative Business Intelligence Solutions for Growth"
        }
        description={
          "Inter-Sight specializes in advanced, tailored business intelligence and analytics solutions. Enhance your growth with our expertise in technology, consulting, and innovation."
        }
      />
      <HeroSection />
      <About />
      {/* <About2 /> */}
      <Features />
      <Tools />
      <System />
      {/* <Plans /> */}
      {/* <FAQ /> */}
      {/* <Testimonials /> */}
      <Newsletter />
    </section>
  );
};

export default Home;
