import React from "react";
import { useTranslation } from "react-i18next";
import TermsEN from "./TermsEN";
import TermsHE from "./TermsHE";
import Translate from "../../layouts/Navbar/translate";

const Terms = () => {
  const { i18n } = useTranslation();
  const isEng = i18n.language === "en";
  return (
    <div dir={isEng ? "ltr" : "rtl"} className="relative">
      {isEng ? <TermsEN /> : <TermsHE />}
      <Translate />
    </div>
  );
};

export default Terms;
