import React from "react";
import { useTranslation } from "react-i18next";
import PrivacyEN from "./PrivacyEN";
import PrivacyHE from "./PrivacyHE";
import Translate from "../../layouts/Navbar/translate";

const Privacy = () => {
  const { i18n } = useTranslation();
  const isEng = i18n.language === "en";
  return (
    <div dir={isEng ? "ltr" : "rtl"} className="relative">
      {isEng ? <PrivacyEN /> : <PrivacyHE />}
      <Translate />
    </div>
  );
};

export default Privacy;
