import React from "react";
import Chart from "./components/Chart";
import Table from "./components/Table";
import { ChartsTypeEnum } from "../../../../utils/types";

const dataSource = [
  {
    index: "0",
    rank: "20",
    name: "Edward King",
    email: "edward@example.com",
    receive: {
      value: "20",
      percent: 3,
      isDesc: true,
    },
    messages: {
      value: "15",
      percent: 4,
      isDesc: false,
    },
    involvement: {
      value: "4",
      percent: 23,
      isDesc: true,
    },
    sent: {
      value: "42",
      percent: 40,
      isDesc: true,
    },
    responseTime: {
      value: "3h 49m",
      percent: 15,
      isDesc: true,
    },
  },
  {
    index: "1",
    rank: "1",
    name: "Jacob Lion",
    email: "jacobo@example.com",
    receive: {
      value: "10",
      percent: 13,
      isDesc: true,
    },
    sent: {
      value: "15",
      percent: 55,
      isDesc: true,
    },
    messages: {
      value: "8",
      percent: 8,
      isDesc: false,
    },
    involvement: {
      value: "14",
      percent: 15,
      isDesc: false,
    },
    responseTime: {
      value: "2h 12m",
      percent: 13,
      isDesc: false,
    },
  },
];
const Connections = (props: any) => {
  const { flow, charts } = props;
  const chartData = charts[ChartsTypeEnum.CONNECTIVITY_RANKS];
  const tableData = charts[ChartsTypeEnum.CONNECTIVITY_ANALYSIS];
  // const Table = flow === FlowEnum.OUTFLOW ? OutflowTable : InflowTable;
  return (
    <div className="flex flex-col gap-4 md:gap-8 mt-4 md:mt-8">
      <Chart flow={flow} details={chartData} />
      <Table
        data={dataSource}
        loading={false}
        tableParams={undefined}
        handleTableChange={console.log}
        handlePaginationChange={console.log}
        fetchTeams={console.log}
        flow={flow}
      />
      {/* <div className="grid gap-4 md:grid-cols-1 md:gap-8 lg:grid-cols-3 auto-rows-auto">
        <ParticipantsGraph />
        <div className="col-span-2">
          <Table
            data={dataSource}
            loading={false}
            tableParams={undefined}
            handleTableChange={console.log}
            handlePaginationChange={console.log}
            fetchTeams={console.log}
          />
        </div>
      </div> */}
    </div>
  );
};

export default Connections;
