import React, { Component, ErrorInfo, ReactNode } from "react";

interface ErrorBoundaryProps {
  renderError: (error: Error, errorInfo: ErrorInfo) => ReactNode;
  children: any;
}

interface ErrorBoundaryState {
  hasError: boolean;
  error: Error | null;
  errorInfo: ErrorInfo | null;
}

export default class ErrorBoundary extends Component<
  ErrorBoundaryProps,
  ErrorBoundaryState
> {
  constructor(props: ErrorBoundaryProps) {
    super(props);
    this.state = {
      hasError: false,
      error: null,
      errorInfo: null,
    };
  }

  componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    this.setState({
      hasError: true,
      error: error,
      errorInfo: errorInfo,
    });
  }

  render() {
    const { renderError, children } = this.props;
    const { hasError, error, errorInfo } = this.state;

    if (hasError) {
      return renderError(error!, errorInfo!);
    }

    return children;
  }
}
