import React from "react";
import PageTitle from "../../../components/PageTitle";
import Button from "../../../components/Button";
import { useNavigate } from "react-router-dom";
import ListWithAction from "../../../components/list/ListWithAction";
import Section from "../../../layouts/Section";
import Invoices from "./components/Invoices";

const invoices = [
  {
    invoice: "INV001",
    paymentStatus: "Paid",
    totalAmount: "$250.00",
    paymentMethod: "Credit Card",
    date: "Nov 02, 2024",
  },
  {
    invoice: "INV002",
    paymentStatus: "Pending",
    totalAmount: "$150.00",
    paymentMethod: "Credit Card",
    date: "Nov 02, 2024",
  },
  {
    invoice: "INV003",
    paymentStatus: "Unpaid",
    totalAmount: "$350.00",
    paymentMethod: "Credit Card",
    date: "Nov 02, 2024",
  },
  {
    invoice: "INV004",
    paymentStatus: "Paid",
    totalAmount: "$450.00",
    paymentMethod: "Credit Card",
    date: "Nov 02, 2024",
  },
  {
    invoice: "INV005",
    paymentStatus: "Paid",
    totalAmount: "$550.00",
    paymentMethod: "Credit Card",
    date: "Nov 02, 2024",
  },
  {
    invoice: "INV006",
    paymentStatus: "Pending",
    totalAmount: "$200.00",
    paymentMethod: "Credit Card",
    date: "Nov 02, 2024",
  },
  {
    invoice: "INV007",
    paymentStatus: "Unpaid",
    totalAmount: "$300.00",
    paymentMethod: "Credit Card",
    date: "Nov 02, 2024",
  },
];
const Billing = () => {
  const navigate = useNavigate();
  return (
    <Section className="max-w-7xl mx-auto !mt-4 !p-4">
      <div className="flex flex-wrap justify-between bg-white z-10 pb-4 mb-4 border-b mt-8">
        <PageTitle
          title={"Billing"}
          subTitle={
            "Manage all things billing, including payment info and invoices"
          }
        />
      </div>
      <div className="space-y-4">
        <ListWithAction
          rightNode={
            <Button
              text={"Upgrade plan"}
              onClick={() => navigate("/Dashboard/plans")}
              className="justify-center items-center !py-2 !max-md:px-5 !text-sm"
            />
          }
          leftNode={
            <>
              <span className="block text-sm font-bold leading-6">
                Current plan
              </span>
              <span className="block text-sm font-medium leading-6 text-placeholder">
                You are on the Free plan. Upgrade to Plus to unlock advanced
                features
              </span>
            </>
          }
        />
        {/* Invoices List */}
        <Invoices
          // invoices={[]}
          invoices={invoices}
        />
        <ListWithAction
          rightNode={
            <Button
              text={"Cancel subscription"}
              variant="danger"
              onClick={() => console.log("cancel subscription")}
              className="justify-center items-center !py-2 !max-md:px-5 !text-sm"
            />
          }
          leftNode={
            <>
              <span className="block text-sm font-bold leading-6">
                Payment Method
              </span>
              <span className="block text-sm font-medium leading-6 text-placeholder">
                Renews on 15 January, 2024
              </span>
            </>
          }
        />

        <ListWithAction
          rightNode={
            <Button
              text={"Contact support"}
              variant="secondary"
              onClick={() => navigate("/Dashboard/support-tickets")}
              className="justify-center items-center !py-2 !max-md:px-5 !text-sm"
            />
          }
          leftNode={
            <>
              <span className="block text-sm font-bold leading-6">
                Need anything else?
              </span>
              <span className="block text-sm font-medium leading-6 text-placeholder">
                If you need further help with billing, our support team are here
                to help
              </span>
            </>
          }
        />
      </div>
    </Section>
  );
};

export default Billing;
