// i18n.js
import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import Backend from "i18next-http-backend";
const resources = {
  en: {
    common: require("./locales/en/common.json"),
    privacy: require("./locales/en/privacy.json"),
    terms: require("./locales/en/terms.json"),
    accessibility: require("./locales/en/accessibility.json"),
  },
  he: {
    common: require("./locales/he/common.json"),
    privacy: require("./locales/he/privacy.json"),
    terms: require("./locales/he/terms.json"),
    accessibility: require("./locales/he/accessibility.json"),
  },
};
i18n
  .use(Backend)
  .use(initReactI18next)
  .use(LanguageDetector)
  .init({
    resources,
    lng: "en",
    fallbackLng: "en", // Fallback to English if translation is not available for a language
    debug: process.env.NODE_ENV === "development",
    interpolation: {
      escapeValue: false, // React already escapes values, so no need to escape again
    },
  });

export default i18n;
