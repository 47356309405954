import React from "react";
import { motion } from "framer-motion";
import images from "../../data/images";
import { useNavigate } from "react-router-dom";

const AnimatedLogo = () => {
  const navigate = useNavigate();
  const sloganVariation = {
    initial: { width: 0, opacity: 0, height: 0 },
    animate: {
      width: "8rem",
      opacity: 1,
      height: 60,
      transition: {
        duration: 1,
        ease: "easeInOut",
      },
    },
    hover: {
      width: [0, "8rem"],
      opacity: 1,
      height: [0, 60],
      transition: {
        duration: 2,
        ease: "easeInOut",
        // delay: 1,
      },
    },
  };

  const badgeVariation = {
    initial: { opacity: 0, rotate: 0 },
    animate: {
      opacity: 1,
      rotate: 0,
      transition: {
        duration: 0,
        ease: "linear",
      },
    },
    hover: {
      opacity: 1,
      rotate: [0, 100, 200, 360],
      transition: {
        duration: 2,
        ease: "linear",
      },
    },
  };

  return (
    <motion.div
      onClick={() => navigate("/")}
      className="flex items-center justify-center w-[12rem] cursor-pointer"
      initial="initial"
      animate="animate"
      whileHover="hover"
    >
      <motion.img
        className="object-contain w-[4rem]"
        src={images.LogoBadge}
        variants={badgeVariation}
      />
      <motion.img
        className="object-contain"
        src={images.logoSlogan}
        variants={sloganVariation}
      />
    </motion.div>
  );
};

export default AnimatedLogo;
