import React from "react";
import Input from "./ui/Inputs/Input";
import Button from "./Button";
import { ContactFormValues } from "../utils/types";
import { Link } from "react-router-dom";

interface InputConfig {
  id: number;
  label: string;
  placeholder: string;
  name: string;
  required?: boolean;
  type?: string;
  value: string;
  error?: string;
  textArea?: boolean;
  component?: React.FC<any>;
}

interface FormProps {
  inputs: InputConfig[];
  onSubmit: (values: any) => void;
  onReset?: () => void;
  loading?: boolean;
  formValues: ContactFormValues;
  setFormValues: React.Dispatch<React.SetStateAction<ContactFormValues>>;
  marketingCheck?: boolean;
  submitText: string;
}

const Form: React.FC<FormProps> = ({
  inputs,
  onSubmit,
  onReset,
  loading,
  formValues,
  setFormValues,
  marketingCheck = true,
  submitText,
}) => {
  const [errors, setErrors] = React.useState<Partial<ContactFormValues>>({});

  const handleChange = (
    e: React.ChangeEvent<
      HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement
    >
  ) => {
    const { name, value } = e.target;
    setFormValues((prevValues) => ({
      ...prevValues,
      [name]: value,
    }));
  };

  const validateForm = (): boolean => {
    const newErrors: Partial<ContactFormValues> = {};

    inputs.forEach((input) => {
      if (input.required && !formValues[input.name]) {
        newErrors[input.name] = `${input.label} is required`;
      }
      if (!formValues.email) {
        newErrors.email = "Email is required";
      } else if (!/\S+@\S+\.\S+/.test(formValues.email)) {
        newErrors.email = "Email is invalid";
      }
    });

    setErrors(newErrors);

    return Object.keys(newErrors).length === 0;
  };

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    if (validateForm()) {
      // Submit the form
      // console.log(formValues);
      onSubmit(formValues);
    }
  };

  const handleReset = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setFormValues(formValues);
    setErrors({});
    onReset && onReset();
  };

  return (
    <form onSubmit={handleSubmit} onReset={handleReset}>
      <div className="flex mt-4 flex-wrap">
        {inputs.map((input) => {
          const Component = input.component;
          return (
            <div
              key={input.id}
              //   className={`${input.textArea ? "w-full" : "w-1/2"}  pr-5 mb-5`}
              // className={`lg:${
              //   input.textArea ? "w-full" : "w-1/2"
              // } sm:w-full mb-5`}
              className={`w-full mb-5 sm:w-1/2 ${
                input.textArea ? "sm:w-full" : "sm:w-1/2"
              }`}
            >
              {Component ? (
                <Component
                  label={input.label}
                  name={input.name}
                  value={formValues[input.name]}
                  onChange={handleChange}
                />
              ) : (
                <Input
                  label={input.label}
                  type={input.type}
                  placeholder={input.placeholder}
                  name={input.name}
                  required={input.required}
                  textArea={input.textArea}
                  value={formValues[input.name]}
                  onChange={handleChange}
                  error={errors[input.name]}
                />
              )}
            </div>
          );
        })}
        {marketingCheck ? (
          <>
            <div className="col-span-6">
              <label htmlFor="MarketingAccept" className="flex gap-4">
                <input
                  type="checkbox"
                  id="MarketingAccept"
                  name="marketing_accept"
                  className="size-5 rounded-md border-gray-200 bg-white shadow-sm"
                />

                <span className="text-sm text-gray-700">
                  I want to receive emails about events, product updates and
                  company announcements.
                </span>
              </label>
            </div>
            <div className="col-span-6 mt-4">
              <p className="text-sm text-gray-500">
                By creating an account, you agree to our
                <Link
                  to="/terms-of-service"
                  className="text-gray-700 underline mx-1"
                >
                  terms and conditions
                </Link>
                and
                <Link
                  to="/privacy-policy"
                  className="text-gray-700 underline mx-1"
                >
                  privacy policy.
                </Link>
              </p>
            </div>
          </>
        ) : null}
      </div>

      <div className="flex gap-5 items-center mt-4 text-xl text-center">
        <Button
          text={submitText}
          type={"submit"}
          loading={loading}
          className="justify-center items-center lg:!px-12 !px-2 !py-3 !font-bold text-white bg-primaryBlue !max-md:px-5"
        />
        <Button
          text={"Reset"}
          type={"reset"}
          disabled={loading}
          className="justify-center items-center lg:!px-12 !px-2 !py-3 !border-0 !bg-gray-100 !text-gray-400 !max-md:px-5"
        />
      </div>
    </form>
  );
};

export default Form;
