import * as React from "react";
import { FiPhone } from "react-icons/fi";
import images from "../data/images";
import { YearNow } from "../utils/timezone";
import { Link } from "react-router-dom";
import { MdOutlineLocationOn } from "react-icons/md";
import { HiOutlineEnvelope } from "react-icons/hi2";

const SocialMediaIcon = ({ src }: { src: React.ReactNode }) => (
  <li>
    <Link
      to={"/"}
      className="transition-all duration-300 ease-out flex justify-center items-center hover:scale-110"
    >
      {src}
    </Link>
  </li>
);
const socialMediaIcons: React.ReactNode[] = [
  <img
    src={images.linkedin}
    alt="linkedin"
    className="size-10 object-contain"
  />,
  <img src={images.youtube} alt="youtube" className="size-10 object-contain" />,
  <img src={images.dnsG} alt="dnsG" className="size-10 object-contain" />,
];
const Footer = () => {
  return (
    <div className="flex items-center justify-center lg:mb-8">
      <div className="lg:w-[90%] w-full lg:p-4 lg:rounded-2xl lg:border">
        <footer className="w-full rounded-xl max-md:rounded-b-none relative bg-blue-grt">
          <div className="mx-auto max-w-screen-xl px-4 pb-6 pt-8 sm:px-6 lg:px-8 lg:pt-16">
            <div className="grid grid-cols-1 gap-8 lg:grid-cols-3">
              <div>
                <div className="flex">
                  <img
                    loading="lazy"
                    src={images.logoPrimary}
                    alt="Company Logo"
                    className="w-[12rem]"
                  />
                </div>
                <p className="mt-2 max-w-md leading-relaxed font-bold text-primaryBlue sm:max-w-xs">
                  Business Intelligence from Your Email
                </p>
                <ul className="mt-4 flex gap-1">
                  {socialMediaIcons.map((icon, index) => (
                    <SocialMediaIcon key={index} src={icon} />
                  ))}
                </ul>
              </div>
              <div className="grid grid-cols-1 gap-8 sm:grid-cols-2 md:grid-cols-3 lg:col-span-2">
                <div className="text-start">
                  <p className="text-lg font-medium text-gray-900">About Us</p>
                  <ul className="mt-8 space-y-4 text-sm">
                    <li>
                      <Link
                        className="text-gray-700 transition hover:text-gray-700/75"
                        to="#"
                      >
                        Company History
                      </Link>
                    </li>
                    <li>
                      <Link
                        className="text-gray-700 transition hover:text-gray-700/75"
                        to="#"
                      >
                        Meet the Team
                      </Link>
                    </li>
                    <li>
                      <Link
                        className="text-gray-700 transition hover:text-gray-700/75"
                        to="#"
                      >
                        Employee Handbook
                      </Link>
                    </li>
                  </ul>
                </div>
                <div className="text-start">
                  <p className="text-lg font-medium text-gray-900">Company</p>
                  <ul className="mt-8 space-y-4 text-sm">
                    <li>
                      <Link
                        className="text-gray-700 transition hover:text-gray-700/75"
                        to="/Resources"
                      >
                        Resources
                      </Link>
                    </li>
                    <li>
                      <Link
                        className="text-gray-700 transition hover:text-gray-700/75"
                        to="/Security"
                      >
                        Security
                      </Link>
                    </li>
                    <li>
                      <Link
                        className="text-gray-700 transition hover:text-gray-700/75"
                        to="/privacy-policy"
                      >
                        Privacy Policy
                      </Link>
                    </li>
                    <li>
                      <Link
                        className="text-gray-700 transition hover:text-gray-700/75"
                        to="/terms-of-service"
                      >
                        Terms and Condition
                      </Link>
                    </li>
                    {/* <li>
                      <Link
                        className="text-gray-700 transition hover:text-gray-700/75"
                        to="/terms-of-use"
                      >
                        Terms of Use
                      </Link>
                    </li> */}
                    <li>
                      <Link
                        className="text-gray-700 transition hover:text-gray-700/75"
                        to="/accessibility-statement"
                      >
                        Accessibility
                      </Link>
                    </li>
                  </ul>
                </div>
                <div className="text-start">
                  <p className="text-lg font-medium text-gray-900">
                    Contact Us
                  </p>
                  <ul className="mt-8 space-y-4 text-sm">
                    <li>
                      <Link
                        className="flex items-center justify-center gap-1.5 ltr:sm:justify-start rtl:sm:justify-end"
                        to="mailto:Service@Inter-sight.com"
                      >
                        <HiOutlineEnvelope size={24} />
                        <span className="flex-1 text-gray-700">
                          service@inter-sight.com
                        </span>
                      </Link>
                    </li>
                    <li>
                      <Link
                        className="flex items-center justify-center gap-1.5 ltr:sm:justify-start rtl:sm:justify-end"
                        to="tel:+972502000111"
                        dir="ltr"
                      >
                        <FiPhone size={24} />
                        <span className="flex-1 text-gray-700">
                          +972502000111
                        </span>
                      </Link>
                    </li>
                    <li className="flex items-start justify-center gap-1.5 ltr:sm:justify-start rtl:sm:justify-end">
                      <MdOutlineLocationOn size={24} />
                      <address className="flex-1 not-italic text-gray-700">
                        Arnon 27, Ramat Gan
                      </address>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="mt-12 border-t border-gray-100 pt-6">
              <div className="sm:flex sm:justify-between text-start">
                <div className="text-sm flex gap-2">
                  <Link
                    to={"/terms-of-service"}
                    className="font-bold"
                  >
                    Terms
                  </Link>
                  <span>&middot;</span>
                  <Link
                    to={"/privacy-policy"}
                    className="font-bold"
                  >
                    Privacy Policy
                  </Link>
                </div>
                <p className="mt-4 text-start text-sm sm:order-first sm:mt-0">
                  Copyright <strong>©{YearNow} InterSight LTD.</strong> All
                  rights reserved.
                </p>
              </div>
              <Link
                to={"https://www.codit.co.il/"}
                target="_blank"
                className="self-stretch flex items-end gap-x-2 my-auto font-bold mt-2"
              >
                <span className="text-xs">Powered by</span>
                <img
                  loading="lazy"
                  src={images.codit}
                  className="w-12 grayscale"
                  alt="codit software"
                />
              </Link>
            </div>
          </div>
        </footer>
      </div>
    </div>
  );
};

export default Footer;
