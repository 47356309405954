import React, { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import AuthActions from "../../modules/auth/actions";
import { useAppDispatch } from "../../hooks/useStore";
import { useAlertsContext } from "../../hooks/useAlertsContext";
import { useMsal } from "@azure/msal-react";
import {
  EventType,
  InteractionRequiredAuthError,
  InteractionType,
} from "@azure/msal-browser";
import { tokenRequest } from "../../utils/msAuthConfig";
import { CgSpinner } from "react-icons/cg";

const MSCallback = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { msLogIn } = AuthActions();
  const dispatch = useAppDispatch();
  const { toastError } = useAlertsContext();
  const { instance, accounts } = useMsal();

  useEffect(() => {
    // const searchParams = new URLSearchParams(location.search);
    // const code = searchParams.get("code");
    // const error = searchParams.get("error");
    const hashParams = new URLSearchParams(location.hash.substring(1));
    const code = hashParams.get("code");
    const error = hashParams.get("error");
    console.log("useEffect", { s: JSON.stringify(location), hashParams });

    const handleMSLogin = async (code: string) => {
      console.log("handleMSLogin", { code });
      try {
        const callbackId = instance.addEventCallback(async (message) => {
          switch (message.eventType) {
            case EventType.LOGIN_SUCCESS:
            case EventType.ACQUIRE_TOKEN_SUCCESS:
              console.log({ message });
              if (message.interactionType === InteractionType.Redirect) {
                const accessTokenRequest = {
                  ...tokenRequest,
                  account: accounts[0],
                };
                console.log({ accessTokenRequest });
                try {
                  const accessTokenResponse = await instance.acquireTokenSilent(
                    accessTokenRequest
                  );
                  const accessToken = accessTokenResponse.accessToken;
                  dispatch(msLogIn(accessToken, () => navigate("/Dashboard")));
                } catch (error) {
                  console.log("error", { error });
                  if (error instanceof InteractionRequiredAuthError) {
                    // Token is expired
                    toastError("Token Expired");
                    await instance.loginRedirect(tokenRequest);
                  } else {
                    toastError(
                      error || "Acquire token failed! please try again"
                    );
                    throw error;
                  }
                }
                // await instance
                //   .acquireTokenSilent(accessTokenRequest)
                //   .then(async (response) => {
                //     console.log({ response });
                //     // Acquire token silent success
                //     const accessToken = response.accessToken;
                //     // Call your API with token
                //     dispatch(
                //       msLogIn(accessToken, () => navigate("/Dashboard"))
                //     );
                //   })
                //   .catch((error) => {
                //     console.log({ error });
                //     toastError(error);
                //   });
              }
              break;
            default:
              break;
          }
        });
        // onClose();
      } catch (error) {
        console.log("handleMSLogin -> catch", error);
        toastError("Failed to login!");
        navigate("/");
      }
    };

    if (code) {
      handleMSLogin(code);
    } else if (error) {
      toastError("Failed to login!");
      console.log("handleMSLogin", error);
      navigate("/");
    } else if (!code && error) {
      navigate("/");
    }

    // Redirect back to your main app page
    else navigate("/");
  }, []);

  return (
    <div className="p-4">
      <div className="flex gap-2 items-center">
        <CgSpinner className="animate-spin size-8 text-primaryBlue" />
        <h1 className="animate-shine text-lg font-bold">Redirecting...</h1>
      </div>
      <p>Please wait a while. You are being redirected.</p>
    </div>
  );
};

export default MSCallback;
