import { createStore, applyMiddleware } from "redux";
import { thunk } from "redux-thunk";
import rootReducers from "./reducer";
import storage from "./storage";
import loggerMiddleware from "./logger";

const middlewareEnhancer = applyMiddleware(loggerMiddleware, thunk);
const store = createStore(
  rootReducers,
  storage.loadFromLocalStorage(),
  middlewareEnhancer
);

// listen for store changes and use saveToLocalStorage to
// save them to localStorage
store.subscribe(() => storage.saveToLocalStorage(store.getState()));

export default store;
