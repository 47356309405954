import React, { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import AuthActions from "../../modules/auth/actions";
import { useAppDispatch } from "../../hooks/useStore";
import { useAlertsContext } from "../../hooks/useAlertsContext";
import { CgSpinner } from "react-icons/cg";

const GoogleCallback = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { googleLogIn } = AuthActions();
  const dispatch = useAppDispatch();
  const { toastError } = useAlertsContext();

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const code = searchParams.get("code");
    const error = searchParams.get("error");
    const handleGoogleLogin = async (code: string) => {
      console.log("handleGoogleLogin", { code });
      try {
        dispatch(googleLogIn(code, () => navigate("/Dashboard")));
        // onClose();
      } catch (error) {
        console.log("handleGoogleLogin -> catch", error);
        toastError("Failed to login!");
        navigate("/");
      }
    };

    if (code) {
      handleGoogleLogin(code);
    } else if (error) {
      toastError("Failed to login!");
      console.log("handleGoogleLogin error", error);
      navigate("/");
    }

    // Redirect back to your main app page
    else navigate("/");
  }, []);

  return (
    <div className="p-4">
      <div className="flex gap-2 items-center">
        <CgSpinner className="animate-spin size-8 text-primaryBlue" />
        <h1 className="animate-shine text-lg font-bold">Redirecting...</h1>
      </div>
      <p>Please wait a while. You are being redirected.</p>
    </div>
  );
};

export default GoogleCallback;
