import React from "react";
import Section from "../layouts/Section";
import SEO from "../layouts/SEO";
import CommonSelectors from "../modules/common/selectors";
import { useParams } from "react-router-dom";
import { Pricing } from "../utils/types";

const Subscribe = () => {
  const { pricingId } = useParams();
  const { pricingTiers } = CommonSelectors();
  const plan = pricingTiers.find((tier: Pricing) => tier.id === pricingId);

  return (
    <Section title={plan.title}>
      <SEO
        title={"Subscription | InterSight"}
        description={`${plan.title}, ${plan.features.join(", ")}`}
      />
    </Section>
  );
};

export default Subscribe;
