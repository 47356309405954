import React, { useEffect, useState } from "react";
import { FaArrowLeftLong } from "react-icons/fa6";
import { useLocation, useNavigate } from "react-router-dom";
import images from "../../../data/images";
import { GetPaymentForm } from "../../../modules/common/service";
import { SubscriptionType } from "../../../utils/types";

const Payment = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [loading, setLoading] = useState(true);
  const [paymentURL, setPaymentURL] = useState("");
  const { plan, pricingId, paymentType } = location.state;
  useEffect(() => {
    let isMounted = false;
    const getIframe = async () => {
      setLoading(true);
      await GetPaymentForm({ pricingId, paymentType })
        .then((response) => {
          setPaymentURL(response?.data?.url || "");
          console.log(response?.data?.url);
        })
        .catch((e) => console.log("GetDashboardPricing", e?.data?.message))
        .finally(() => setLoading(false));
    };
    if (!isMounted) {
      getIframe();
    }

    return () => {
      isMounted = true;
    };
  }, [pricingId]);
  return (
    <div className="h-[100dvh] w-[100dvw] flex max-md:flex-col bg-gray-50">
      <div className="h-full max-md:h-fit w-[50%] max-md:w-full flex p-8 space-y-4 border justify-end">
        <div className="min-w-[50%] max-md:w-full flex flex-col space-y-4">
          <div
            className="flex border items-center size-fit w-full gap-2 cursor-pointer transition-all duration-300 ease-in-out group"
            onClick={() => navigate(-1)}
          >
            <FaArrowLeftLong className="text-primaryBlue group-hover:mr-1 group-hover:text-black transition-all duration-300 ease-in-out" />
            <span className="hidden group-hover:opacity-1 group-hover:block transition-opacity duration-300 ease-in-out">
              back
            </span>
            <img
              src={images.logoPrimary}
              alt="company logo"
              className=" object-contain w-24 py-1 group-hover:hidden transition-all duration-300 ease-in-out"
            />
          </div>
          <h1 className="font-bold text-lg text-placeholder">
            Subscribe to InterSight {plan?.title}
          </h1>
          <div className="mb-1 flex items-end">
            <span className="text-xl font-medium text-slate-900">
              ₪
            </span>
            <span className="text-4xl font-bold text-slate-900">
              {paymentType === SubscriptionType.ANNUAL
                ? plan.priceYearly * 12
                : plan.pricingMonthly}
            </span>
            <div className="w-5 ml-2 text-xs">
              {paymentType === SubscriptionType.ANNUAL
                ? "per year"
                : "per month"}
            </div>
          </div>
          <div className="pb-4 border-b border-gray-400 text-slate-500">
            <div className="flex w-full items-end justify-between">
              <div>
                <span className="font-bold">{plan?.title}</span>
              </div>
              <div>
                <span>₪</span>
                <span>
                  {paymentType === SubscriptionType.ANNUAL
                    ? plan.priceYearly * 12
                    : plan.pricingMonthly}
                </span>
              </div>
            </div>
            {/* <div className="flex w-full justify-between pb-2 !text-slate-300 text-xs">
              <span>Billed monthly</span>
              <span>
                ₪
                {paymentType === SubscriptionType.ANNUAL
                  ? plan.priceYearly * 12
                  : plan.pricingMonthly}
                {" per mailbox"}
              </span>
            </div> */}
          </div>
          <div className="flex items-end justify-between pb-4 font-bold text-slate-500">
            <span>Total</span>
            <span className="text-lg">
              ₪
              {paymentType === SubscriptionType.ANNUAL
                ? plan.priceYearly * 12
                : plan.pricingMonthly}
            </span>
          </div>
        </div>
      </div>
      <div className="h-full w-[50%] max-md:w-full flex p-4 bg-white shadow-sm">
        <iframe
          title="subscription"
          src={paymentURL}
          //   sandbox="allow-scripts allow-modal"
          loading="eager"
        ></iframe>
      </div>
    </div>
  );
};

export default Payment;
