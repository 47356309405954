import React from "react";
import { InviteStatus, TeammateDT } from "../../../../utils/types";
import Icon from "../../../../components/ui/Buttons/Icon";
import { BsThreeDotsVertical } from "react-icons/bs";
import { Dropdown, MenuProps } from "antd";
import useActions from "../../hooks/useActions";

const Actions = ({
  team,
  fetchTeams,
}: {
  team: TeammateDT;
  fetchTeams: Function;
}) => {
  const { status, id } = team;
  const { ResendInvitation, RevokeInvitation, loading } = useActions();

  const handleAction = (action: string) => {
    switch (action) {
      case "Revoke":
        RevokeInvitation(id, () => fetchTeams());
        break;
      case "Resend":
        ResendInvitation(id);
        break;
      default:
        break;
    }
  };
  const items: MenuProps["items"] = [
    status.id === InviteStatus.ACTIVE
      ? {
          key: "1",
          label: (
            <button
              disabled={loading}
              className="p-2"
              onClick={() => handleAction("Revoke")}
            >
              Remove Member
            </button>
          ),
        }
      : null,
    status.id === InviteStatus.INVITE_SENT
      ? {
          key: "2",
          label: (
            <button
              disabled={loading}
              className="p-2"
              onClick={() => handleAction("Resend")}
            >
              Resend Invitation
            </button>
          ),
        }
      : null,
    status.id === InviteStatus.INVITE_SENT
      ? {
          key: "3",
          label: (
            <button
              disabled={loading}
              className="p-2"
              onClick={() => handleAction("Revoke")}
            >
              Revoke Invitation
            </button>
          ),
        }
      : null,
  ];
  return (
    <Dropdown menu={{ items }} placement="bottomLeft">
      <div>
        <Icon>
          <BsThreeDotsVertical />
        </Icon>
      </div>
    </Dropdown>
  );
};

export default Actions;
