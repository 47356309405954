import React from "react";
import {
  CardContentFilter,
  IChartDetails,
  ITopics,
} from "../../../../../utils/types";
import CardWrapper from "../../../../../components/CardWrapper";
import { ScrollArea } from "../../../../../components/ui/scroll-area";
import TopicsList from "./components/TopicsList";
import LoadingChart from "../../LoadingChart";
import { MdErrorOutline } from "react-icons/md";

const Scope = ({
  filters,
  className,
  details,
}: {
  filters: CardContentFilter;
  className: string;
  details: IChartDetails<ITopics>;
}) => {
  const { data, error, loading } = details;
  return (
    <CardWrapper
      className={className}
      title={"Topics menu"}
      description={"Raise awareness about main subjects users working on"}
      tooltip={"Subjects presented by period selected"}
      content={
        <>
          {loading ? (
            <LoadingChart />
          ) : !(error && error.isOccurred) ? (
            <ScrollArea className="h-0 min-h-[400px]">
              <TopicsList data={data} />
              {/* <TreemapChart data={topicsData2} filters={filters} /> */}
            </ScrollArea>
          ) : null}
          {!loading && error && error.isOccurred ? (
            <div className="flex gap-2 items-center p-2 bg-gray-50 rounded-lg">
              <MdErrorOutline className="text-red-700" />
              <p className="text-red-700">{error?.message}</p>
            </div>
          ) : null}
        </>
      }
    />
  );
};

export default Scope;
