export default function MicrosoftIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 16 16"
      width="16"
      height="16"
    >
      <g clipPath="url(#microsoft__a)">
        <path fill="#F25022" d="M8.119.762H1.262v6.857h6.857V.762Z"></path>
        <path fill="#00A4EF" d="M8.119 8.38H1.262v6.858h6.857V8.381Z"></path>
        <path fill="#7FBA00" d="M15.738.762H8.881v6.857h6.857V.762Z"></path>
        <path fill="#FFB900" d="M15.738 8.38H8.881v6.858h6.857V8.381Z"></path>
      </g>
      <defs>
        <clipPath id="microsoft__a">
          <path fill="#fff" d="M.5 0h16v16H.5z"></path>
        </clipPath>
      </defs>
    </svg>
  );
}
