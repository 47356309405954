import React from "react";

const TeamStatus = ({
  status,
  small,
}: {
  status: boolean;
  small?: boolean;
}) => {
  const defaultClass = `font-medium px-4 py-1 rounded-${
    small ? "md !px-2" : "lg"
  } border`;
  if (status) {
    return (
      <span
        className={`${defaultClass} bg-green-100 text-green-800 border-green-300`}
      >
        {"Active"}
      </span>
    );
  } else {
    return (
      <span
        className={`${defaultClass} bg-red-100 text-red-600 border-red-300`}
      >
        {"Not Active"}
      </span>
    );
  }
};

export default TeamStatus;
