import React from "react";
import { CgSpinner } from "react-icons/cg";

const Loading = () => {
  return (
    <div className="p-4">
      <div className="flex gap-2 items-center">
        <CgSpinner className="animate-spin size-8 text-primaryBlue" />
        <h1 className="animate-shine text-lg font-bold">Loading...</h1>
      </div>
    </div>
  );
};

export default Loading;
