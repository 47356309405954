import React, { useEffect } from "react";
import useTeams from "./hooks/useTeams";
import Filters from "./components/Filters";
import TeamsTable from "./components/TeamsTable";
import AuthSelectors from "../../modules/auth/selectors";
import { Navigate } from "react-router-dom";
import Section from "../../layouts/Section";

const Teams = () => {
  const {
    data,
    loading,
    tableParams,
    searchTerm,
    dateRange,
    setDateRange,
    handleSearch,
    handleTableChange,
    handlePaginationChange,
    FetchTeams,
  } = useTeams();

  useEffect(() => {
    FetchTeams();
  }, [JSON.stringify(tableParams), searchTerm, dateRange]);
  const { isAdmin } = AuthSelectors();

  return isAdmin ? (
    <Section className="max-w-7xl mx-auto !mt-4 !pt-4">
      <Filters
        searchTerm={searchTerm}
        handleSearch={handleSearch}
        setDateRange={setDateRange}
        fetchTeams={() => FetchTeams()}
      />
      <TeamsTable
        data={data}
        loading={loading}
        tableParams={tableParams}
        handleTableChange={handleTableChange}
        handlePaginationChange={handlePaginationChange}
        fetchTeams={() => FetchTeams()}
      />
    </Section>
  ) : (
    <Navigate to={"/Dashboard"} />
  );
};

export default Teams;
