import React from "react";
import { Badge } from "../../../../components/ui/badge";
import { Download } from "lucide-react";
import CardWrapper from "../../../../components/CardWrapper";
import { ScrollArea } from "../../../../components/ui/scroll-area";

type InvoiceProp = {
  invoice: string;
  paymentStatus: string;
  totalAmount: string;
  paymentMethod: string;
  date: string;
};
const Invoices = ({ invoices }: { invoices: InvoiceProp[] }) => {
  return (
    <CardWrapper
      className="col-span-2"
      title={"Latest Billing"}
      description="Here is your recent invoices history"
      innerClassName="flex flex-1"
      content={
        invoices.length === 0 ? (
          <div className="flex flex-1 py-4 items-center justify-center rounded-lg border border-dashed border-gray-300 shadow-sm">
            <div className="flex flex-col items-center gap-1 text-center">
              <h3 className="text-xl font-bold tracking-tight">
                No invoices available
              </h3>
              <p className="text-sm text-muted-foreground">
                You can start viewing as soon as you has a renew.
              </p>
            </div>
          </div>
        ) : (
          <div className="flex flex-1 flex-col gap-2">
            <ScrollArea className="h-0 min-h-[400px]">
              {invoices.map((invoice) => (
                <div
                  key={invoice.invoice}
                  className="border lg:p-4 max-md:py-2 rounded-md grid lg:grid-cols-4 items-end w-full"
                >
                  <h3 className="font-bold">{invoice.invoice}</h3>
                  <div className="flex gap-2 items-end">
                    {/* <span className="text-placeholder text-xs">
                    {invoice.paymentMethod}
                  </span> */}
                    <div>
                      <Badge>{invoice.paymentStatus}</Badge>
                    </div>
                  </div>
                  <span className="font-bold block max-md:text-sm">
                    {invoice.totalAmount}
                  </span>
                  <div className="flex gap-2 items-end lg:place-items-end">
                    <span className="font-bold text-placeholder text-xs">
                      {invoice.date}
                    </span>
                    <Download
                      size={20}
                      onClick={() => console.log("download")}
                    />
                  </div>
                </div>
              ))}
            </ScrollArea>
          </div>
        )
      }
    />
  );
};

export default Invoices;
