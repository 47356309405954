import React from "react";
import { BsArrowUpRight } from "react-icons/bs";

interface IconProps {
  className?: string;
}

const ArrowIcon = ({ className }: IconProps) => {
  return (
    <div className={`transition-all duration-300 ease-out flex justify-center items-center p-2 w-10 h-10 rounded-full bg-primaryBlue text-white border border-primaryBlue hover:bg-transparent hover:text-primaryBlue hover:border-primaryBlue ${className}`}>
      <BsArrowUpRight />
    </div>
  );
};
export default ArrowIcon;
