import React from "react";
import ErrorBoundary from "./ErrorBoundary";
import { Outlet, useLocation } from "react-router-dom";
import Footer from "./Footer";
import Fallback from "./Fallback";
import ScrollToTop from "../hooks/ScrollToTop";
import Navbar from "./Navbar";

const HomeLayout = () => {
  const location = useLocation();
  // const [reveal, setReveal] = React.useState(true);
  const main = React.useRef(null);
  // React.useLayoutEffect(() => {
  //   const handleDOMContentLoaded = () => {
  //     setTimeout(() => {
  //       setReveal(false);
  //     }, 1500);
  //   };
  //   document.addEventListener("readystatechange", () => {
  //     handleDOMContentLoaded();
  //   });
  //   // return () => {
  //   //   document.removeEventListener("readystatechange", handleDOMContentLoaded);
  //   // };
  // }, []);
  // const exclusionArray = [
  //   "/privacy-policy",
  //   "/terms-of-service",
  //   "/accessibility-statement",
  // ];
  // const isHidden = exclusionArray.indexOf(location.pathname) < 0;
  return (
    <ErrorBoundary renderError={Fallback}>
      <ScrollToTop />
      <Navbar />
      {/* {reveal && isHidden ? <Splash /> : null} */}
      <div ref={main} className="min-h-dvh">
        <Outlet key={location.pathname} />
      </div>
      <Footer />
    </ErrorBoundary>
  );
};

export default HomeLayout;
