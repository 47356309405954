import CardWrapper from "../../../../../components/CardWrapper";
import React from "react";
import Graph from "react-vis-network-graph";
import { cn } from "../../../../../utils/utils";
import LoadingChart from "../../../../../pages/Dashboard/components/LoadingChart";
import NeedSubscription from "../../../../../pages/Dashboard/components/NeedSubscription";
import { MdErrorOutline } from "react-icons/md";

const options = (nodeColor) => ({
  autoResize: true,
  clickToUse: false,
  layout: {
    hierarchical: false,
    // hierarchical: true,
  },
  edges: {
    color: "#000000",
    arrows: {
      to: {
        enabled: false,
        scaleFactor: 1,
      },
    },
  },
  nodes: {
    color: nodeColor,
    font: {
      color: "#fff",
    },
  },
});

const Chart = ({ flow, details }) => {
  const { data, error, loading, needSubscription } = details;

  // const nodes = [
  //   { id: "Ross", group: 1 },
  //   { id: "Rachel", group: 2 },
  //   { id: "Monica", group: 1 },
  //   { id: "Chandler", group: 2 },
  //   { id: "Joey", group: 3 },
  //   { id: "Phoebe", group: 3 },
  // ];
  // const links = [
  //   { source: "Ross", target: "Rachel", value: 1 },
  //   { source: "Monica", target: "Chandler", value: 1 },
  //   { source: "Joey", target: "Phoebe", value: 1 },
  //   { source: "Ross", target: "Monica", value: 1 },
  //   { source: "Rachel", target: "Chandler", value: 1 },
  // ];
  // // Define custom colors for each group
  // const colorMap = {
  //   1: "#ff6666", // Group 1 - Red
  //   2: "#66b3ff", // Group 2 - Blue
  //   3: "#66ff66", // Group 3 - Green
  // };
  return (
    <CardWrapper
      title="Connectivity Ranks"
      description="Find out the level of connections between users"
      tooltip="Calculated by Users connectivity ranking scores"
      content={
        <div className="relative">
          {!loading && !error?.isOccurred && needSubscription ? (
            <NeedSubscription title="Connectivity Ranks" />
          ) : null}
          {loading ? (
            <LoadingChart />
          ) : !(error && error.isOccurred) ? (
            <div
              className={cn(
                "grid auto-rows-auto gap-8 divide-x lg:divide-gray-300",
                data?.length > 2 ? "grid-cols-3" : "grid-cols-2",
                "max-md:grid-cols-1"
              )}
            >
              {data?.map((state, index) => {
                const { graph, events, title, color } = state;
                return (
                  <div className="flex flex-col gap-2 items-center" key={index}>
                    {/* <NetworkGraph nodes={nodes} links={links} colorMap={colorMap} /> */}
                    <Graph
                      graph={graph}
                      options={options(color)}
                      events={events}
                      style={{ height: "250px" }}
                    />
                    <div className="text-xs mt-2 flex gap-2 items-center">
                      <div
                        className={"size-2 rounded-sm"}
                        style={{
                          backgroundColor: color,
                        }}
                      />
                      <span className="max-md:text-[0.5rem]">{title}</span>
                    </div>
                  </div>
                );
              })}
            </div>
          ) : null}
          {!loading && error && error.isOccurred ? (
            <div className="flex gap-2 items-center p-2 bg-gray-50 rounded-lg">
              <MdErrorOutline className="text-red-700" />
              <p className="text-red-700">{error?.message}</p>
            </div>
          ) : null}
        </div>
      }
    />
  );
};

export default Chart;
