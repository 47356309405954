import React, { useMemo, useState } from "react";
import { Dropdown, Menu, Button, DatePicker, DatePickerProps } from "antd";
import dayjs, { Dayjs } from "dayjs";
import { CiCalendar } from "react-icons/ci";
import { DateType } from "../../../../utils/types";
import { cn } from "../../../../utils/utils";

const DatePickerChunks = ({
  selectedDates,
  onChange,
}: {
  selectedDates: DateType;
  onChange: (value: DateType) => void;
}) => {
  const isValid =
    selectedDates &&
    dayjs(selectedDates[0]).isValid() &&
    dayjs(selectedDates[1]).isValid();

  const [picker, setPicker] = useState<"week" | "month" | "quarter" | "year">(
    "week"
  );

  const [dates, setDates] = useState<DateType>(
    isValid ? selectedDates : [dayjs().subtract(7, "days"), dayjs()]
  );
  const selectedDate = useMemo(
    () => ({
      from:
        dates && dates[0] ? dayjs(dates[0]).format("YYYY-MM-DD") : undefined,
      to: dates && dates[1] ? dayjs(dates[1]).format("YYYY-MM-DD") : undefined,
    }),
    [dates]
  );

  const handlePresetClick: DatePickerProps["onChange"] = (_, dateString) => {
    let start: Dayjs;
    let end: Dayjs;
    if (typeof dateString !== "string") {
      // Handle cases where dateString is an array or something unexpected
      console.error("Unexpected dateString format:", dateString);
      return;
    }
    switch (picker) {
      case "week":
        {
          // Format: "YYYY-WW"
          const match = dateString.match(/^(\d{4})-(\d+)(st|nd|rd|th)$/);
          if (match) {
            const year = parseInt(match[1], 10);
            const week = parseInt(match[2], 10);
            // Start and end of the specific week
            start = dayjs().year(year).week(week).startOf("week");
            end = start.endOf("week");
          } else {
            console.error("Invalid week format:", dateString);
            return;
          }
        }
        break;
      case "month":
        // Format: "YYYY-MM"
        start = dayjs(dateString, "YYYY-MM").startOf("month");
        end = dayjs(dateString, "YYYY-MM").endOf("month");
        break;
      case "quarter":
        // Format: "YYYY-Qx"
        {
          const match = dateString.match(/^(\d{4})-Q(\d)$/);
          if (match) {
            const year = parseInt(match[1], 10);
            const quarter = parseInt(match[2], 10);

            // Start and end of the specific quarter
            start = dayjs().year(year).quarter(quarter).startOf("quarter");
            end = dayjs().year(year).quarter(quarter).endOf("quarter");
          } else {
            console.error("Invalid quarter format:", dateString);
            return;
          }
        }
        break;
      case "year":
        // Format: "YYYY"
        start = dayjs(dateString, "YYYY").startOf("year");
        end = dayjs(dateString, "YYYY").endOf("year");
        break;
    }
    const newDates: DateType = [start, end];
    setDates(newDates); // Update local dates state
    onChange(newDates); // Call onChange with the date range
  };
  const handlePicker = (picker: "week" | "month" | "quarter" | "year") => {
    setPicker(picker);
  };
  const presetsMenu = (
    <Menu>
      <div className="flex flex-col gap-2 p-2">
        <div className="space-x-1 divide-x divide-gray-200 flex">
          <button
            className="flex-grow p-2 text-center cursor-pointer "
            onClick={() => handlePicker("week")}
          >
            <span
              className={cn(
                picker === "week" && "font-bold text-primaryBlue",
                ""
              )}
            >
              Week
            </span>
          </button>
          <button
            className="flex-grow p-2 text-center cursor-pointer"
            onClick={() => handlePicker("month")}
          >
            <span
              className={cn(
                picker === "month" && "font-bold text-primaryBlue",
                ""
              )}
            >
              Month
            </span>
          </button>
          <button
            className="flex-grow p-2 text-center cursor-pointer"
            onClick={() => handlePicker("quarter")}
          >
            <span
              className={cn(
                picker === "quarter" && "font-bold text-primaryBlue",
                ""
              )}
            >
              Quarter
            </span>
          </button>
          <button
            className="flex-grow p-2 text-center cursor-pointer"
            onClick={() => handlePicker("year")}
          >
            <span
              className={cn(
                picker === "year" && "font-bold text-primaryBlue",
                ""
              )}
            >
              Year
            </span>
          </button>
        </div>
        <div className="w-full">
          <DatePicker
            picker={picker}
            onChange={handlePresetClick}
            value={dates[0] || undefined}
            className="w-full custom-picker"
            allowClear={false}
            inputReadOnly
          />
        </div>
      </div>
    </Menu>
  );

  return (
    <Dropdown overlay={presetsMenu} trigger={["click"]}>
      <Button className="min-h-[2.5rem] rounded-xl flex items-center">
        <CiCalendar size={20} />
        {`${selectedDate.from} - ${selectedDate.to}`}
      </Button>
    </Dropdown>
  );
};

export default DatePickerChunks;
