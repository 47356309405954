import { useAlertsContext } from "../../hooks/useAlertsContext";
import * as Actions from "./types";
import { GetPricing } from "./service";

const CommonActions = () => {
  const { toastError } = useAlertsContext();
  const getPricing = () => {
    return async (dispatch) => {
      await GetPricing()
        .then((response) => {
          dispatch({
            type: Actions.SAVE_PRICING,
            payload: response.data,
          });
        })
        .catch((e) => {
          toastError(e?.data?.message);
          // dispatch(handleRefreshToken());
        });
    };
  };

  return {
    getPricing,
  };
};

export default CommonActions;
