import React from "react";
import {
  ChartConfig,
  ChartContainer,
  ChartLegend,
  ChartLegendContent,
  ChartTooltip,
  ChartTooltipContent,
} from "../../../../components/ui/chart";
import { CartesianGrid, Line, LineChart, XAxis, YAxis } from "recharts";
import {
  CardContentFilter,
  FlowEnum,
  IChartDetails,
  IVolume,
} from "../../../../utils/types";
import Wrapper from "./Wrapper";

const chartConfig = {
  currentPeriod: {
    label: "Current Period",
  },
  lastPeriod: {
    label: "Last Period",
  },
  yearlyAvg: {
    label: "Yearly Avg",
  },
} satisfies ChartConfig;
const Volume = ({
  className,
  filters,
  details,
}: {
  className?: string;
  filters: CardContentFilter;
  details: IChartDetails<IVolume>;
}) => {
  const isOutFlow = filters.flow === FlowEnum.OUTFLOW ? "out" : "in";
  const { data, error, loading, needSubscription } = details;
  return (
    <Wrapper
      className={className}
      cardFilters={filters}
      loading={loading}
      error={error}
      needSubscription={needSubscription}
      innerClassName="pl-0"
      content={
        <ChartContainer
          config={chartConfig}
          className="max-md:h-[200px] max-md:w-[270px]"
        >
          <LineChart
            accessibilityLayer
            data={data}
            margin={{
              top: 20,
            }}
          >
            <CartesianGrid vertical={false} />
            <XAxis
              dataKey="date"
              tickLine={false}
              axisLine={false}
              tickMargin={8}
              // tickFormatter={(value) => value.slice(0, 3)}
            />
            <YAxis tickLine={false} axisLine={false} tickMargin={8} />
            {/* <ChartTooltip
          cursor={false}
          content={<ChartTooltipContent hideLabel />}
        /> */}
            <ChartTooltip
              content={<ChartTooltipContent indicator="line" />}
              cursor={false}
              defaultIndex={1}
            />
            <Line
              dataKey="currentPeriod"
              type="bump"
              stroke={`var(--chart-current-period-${isOutFlow})`}
              strokeWidth={2}
              dot={{
                fill: `var(--chart-current-period-${isOutFlow})`,
              }}
              activeDot={{
                r: 6,
              }}
            />

            <Line
              dataKey="lastPeriod"
              type="bump"
              stroke={`var(--chart-last-period-${isOutFlow})`}
              strokeWidth={2}
              dot={{
                fill: `var(--chart-last-period-${isOutFlow})`,
              }}
              activeDot={{
                r: 6,
              }}
            />
            <Line
              dataKey="yearlyAvg"
              type="bump"
              stroke={`var(--chart-yearly-avg-${isOutFlow})`}
              strokeWidth={2}
              dot={{
                fill: `var(--chart-yearly-avg-${isOutFlow})`,
              }}
              activeDot={{
                r: 6,
              }}
            />
            <ChartLegend content={<ChartLegendContent />} />
          </LineChart>
        </ChartContainer>
      }
    />
  );
};

export default Volume;
