import { cn } from "../../../utils/utils";
import React from "react";

const SubscriptionBar = ({ searches }: { searches: number }) => {
  return (
    <div className="w-full my-2">
      {/* <span className="font-bold text-base block">Welcome,</span> */}
      <span className=" text-sm block">Trial subscription</span>
      <div className="flex flex-wrap justify-between mt-1">
        <span className="text-xs block font-bold">{searches}% Used</span>
        <span className="text-xs font-normal block">5 searches left</span>
      </div>
      <div className="w-full bg-gray-300 rounded-full h-1 mt-1">
        <div
          className={cn(
            "bg-primaryBlue rounded-full h-full",
            `w-[${searches}%]`
          )}
        />
      </div>
    </div>
  );
};

export default SubscriptionBar;
