import React from "react";
import "./looper.css";

const InfiniteLooper = ({
  speed,
  direction,
  children,
}: {
  speed: number;
  direction: "right" | "left" | "up" | "down";
  children: React.ReactNode;
}) => {
  return (
    <div className="looper h-full ">
      <div
        className="looper__innerList w-full flex items-center"
        data-animate="true"
        data-direction={direction}
        style={{
          animationDuration: `${speed}s`,
          animationDirection: "normal",
        }}
      >
        <div className="gap-6 max-md:gap-2 max-md:px-2 p-4 [column-fill:_balance] sm:columns-2 sm:gap-6 lg:columns-3 lg:gap-8">
          {children}
        </div>
      </div>
    </div>
  );
};

export default InfiniteLooper;
