import React from "react";

interface TitleProps {
  title: string;
  className?: string;
}
const SectionTitle: React.FC<TitleProps> = ({ title, className }) => (
  <h2
    className={`mt-2 text-4xl lg:text-7xl text-center font-semibold capitalize tracking-tight  ${className}`}
  >
    {title}
  </h2>
);

export default SectionTitle;
