import React, { ReactNode } from "react";
import { motion } from "framer-motion";

// absolute right-2 bottom-4 -z-1
const bgClass =
  "transition-all duration-300 ease-out group-hover:scale-105 w-full";
// "border-none transition-all duration-300 ease-out [mask-image:linear-gradient(to_top,transparent_10%,#000_100%)] group-hover:scale-105";
const MotionCard = ({
  index,
  name,
  className,
  background,
  Icon,
  description,
  descriptionStyle,
}: {
  index: number;
  name?: string;
  className?: string;
  descriptionStyle?: string;
  background?: ReactNode;
  Icon?: any;
  description?: string;
}) => (
  <motion.div
    initial={{ scale: 0.8, opacity: 0.7 }}
    whileInView={{ scale: 1, opacity: 1 }}
    transition={{
      duration: index * 0.35,
      ease: "easeInOut",
    }}
    key={name}
    className={`group relative col-span-3 flex flex-col justify-between rounded-xl bg-white border
      overflow-hidden
        transform-gpu 
        ${className}`}
  >
    <div className="pointer-events-none z-10 flex transform-gpu flex-col gap-1 p-6">
      {Icon ? (
        <Icon className="h-12 w-12 origin-left transform-gpu text-neutral-700 transition-all duration-300 ease-in-out group-hover:scale-75" />
      ) : null}
      <h3 className="text-xl font-semibold text-neutral-700">
        {name}
      </h3>
      <p className={`max-w-lg text-neutral-400 ${descriptionStyle}`}>
        {description}
      </p>
    </div>
    {background ? <div className={bgClass}>{background}</div> : null}
    <div className="pointer-events-none absolute inset-0 transform-gpu transition-all duration-300 group-hover:bg-black/[.03]" />
  </motion.div>
);

export default MotionCard;
