import { FaChartColumn } from "react-icons/fa6";
import { BsIntersect, BsShieldLock } from "react-icons/bs";
import { LuSearch } from "react-icons/lu";
import { IoDocumentTextOutline } from "react-icons/io5";
import { CgArrowsExpandLeft } from "react-icons/cg";
import { TbPresentationAnalytics } from "react-icons/tb";
import { MdTrackChanges } from "react-icons/md";

export const Chunk1 = [
  {
    Icon: BsIntersect,
    title: "Integration",
    description: "Experience seamless integration to level up your efficiency.",
  },
  {
    Icon: BsShieldLock,
    title: "Security",
    description:
      "With end-to-end encryption on Google and Microsoft cloud, your data is securely stored and protected from unauthorized access.",
  },
  {
    Icon: LuSearch,
    title: "Search Engine",
    description:
      "Find the information you need quickly with advanced search capabilities.",
  },
  {
    Icon: MdTrackChanges,
    title: "Data Processing",
    description:
      "Explore our lightning-fast processing method that turns complexity into clarity.",
  },
];
export const Chunk2 = [
  {
    Icon: IoDocumentTextOutline,
    title: "Custom Reports",
    description:
      "Create tailored reports with customizable data, layouts, and presentations to fit your needs.",
  },
  {
    Icon: FaChartColumn,
    title: "Data Visualization",
    description:
      "Convert your data into interactive charts and dashboards for easy interpretation and analysis.",
  },
  {
    Icon: CgArrowsExpandLeft,
    title: "Zoom in & Out",
    description:
      "Navigate through your data seamlessly, zooming in for specifics or out for an overview.",
  },
  {
    Icon: TbPresentationAnalytics,
    title: "Monitor",
    description:
      "Stay updated with real-time data monitoring and alerts for critical changes and trends.",
  },
];
