import logoPrimary from "../assets/images/LogoPrimary.webp";
import logoWhite from "../assets/images/LogoWhite.webp";
import logoSlogan from "../assets/images/logoSlogan.png";
import LogoBadge from "../assets/images/Logobadge.webp";
import LogoBadgeWhite from "../assets/images/Logobadge_white.webp";
import heroSection from "../assets/images/heroSection.webp";
import dashboard from "../assets/images/Dashboard.webp";
import analyticsInterface from "../assets/images/analytics-interface.png";
import analyticsInterface2 from "../assets/images/analytics-interface2.png";
import analyticsInterface3 from "../assets/images/analytics-interface3.png";
import google from "../assets/icons/Google.png";
import microsoft from "../assets/icons/Microsoft.png";
import googleIcon from "../assets/icons/GoogleIcon.webp";
import microsoftIcon from "../assets/icons/MicrosoftIcon.webp";
import feature1 from "../assets/images/feature1.webp";
import feature2 from "../assets/images/feature2.webp";
import feature3 from "../assets/images/feature3.webp";
import dash1 from "../assets/images/dash1.webp";
import dash2 from "../assets/images/dash2.webp";
import analytics1 from "../assets/images/analytics1.png";
import analytics2 from "../assets/images/analytics2.png";
import analytics3 from "../assets/images/analytics3.png";
import analytics4 from "../assets/images/analytics4.png";
import analytics5 from "../assets/images/analytics5.png";
import CCPA from "../assets/icons/CCPA.webp";
import GDPR from "../assets/icons/GDPR.webp";
import ISO27001 from "../assets/icons/ISO27001.webp";
import youtube from "../assets/icons/youtube.png";
import linkedin from "../assets/icons/linkedin.png";
import dnsG from "../assets/icons/dnsG.png";
import security from "../assets/images/security.webp";
import company1 from "../assets/images/company1.jpg";
import codit from "../assets/icons/codit_logo.png";
import PlanetImg from "../assets/images/planet.webp";
import PlanetOverlayImg from "../assets/images/planet-overlay.svg";
import PlanetTagImg01 from "../assets/images/planet-tag-01.png";
import PlanetTagImg02 from "../assets/images/planet-tag-02.png";
import PlanetTagImg03 from "../assets/images/planet-tag-03.png";
import PlanetTagImg04 from "../assets/images/planet-tag-04.png";

import noise from "../assets/images/noise.png";
import heroRing from "../assets/images/hero/ring.png";
import heroAvatar01 from "../assets/images/hero/avatar-01.png";
import heroAvatar02 from "../assets/images/hero/avatar-02.png";
import heroAvatar03 from "../assets/images/hero/avatar-03.png";
import heroAvatar04 from "../assets/images/hero/avatar-04.png";
import heroAvatar05 from "../assets/images/hero/avatar-05.png";
import heroAvatar06 from "../assets/images/hero/avatar-06.png";

import needle from "../assets/icons/needle.png";

import card1 from "../assets/images/cards/card-01.png";
import cardHover1 from "../assets/images/cards/card-01-hover.png";
import card2 from "../assets/images/cards/card-02.png";
import cardHover2 from "../assets/images/cards/card-02-hover.png";
import card3 from "../assets/images/cards/card-03.png";
import cardHover3 from "../assets/images/cards/card-03-hover.png";

const images = {
  needle,
  logoPrimary,
  logoWhite,
  LogoBadge,
  LogoBadgeWhite,
  heroSection,
  dashboard,
  analyticsInterface,
  analyticsInterface2,
  analyticsInterface3,
  google,
  microsoft,
  googleIcon,
  microsoftIcon,
  feature1,
  feature2,
  feature3,
  dash1,
  dash2,
  analytics1,
  analytics2,
  analytics3,
  analytics4,
  analytics5,
  CCPA,
  GDPR,
  ISO27001,
  security,
  company1,
  codit,
  youtube,
  linkedin,
  dnsG,
  PlanetImg,
  PlanetOverlayImg,
  PlanetTagImg01,
  PlanetTagImg02,
  PlanetTagImg03,
  PlanetTagImg04,
  noise,
  heroRing,
  heroAvatar01,
  heroAvatar02,
  heroAvatar03,
  heroAvatar04,
  heroAvatar05,
  heroAvatar06,
  card1,
  cardHover1,
  card2,
  cardHover2,
  card3,
  cardHover3,
  logoSlogan,
};
export default images;
