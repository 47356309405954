import React, { Suspense } from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { HelmetProvider } from "react-helmet-async";
import { ConfigProvider, type ThemeConfig } from "antd";
import Loading from "./components/ui/Loading";
import { GoogleOAuthProvider } from "@react-oauth/google";
import { PublicClientApplication } from "@azure/msal-browser";
import { MsalProvider } from "@azure/msal-react";
import { msalConfig } from "./utils/msAuthConfig";
import "./i18n";
const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
const helmetContext = {};
const config: ThemeConfig = {
  token: {
    colorPrimary: "#113FD2",
  },
};

const Wrapper =
  process.env.NODE_ENV === "development" ? React.Fragment : React.StrictMode;
const msalInstance = new PublicClientApplication(msalConfig);
const ClientId = process.env.REACT_APP_GOOGLE_CLIENT_ID || "";

root.render(
  <MsalProvider instance={msalInstance}>
    <GoogleOAuthProvider clientId={ClientId}>
      <Wrapper>
        <HelmetProvider context={helmetContext}>
          <ConfigProvider theme={config}>
            <Suspense fallback={<Loading />}>
              <App />
            </Suspense>
          </ConfigProvider>
        </HelmetProvider>
      </Wrapper>
    </GoogleOAuthProvider>
  </MsalProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
