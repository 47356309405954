import React, { Dispatch, SetStateAction } from "react";
import { DatePicker, TimeRangePickerProps } from "antd";
import dayjs, { Dayjs } from "dayjs";
import NewTeam from "./NewTeam";
import TableHeader from "../../../components/ui/TableHeader";

const buttonStyle = "flex items-center gap-2 p-4 rounded-xl cursor-pointer";
const Filters = ({
  searchTerm,
  handleSearch,
  setDateRange,
  fetchTeams,
}: {
  searchTerm: string;
  handleSearch: Function;
  setDateRange: Dispatch<
    SetStateAction<{
      from?: string | null;
      to?: string | null;
    }>
  >;
  fetchTeams: Function;
}) => {
  const { RangePicker } = DatePicker;
  const onRangeChange = (
    dates: null | (Dayjs | null)[],
    dateStrings: string[]
  ) => {
    setDateRange((prev) => ({
      ...prev,
      dateRange: dates
        ? {
            from: dayjs(dateStrings[0]).format("YYYY-MM-DD"),
            to: dayjs(dateStrings[1]).format("YYYY-MM-DD"),
          }
        : { from: null, to: null },
    }));
  };

  const rangePresets: TimeRangePickerProps["presets"] = [
    { label: "Last 7 Days", value: [dayjs().add(-7, "d"), dayjs()] },
    { label: "Last 14 Days", value: [dayjs().add(-14, "d"), dayjs()] },
    { label: "Last 30 Days", value: [dayjs().add(-30, "d"), dayjs()] },
    { label: "Last 90 Days", value: [dayjs().add(-90, "d"), dayjs()] },
  ];
  return (
    <TableHeader
      searchTerm={searchTerm}
      placeholder={"Search by name, email..."}
      handler={(e: React.ChangeEvent<HTMLInputElement>) =>
        handleSearch(e.target.value)
      }
      leftNode={
        <div className="flex gap-2 px-5 text-slate-500 max-md:flex-wrap max-md:px-0">
          <div className={`${buttonStyle} !p-0`}>
            <RangePicker
              presets={rangePresets}
              onChange={onRangeChange}
              size={"large"}
            />
          </div>
          <NewTeam fetchTeams={fetchTeams} />
        </div>
      }
    />
  );
};

export default Filters;
