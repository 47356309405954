import { useState } from "react";
import { Notification, notifications } from "./data";

type Config = {
  selected: Notification["id"] | null;
  allNotifications: Notification[];
  unreadCount: number;
};

const useNotification = () => {
  const [notification, setNotification] = useState<Config>({
    selected: null,
    allNotifications: notifications,
    unreadCount: notifications.filter((n) => !n.read).length,
  });
  const openNotification = (notificationId: string) => {
    setNotification((prev) => ({
      ...prev,
      selected: notificationId,
      allNotifications: prev.allNotifications.map((n) =>
        n.id === notificationId ? { ...n, read: true } : n
      ),
      unreadCount: prev.allNotifications.filter(
        (n) => !n.read && n.id !== notificationId
      ).length,
    }));
  };
  const markAsRead = () => {
    setNotification((prev) => ({
      ...prev,
      allNotifications: prev.allNotifications.map((n) => ({
        ...n,
        read: true,
      })),
      unreadCount: 0,
    }));
  };
  const closeModal = () => {
    setNotification((prev) => ({
      ...prev,
      selected: null,
    }));
  };
  return {
    markAsRead,
    notification,
    setNotification,
    openNotification,
    closeModal,
  };
};
export default useNotification;
