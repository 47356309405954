import React, { useState } from "react";
import AuthSelectors from "../../modules/auth/selectors";
import PageTitle from "../../components/PageTitle";
import Button from "../../components/Button";
import Section from "../../layouts/Section";
import { Select, Switch, TimePicker } from "antd";
import { timezones, tzLocal } from "../../utils/timezone";
import DeleteAccount from "./Account/DeleteAccount";
import dayjs from "dayjs";
type IDay = {
  label: string;
  value: string;
};
const daysOfWeek: IDay[] = [
  { label: "S", value: "Sunday" },
  { label: "M", value: "Monday" },
  { label: "T", value: "Tuesday" },
  { label: "W", value: "Wednesday" },
  { label: "T", value: "Thursday" },
  { label: "F", value: "Friday" },
  { label: "S", value: "Saturday" },
];
const Settings = () => {
  const { user } = AuthSelectors();
  const onChange = (value: string) => {
    console.log(`selected ${value}`);
  };
  const [workingDaysSwitch, setWorkingDaysSwitch] = useState(false);
  const [selectedDays, setSelectedDays] = useState<string[]>([]);

  const toggleDay = (day: string) => {
    setSelectedDays((prev: any) =>
      prev.includes(day) ? prev.filter((d: any) => d !== day) : [...prev, day]
    );
  };
  const switchDays = (value: boolean) => {
    setWorkingDaysSwitch(value);
    if (!value) {
      setSelectedDays([]);
    }
  };
  const handleChange = (values: any) => {
    console.log(
      "Selected Time Range:",
      values?.map((value: any) => value?.format("hh:mm A"))
    );
  };
  return (
    <Section className="max-w-7xl mx-auto !mt-4 !p-4">
      <div className="flex flex-wrap justify-between bg-white z-10 pb-4 mb-4 border-b mt-8">
        <PageTitle
          title={"Settings"}
          subTitle={
            "Customize certain aspects about how your email statistics are calculated"
          }
        />
        <Button
          text={"Save changes"}
          type={"submit"}
          // loading={loading}
          className="justify-center items-center !px-4 text-white bg-primaryBlue !max-md:px-5"
        />
      </div>
      <form>
        <div className="space-y-4">
          <div className="border-b border-gray-900/10 pb-6">
            <div className="grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
              <div className="sm:col-span-full  justify-between grid  sm:grid-cols-6 grid-flow-row">
                <div className="sm:col-span-4">
                  <span className="block text-sm font-bold leading-6">
                    Timezone
                  </span>
                  <span className="block text-sm font-medium leading-6 text-placeholder">
                    Choose your time zone for data collection and presentation
                  </span>
                </div>
                <div className="sm:col-span-2 place-self-end">
                  <Select
                    showSearch
                    placeholder={"Select a timezone"}
                    value={tzLocal}
                    onChange={(val) => onChange(val)}
                    options={timezones}
                    allowClear
                    size={"large"}
                    className={"w-full"}
                  />
                </div>
              </div>
            </div>
          </div>

          <div className="border-b border-gray-900/10 pb-6">
            <div className="grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
              {/* <div className="sm:col-span-full  justify-between grid  sm:grid-cols-6 grid-flow-row">
                <div className="sm:col-span-4">
                  <span className="block text-sm font-bold leading-6">
                    Take my working hours into account
                  </span>
                  <span className="block text-sm font-medium leading-6 text-placeholder">
                    Update your working days for graphic display
                  </span>
                </div>
                <div className="sm:col-span-2 place-self-end">
                  <Switch checked={workingDaysSwitch} onChange={switchDays} />
                </div>
              </div> */}
              <div className="sm:col-span-full  justify-between grid  sm:grid-cols-6 grid-flow-row">
                <div className="sm:col-span-4">
                  <span className="block text-sm font-bold leading-6">
                    Working Days
                  </span>
                  <span className="block text-sm font-medium leading-6 text-placeholder">
                    Update your working days for graphic display
                  </span>
                </div>
                <div className="sm:col-span-2 place-self-end">
                  <div className="flex gap-2">
                    {daysOfWeek.map((day: IDay) => (
                      <button
                        // disabled={!workingDaysSwitch}
                        key={day.value}
                        type="button"
                        onClick={() => toggleDay(day.value)}
                        className={`size-8 rounded-xl flex items-center justify-center 
              ${
                selectedDays.includes(day.value)
                  ? "bg-primaryBlue text-white"
                  : "bg-gray-100 text-gray-400"
              }`}
                      >
                        {day.label}
                      </button>
                    ))}
                  </div>
                </div>
              </div>
              <div className="sm:col-span-full  justify-between grid  sm:grid-cols-6 grid-flow-row">
                <div className="sm:col-span-2">
                  <span className="block text-sm font-bold leading-6">
                    Business hours
                  </span>
                  <span className="block text-sm font-medium leading-6 text-placeholder">
                    Select business hours for response time metrics
                  </span>
                </div>
                <div className="sm:col-span-4 place-self-end">
                  <TimePicker.RangePicker
                    defaultValue={[
                      dayjs("08:00 AM", "hh:mm A"),
                      dayjs("05:00 PM", "hh:mm A"),
                    ]}
                    minuteStep={15}
                    use12Hours
                    format="hh:mm a"
                    onChange={handleChange}
                    className="min-h-[2.5rem]"
                  />
                </div>
              </div>
            </div>
          </div>

          <div className="border-b border-gray-900/10 pb-6">
            <div className="grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
              <div className="sm:col-span-full  justify-between grid  sm:grid-cols-6 grid-flow-row">
                <div className="sm:col-span-4">
                  <span className="block text-sm font-bold leading-6">
                    Monthly report
                  </span>
                  <span className="block text-sm font-medium leading-6 text-placeholder">
                    Receive an email at the beginning of the month with your
                    last month’s statistics
                  </span>
                </div>
                <div className="sm:col-span-2 place-self-end">
                  <Switch />
                </div>
              </div>
            </div>
          </div>

          <div className="border-b border-gray-900/10 pb-6">
            <div className="grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
              <div className="sm:col-span-full  justify-between grid  sm:grid-cols-6 grid-flow-row">
                <div className="sm:col-span-4">
                  <span className="block text-sm font-bold leading-6">
                    Weekly report
                  </span>
                  <span className="block text-sm font-medium leading-6 text-placeholder">
                    Receive an email at the beginning of the week with your last
                    week’s statistics
                  </span>
                </div>
                <div className="sm:col-span-2 place-self-end">
                  <Switch />
                </div>
              </div>
            </div>
          </div>

          <div className="border-b border-gray-900/10 pb-12">
            <div className="mb-4">
              <span className="block text-sm font-bold leading-6">
                Notifications
              </span>
              <span className="block text-sm font-medium leading-6 text-placeholder">
                We'll always let you know about important changes, but you pick
                what else you want to hear about.
              </span>
            </div>
            <fieldset>
              <legend className="text-sm font-semibold leading-6 text-gray-900">
                By Email
              </legend>
              <div className="mt-2 space-y-4">
                <div className="relative flex gap-x-3">
                  <div className="flex h-6 items-center">
                    <input
                      id="promotional"
                      name="promotional"
                      type="checkbox"
                      className="h-4 w-4 rounded border-gray-300 text-primaryBlue focus:ring-primaryBlue"
                    />
                  </div>
                  <div className="text-sm leading-6">
                    <label
                      htmlFor="promotional"
                      className="font-medium text-gray-900"
                    >
                      Promotional
                    </label>
                    <p className="text-gray-400">
                      Get notified when someones posts a comment on a posting.
                    </p>
                  </div>
                </div>
                <div className="relative flex gap-x-3">
                  <div className="flex h-6 items-center">
                    <input
                      id="offers"
                      name="offers"
                      type="checkbox"
                      className="h-4 w-4 rounded border-gray-300 text-primaryBlue focus:ring-primaryBlue"
                    />
                  </div>
                  <div className="text-sm leading-6">
                    <label
                      htmlFor="offers"
                      className="font-medium text-gray-900"
                    >
                      Offers
                    </label>
                    <p className="text-gray-400">
                      Get notified when a candidate accepts or rejects an offer.
                    </p>
                  </div>
                </div>
              </div>
            </fieldset>
            {/* <fieldset>
                <legend className="text-sm font-semibold leading-6 text-gray-900">
                  Push Notifications
                </legend>
                <p className="mt-1 text-sm leading-6 text-gray-600">
                  These are delivered via SMS to your mobile phone.
                </p>
                <div className="mt-6 space-y-6">
                  <div className="flex items-center gap-x-3">
                    <input
                      id="push-everything"
                      name="push-notifications"
                      type="radio"
                      className="h-4 w-4 border-gray-300 text-primaryBlue focus:ring-primaryBlue"
                    />
                    <label
                      htmlFor="push-everything"
                      className="block text-sm font-medium leading-6 text-gray-900"
                    >
                      Everything
                    </label>
                  </div>
                  <div className="flex items-center gap-x-3">
                    <input
                      id="push-email"
                      name="push-notifications"
                      type="radio"
                      className="h-4 w-4 border-gray-300 text-primaryBlue focus:ring-primaryBlue"
                    />
                    <label
                      htmlFor="push-email"
                      className="block text-sm font-medium leading-6 text-gray-900"
                    >
                      Same as email
                    </label>
                  </div>
                  <div className="flex items-center gap-x-3">
                    <input
                      id="push-nothing"
                      name="push-notifications"
                      type="radio"
                      className="h-4 w-4 border-gray-300 text-primaryBlue focus:ring-primaryBlue"
                    />
                    <label
                      htmlFor="push-nothing"
                      className="block text-sm font-medium leading-6 text-gray-900"
                    >
                      No push notifications
                    </label>
                  </div>
                </div>
              </fieldset> */}
          </div>
        </div>
      </form>
      <div className="grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6 mt-4">
        <div className="sm:col-span-full justify-between grid sm:grid-cols-6 grid-flow-row">
          <div className="sm:col-span-4">
            <span className="block text-sm font-bold leading-6">
              Delete account
            </span>
            <span className="block text-sm font-medium leading-6 text-placeholder">
              Erase your account and all data associated with it
            </span>
          </div>
          <DeleteAccount />
        </div>
      </div>
    </Section>
  );
};

export default Settings;
