import React from "react";
import Section from "../../layouts/Section";
import images from "../../data/images";
import { motion } from "framer-motion";

// type FeatureProps = {
//   icon: ReactNode;
//   title: string;
//   description: string;
// };

// const Feature: React.FC<FeatureProps> = ({ icon, title, description }) => (
//   <article className="group flex flex-col grow p-6 max-md:p-4 text-base text-black transition-all duration-300 ease-out hover:scale-105 cursor-pointer">
//     {icon}
//     <h3 className="mt-2 text-lg font-bold tracking-tight">{title}</h3>
//     <p className="mt-2 leading-6">{description}</p>
//     <div className="flex gap-2 items-center pr-6  max-md:pr-4 group-hover:font-semibold ">
//       <span>Learn more</span>
//       <FaArrowRightLong />
//     </div>
//   </article>
// );
// const Feature2: React.FC<FeatureProps> = ({ icon, title, description }) => (
//   <article className="group flex flex-col grow p-10 max-md:p-8 text-base text-black  transition-all  duration-300 ease-out hover:scale-105 cursor-pointer">
//     {icon}
//     <h3 className="mt-2.5 text-lg font-bold tracking-tight">{title}</h3>
//     <p className="mt-2.5 leading-6">{description}</p>
//     <div className="flex gap-2 items-center pr-7 mt-2.5  max-md:pr-5 group-hover:font-semibold ">
//       <span>Learn more</span>
//       <FaArrowRightLong />
//     </div>
//   </article>
// );

// const features: FeatureProps[] = [
//   {
//     icon: <RiLeafLine size={25} />,
//     title: "Integration ecosystem",
//     description: "Track your progress and motivate your efforts everyday.",
//   },
//   {
//     icon: <PiTargetBold size={25} />,
//     title: "Goal setting and tracking",
//     description: "Set and track goals with manageable task breakdowns.",
//   },
//   {
//     icon: <MdOutlineLock size={25} />,
//     title: "Secure data encryption",
//     description: "Ensure your data's safety with top- tier encryption.",
//   },
//   {
//     icon: <FaRegBell size={25} />,
//     title: "Customizable notifications",
//     description: "Get alerts on tasks and deadlines that matter most.",
//   },
// ];
const System = () => {
  return (
    <Section
      badge="Boost your productivity"
      title="A more effective way to track progress"
      placeholder="Effortlessly turn your visual sights into assets, control your business flows, and drive real-time progress."
      className="bg-gradient-to-b from-white via-lightBlue to-white box overflow-hidden"
    >
      <section className="flex flex-col justify-center items-center px-20 mt-16 w-full max-md:px-5 max-md:mt-10">
        <div className="w-[70%] max-md:w-full mt-8 flex flex-col justify-center items-center relative">
          <div className="rounded-xl w-full p-4 max-md:p-2 bg-white/50">
            <img
              loading="lazy"
              src={images.dashboard}
              alt="Progress tracking interface"
              className="w-full rounded-lg"
            />
          </div>
          <motion.img
            loading="lazy"
            src={images.dash1}
            initial={{ opacity: 0, y: 100 }}
            whileInView={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.5 }}
            alt="pyramid shape"
            className="lg:w-[20%] w-[30%] absolute lg:bottom-20 lg:left-24 bottom-5 left-10  rounded-lg shadow-lg"
          />
          <motion.img
            loading="lazy"
            initial={{ opacity: 0, y: -100 }}
            whileInView={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.5 }}
            src={images.dash2}
            alt="torus shape"
            className="w-[25%] absolute lg:top-[25%] top-5 lg:-right-8 -right-2  rounded-lg shadow-lg"
          />
        </div>
        {/* <div className="gap-4 grid grid-cols-4 mt-4">
          {features.map((feature, index) => (
            <div key={index} className="lg:col-span-1 col-span-4 flex flex-col">
              <Feature {...feature} />
            </div>
          ))}
        </div> */}
      </section>
    </Section>
  );
};

export default System;
