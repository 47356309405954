import React from "react";
import TeamStatus from "./TeamStatus";
import { TableParams, TeamDT } from "../../../utils/types";
import Actions from "./Actions";
import { Pagination, PaginationProps, Table, TableProps } from "antd";
import { useNavigateToTop } from "../../../hooks/useNavigateToTop";
import { FormatHelper } from "../../../utils/timezone";

type ColumnsType<T> = TableProps<T>["columns"];

const TeamsTable = ({
  data,
  loading,
  tableParams,
  handleTableChange,
  handlePaginationChange,
  fetchTeams,
}: {
  data?: TeamDT[];
  loading: boolean;
  tableParams: TableParams;
  handleTableChange: any;
  handlePaginationChange: any;
  fetchTeams: Function;
}) => {
  const navigateToTop = useNavigateToTop();
  const onChange: PaginationProps["onChange"] = (page, pageSize) => {
    handlePaginationChange(page, pageSize);
    // console.log({ ...pagination, current: page, pageSize });
  };

  const columns: ColumnsType<TeamDT> = [
    {
      title: "Name",
      dataIndex: "name",
      sorter: true,
      render: (_: any, record: TeamDT) => (
        <div>
          <span className="font-bold block">{record.name}</span>
          {record.department ? (
            <span className="font-bold text-sm text-gray-400 block">
              {record.department}
            </span>
          ) : (
            <span className="text-gray-400"> {"not assigned"}</span>
          )}
        </div>
      ),
    },
    {
      title: "Members",
      dataIndex: "teammatesCount",
      render: (_: any, record: TeamDT) => (
        // <AvatarCircles
        //   avatarUrls={[
        //     "https://avatars.githubusercontent.com/u/16860528",
        //     "https://avatars.githubusercontent.com/u/20110627",
        //     "https://avatars.githubusercontent.com/u/106103625",
        //     "https://avatars.githubusercontent.com/u/59228569",
        //   ]}
        //   numPeople={record.teammatesCount}
        // />
        <span className="font-bold">
          {record.teammatesCount ? record.teammatesCount : "Empty"}
        </span>
      ),
    },
    {
      title: "Creation Date",
      dataIndex: "createdAt",
      sorter: true,
      render: (_: any, record: TeamDT) => (
        <span>{FormatHelper.DateFormat(record.createdAt)}</span>
      ),
    },
    {
      title: "Status",
      dataIndex: "active",
      filterMultiple: false,

      showSorterTooltip: { target: "full-header" },
      filters: [
        {
          text: <TeamStatus status={true} small />,
          value: true,
        },
        {
          text: <TeamStatus status={false} small />,
          value: false,
        },
      ],
      // onFilter: (value, record) => record.status === value,
      render: (_: any, record: TeamDT) => <TeamStatus status={record.active} />,
      sorter: true,
    },
    {
      title: "Actions",
      dataIndex: "",
      key: "x",
      // responsive: ["sm"],
      render: (_: any, record: TeamDT) => (
        <Actions team={record} fetchTeams={fetchTeams} />
      ),
    },
  ];
  const { pagination } = tableParams;
  return (
    <div className="my-8">
      <Table
        scroll={{ x: "max-content" }}
        columns={columns}
        rowKey={(record) => record.id}
        dataSource={data}
        pagination={pagination}
        size="small"
        sortDirections={["ascend", "descend"]}
        loading={loading}
        rowClassName={"cursor-pointer editable-row"}
        className="table-top-align"
        onChange={handleTableChange}
        onRow={(record) => {
          return {
            onClick: (e) => {
              e?.stopPropagation();
              console.log({ record });
              navigateToTop(`${record.id}`, false, {
                state: { team: record },
              });
            },
          };
        }}
      />
      <div className="mt-8">
        <Pagination
          total={pagination?.total}
          showTotal={(total, range) =>
            `${range[0]}-${range[1]} of ${total} items`
          }
          // responsive
          showSizeChanger
          onChange={onChange}
          pageSize={pagination?.pageSize}
          current={pagination?.current}
        />
      </div>
    </div>
  );
};

export default TeamsTable;
