import React, { useRef, useState, useEffect } from "react";
import { motion, AnimatePresence } from "framer-motion";
import { useDisableBodyScroll } from "../../hooks/useDisableBodyScroll";
import { X } from "lucide-react";

// const isSafari = () =>
//   /^((?!chrome|android).)*safari/i.test(navigator.userAgent);

const mainVideo =
  "https://intersightsa.blob.core.windows.net/intersight/intersight_intro.mp4";

const BrandVideo = ({
  visible,
  setVisible,
}: {
  visible: boolean;
  setVisible: Function;
}) => {
  //   const videoRef = React.useRef<HTMLVideoElement>(null);
  const videoParentRef = useRef<HTMLDivElement | null>(null);
  const [shouldUseImage, setShouldUseImage] = useState(false);
  const backdropVariants = {
    hidden: { opacity: 0 },
    visible: { opacity: 0.5 },
  };
  const modalVariants = {
    hidden: { opacity: 0, scale: 0.75 },
    visible: { opacity: 1, scale: 1 },
  };
  useDisableBodyScroll(visible);
  const handleVisibility = (visible: boolean) => {
    setVisible(visible);
    // if (visible) {
    //   videoRef.current?.play();
    // } else {
    //   videoRef.current?.pause();
    // }
  };

  useEffect(() => {
    // check if user agent is safari and we have the ref to the container <div />
    // if (isSafari() && videoParentRef.current) {
    if (videoParentRef.current) {
      // obtain reference to the video element
      const player = videoParentRef.current
        .firstElementChild as HTMLVideoElement | null;

      // if the reference to video player has been obtained
      if (player) {
        // set the video attributes using javascript as per the
        // webkit Policy
        player.controls = false;
        player.playsInline = true;
        // player.muted = true;
        player.setAttribute("muted", ""); // leave no stones unturned :)
        player.autoplay = true;

        setTimeout(() => {
          const promise = player.play();
          if (promise && typeof promise.then === "function") {
            promise
              .then(() => {
                // Video played successfully
              })
              .catch(() => {
                // If promise fails, hide the video and fallback to <img>
                if (videoParentRef.current) {
                  videoParentRef.current.style.display = "none";
                }
                setShouldUseImage(true);
              });
          }
        }, 0);
      }
    }
  }, []);
  if (!visible) return null;
  return shouldUseImage ? (
    <img src={mainVideo} alt="Muted Video" />
  ) : visible ? (
    <AnimatePresence>
      <div className="fixed inset-0 flex items-center justify-center z-40">
        {/* Modal dialog */}
        <motion.div
          className="relative z-50 p-6 max-w-5xl w-full"
          variants={modalVariants}
          initial="hidden"
          animate="visible"
          exit="hidden"
        >
          <div
            onClick={() => handleVisibility(false)}
            className="absolute -top-5 left-5 flex items-center justify-center size-8 cursor-pointer text-white border border-white rounded-full"
          >
            <X />
          </div>
          <div
            className="rounded-3xl shadow-2xl aspect-video bg-black overflow-hidden"
            ref={videoParentRef}
            dangerouslySetInnerHTML={{
              __html: `
                    <video
                      loop
                      autoplay
                      playsinline
                      controls
                      preload="metadata"
                    >
                    <source src="${mainVideo}" type="video/mp4" />
                     Your browser does not support the video tag. Please try
                    viewing this page in a modern browser.
                    </video>`,
            }}
          />
        </motion.div>
        {/* End: Modal dialog */}
        <motion.div
          className="fixed inset-0 bg-black"
          variants={backdropVariants}
          initial="hidden"
          animate="visible"
          exit="hidden"
          onClick={() => handleVisibility(false)}
        />
      </div>
    </AnimatePresence>
  ) : null;
};

export default BrandVideo;
