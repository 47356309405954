import classNames from "classnames";
import * as React from "react";
import { CgSpinner } from "react-icons/cg";

interface ButtonProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  onClick?: () => void;
  text?: string;
  icon?: React.ReactNode;
  loading?: boolean;
  isReversed?: boolean;
  variant?: "primary" | "secondary" | "disabled" | "link" | "danger";
}
const Button: React.FC<ButtonProps> = ({
  onClick,
  text,
  icon,
  variant = "primary",
  loading,
  isReversed,
  ...rest
}) => {
  let buttonClassName =
    "transition-all duration-300 ease-out flex gap-2 items-center justify-center self-center px-5 py-3 my-2 text-base font-medium rounded-lg focus:outline-none";
  switch (variant) {
    case "primary":
      buttonClassName +=
        " text-white bg-primaryBlue border border-primaryBlue hover:border-primaryBlue/10 hover:bg-primaryBlue/10 hover:text-primaryBlue";
      break;
    case "link":
      buttonClassName +=
        " text-primaryBlue border-b border-b-primaryBlue bg-transparent rounded-none";
      break;
    case "danger":
      buttonClassName +=
        " text-white bg-red-700 border border-red-700 hover:bg-transparent hover:text-red-700";
      break;
    case "secondary":
      buttonClassName +=
        " text-primaryBlue border border-primaryBlue bg-transparent hover:bg-primaryBlue/10  hover:border-transparent";
      break;
    case "disabled":
      buttonClassName +=
        "!text-gray-500 bg-gray-300 border border-gray-500 cursor-not-allowed opacity-50";
      break;
    default:
      break;
  }
  const handleClick = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    if (onClick) {
      e?.stopPropagation();
      onClick && onClick();
    }
  };
  return (
    <button
      {...rest}
      className={classNames(
        buttonClassName,
        rest?.className,
        isReversed ? "flex-row-reverse" : "flex"
      )}
      onClick={handleClick}
      disabled={variant === "disabled" || loading}
    >
      {loading ? (
        <CgSpinner className="animate-spin size-5" />
      ) : (
        <>
          {/* <span className={icon ? "max-md:hidden" : ""}>{text}</span> */}
          {icon && <span>{icon}</span>}
          {/* <span className="max-md:hidden">{text}</span> */}
          {text && <span>{text}</span>}
        </>
      )}
    </button>
  );
};

export default Button;
