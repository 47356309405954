import React from "react";
import { FAQS } from "../../../../utils/types";
import Accordion from "../../../../components/Accordion";
import CardWrapper from "../../../../components/CardWrapper";
const faqData: FAQS[] = [
  {
    question: "How does the email tracking system work?",
    answer:
      "The email tracking system tracks when recipients open emails and click on links. It uses tracking pixels and link tracking to gather data on recipient interactions.",
    active: true,
  },
  {
    question: "Can I see real-time email analytics?",
    answer:
      "Yes, our system provides real-time analytics showing open rates, click-through rates, and bounce rates. These insights help you gauge the effectiveness of your email campaigns.",
    active: false,
  },
  {
    question: "What types of reports can I generate?",
    answer:
      "You can generate detailed reports including statistics on open rates, click-through rates, bounce rates, and recipient engagement over time.",
    active: false,
  },
  {
    question: "Is it possible to track emails sent from different domains?",
    answer:
      "Yes, the system supports tracking emails sent from multiple domains, allowing you to monitor engagement across various email campaigns.",
    active: false,
  },
];
const Faqs = () => {
  return (
    <CardWrapper
      className="col-span-1 max-md:col-span-3"
      title={"Frequently Asked Questions"}
      content={
        <div className="divide-y divide-slate-200">
          {faqData.map((faq, index) => (
            <Accordion
              key={index}
              title={faq.question}
              id={`faqs-${index}`}
              active={faq.active}
            >
              {faq.answer}
            </Accordion>
          ))}
        </div>
      }
    />
  );
};

export default Faqs;
