import * as Actions from "./types";

const initialState = {
  isLoading: false,
  error: null,
  pricing: [],
};
const commonReducer = (state = initialState, action) => {
  switch (action.type) {
    case Actions.SAVE_PRICING:
      return {
        ...state,
        pricing: action.payload,
      };
    case Actions.SAVE_PAGE_STATE:
      const { pagePath, pageState } = action.payload;
      return {
        ...state,
        [pagePath]: pageState,
      };
    default:
      return state;
  }
};

export default commonReducer;
