import React from "react";
import Section from "../../layouts/Section";
import InfiniteLooper from "../../components/SlideLoop/InfiniteLooper";

const testimonials = [
  { id: 1, role: "CFO", text: "This product is amazing!" },
  {
    id: 2,
    role: "Project Manager",
    text: "I had a great experience. I had a great experience. I had a great experience.",
  },
  { id: 3, role: "CFO", text: "Highly recommend to everyone!" },
  {
    id: 4,
    role: "Marketing Agency",
    text: "Excellent service and quality.Excellent service and quality. Excellent service and quality. Excellent service and quality.",
  },
  {
    id: 5,
    role: "CTO",
    text: "Will buy again for sure. I had a great experience. I had a great experience. I had a great experience.",
  },
  { id: 6, role: "", text: "Will buy again for sure." },
  { id: 11, text: "This product is amazing!" },
  {
    id: 7,
    role: "Manger",
    text: "I had a great experience. I had a great experience. I had a great experience. I had a great experience. I had a great experience.",
  },
  { id: 8, role: "Designer", text: "Highly recommend to everyone!" },
  {
    id: 9,
    role: "CTO",
    text: "Excellent service and  Excellent service and  Excellent service and  quality.",
  },
  { id: 10, role: "CTO", text: "Will buy again for sure." },
  { id: 11, role: "CTO", text: "Will buy again for sure." },
];

const Testimonials = () => {
  return (
    <Section badge="Testimonials" title="What our users say" className="box">
      <section className="flex flex-col justify-center items-center px-20 mt-16 w-full max-md:px-5 max-md:mt-10">
        <div
          className="relative overflow-hidden w-[80%] max-md:w-[95%] h-96"
          style={{
            maskImage:
              "linear-gradient(to bottom, transparent, black 20%, black 80%, transparent)",
            WebkitMaskImage:
              "linear-gradient(to bottom, transparent, black 20%, black 80%, transparent)",
          }}
        >
          <InfiniteLooper speed={50} direction="up">
            {testimonials.map((testimonial, index) => (
              <div key={index} className="mb-8 sm:break-inside-avoid">
                <blockquote className="rounded-lg bg-gray-50 p-6 shadow-sm sm:p-8">
                  <div className="flex items-center gap-4">
                    <img
                      loading="lazy"
                      src={`https://i.pravatar.cc/150?img=${index}`}
                      alt={`user[${index}] avatar`}
                      className="size-14 rounded-full object-cover"
                    />
                    <div>
                      <p className="!m-0 text-lg font-medium text-gray-900">
                        {`user[${index}]`}
                      </p>
                      <span className="text-xs text-gray-400">
                        {testimonial.role}
                      </span>
                    </div>
                  </div>

                  <p className="mt-4 text-gray-700">{testimonial.text}</p>
                </blockquote>
                {/* <div className="px-4 py-6 flex flex-col gap-4 h-full justify-between">
                  <span className="font-medium  text-wrap break-words">
                    {testimonial.text}
                  </span>
                  <div className="flex items-center p-1 gap-2">
                    <img
                      className="h-10 w-10 flex-none rounded-full bg-gray-50"
                      src={`https://i.pravatar.cc/150?img=${index}`}
                      alt={`user[${index}] avatar`}
                    />
                    <span className="text-sm text-gray-800">{`user[${index}]`}</span>
                  </div>
                </div> */}
              </div>
            ))}
          </InfiniteLooper>
        </div>
      </section>
    </Section>
  );
};

export default Testimonials;
