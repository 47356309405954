import { SorterResult, SortOrder } from "antd/es/table/interface";
import { Key } from "react";

export function extractOrderAndField<RecordType>(
  sorter?: SorterResult<RecordType> | SorterResult<RecordType>[]
): { order?: SortOrder; field?: Key | readonly Key[] }[] {
  if (Array.isArray(sorter)) {
    // If sorter is an array, map over each SorterResult object and extract order and field properties
    return sorter.map((sorterResult) => {
      return {
        order: sorterResult.order,
        field: sorterResult.field,
      };
    });
  } else {
    // If sorter is a single SorterResult object, extract order and field properties directly
    return [
      {
        order: sorter?.order,
        field: sorter?.field,
      },
    ];
  }
}
