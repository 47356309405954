import React from "react";
import Section from "../../layouts/Section";
import SEO from "../../layouts/SEO";
import Plans from "../Home/Plans";
import Testimonials from "../Home/Testimonials";
import FAQ from "../Home/FAQ";
import { FAQS } from "../../utils/types";

const faqData: FAQS[] = [
  {
    question: "What pricing plans does InterSight offer",
    answer:
      "Inter-Sight offers multiple pricing plans tailored to different needs. Each plan provides access to a variety of features, including reporting, order management, and marketplace integrations. Detailed information about the specific features included in each plan can be found on their pricing page.",
    active: true,
  },
  {
    question: "Are there any discounts available for startups or non-profits?",
    answer:
      "Inter-Sight offers discounts for eligible startups and non-profits. These discounts are designed to help new and small organizations access their services at a reduced cost. To find out if your organization qualifies, you can contact their sales team for more information.",
    active: false,
  },
  {
    question: "Is there a free trial available?",
    answer:
      "Yes, Inter-Sight offers a free trial period for new users. This allows potential customers to explore the platform and its features before committing to a paid plan. Details on how to sign up for the free trial can be found on their website.",
    active: false,
  },
  {
    question: "Can I customize my pricing plan?",
    answer:
      "While each pricing plan comes with a set of predefined features, Inter-Sight does offer some flexibility for customization. You can choose the number of seats and add-ons according to your specific needs. For further customization options, you may need to discuss with their sales team.",
    active: false,
  },
  {
    question: "What payment methods are accepted?",
    answer:
      "Inter-Sight accepts various payment methods including major credit cards and ACH transfers. Specific details about accepted payment methods and billing cycles can be reviewed during the subscription process or by contacting their support team.",
    active: false,
  },
];
const Pricing = () => {
  return (
    <Section id="Pricing">
      <SEO title={"Pricing | InterSight"} />
      <Plans />
      {/* <ComparePlans /> */}
      {/* <EnterpriseQuote /> */}
      <Testimonials />
      <FAQ faqs={faqData} />
    </Section>
  );
};

export default Pricing;
