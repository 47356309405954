import React, { ReactNode } from "react";
import BasicCard from "./BasicCard";
import CTACard from "./CTACard";
import MotionCard from "./MotionCard";

export type BentoCardProps = {
  index: number;
  name?: string;
  className?: string;
  background?: ReactNode;
  children?: ReactNode;
  Icon?: any;
  description?: string;
  descriptionStyle?: string;
  href?: string;
  cta?: string;
};
interface Props extends BentoCardProps {
  type?: "default" | "basic" | "cta" | "motion";
}
const BentoCard = (props: Props) => {
  const { type = "default", ...rest } = props;
  if (type === "basic") {
    return <BasicCard {...rest} />;
  } else if (type === "cta") {
    return <CTACard {...rest} />;
  } else if (type === "motion") {
    return <MotionCard {...rest} />;
  }
  return <BasicCard {...rest} />;
};

export default BentoCard;
