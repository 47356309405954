import React from "react";
import { cn } from "../../../../utils/utils";

const RenderPercentage = (
  icon: React.ReactNode,
  colorClass: string,
  percent: number
) => (
  <div
    className={cn(
      "flex items-center gap-1 rounded-full text-center font-bold",
      colorClass
    )}
  >
    {icon}
    <span className="items-baseline tabular-nums">{percent}%</span>
  </div>
);

export default RenderPercentage;
