import React, { useState } from "react";
import { Select } from "antd";
import dayjs, { Dayjs } from "dayjs";
import {
  DateType,
  FlowEnum,
  OptionsFilterType,
  ReportTypeEnum,
  ViewEnum,
} from "../../../../utils/types";
import { useMediaQuery } from "react-responsive";
import { LuSearch } from "react-icons/lu";
import { cn } from "../../../../utils/utils";
import Modal from "../../../../components/Modal";
import { IoCloseOutline } from "react-icons/io5";
import Icon from "../../../../components/ui/Buttons/Icon";
import DatePickerChunks from "./DatePickerChunks";
// import DateRangePickerWithPresets from "./DateRangePickerWithPresets";
// const rangePresets: TimeRangePickerProps["presets"] = [
//   { label: "Last Week", value: [dayjs().add(-7, "d"), dayjs()] },
//   { label: "Last Month", value: [dayjs().add(-30, "d"), dayjs()] },
//   { label: "Last Quarter", value: [dayjs().add(-120, "d"), dayjs()] },
//   { label: "Last Median", value: [dayjs().add(-182, "d"), dayjs()] },
//   { label: "Last Year", value: [dayjs().add(-365, "d"), dayjs()] },
// ];
const Filters = ({
  usersOptions,
  filterOption,
  handleFilterChange,
  isCollapsed,
  collapseFilters,
}: {
  usersOptions: any;
  filterOption: OptionsFilterType;
  handleFilterChange: Function;
  isCollapsed: boolean;
  collapseFilters: Function;
}) => {
  // const { RangePicker } = DatePicker;
  const isMobile = useMediaQuery({ maxWidth: "767px" });

  const [filters, setFilters] = useState<OptionsFilterType>(filterOption);
  const isAnalyticsView = filters.report === ReportTypeEnum.ANALYTICS;

  const handleFilters = (name: string, value: any) => {
    // Handle date range differently based on the name
    if (name === "dateRange") {
      setFilters((prev) => ({
        ...prev,
        dateRange: value
          ? {
              from: value[0] ? dayjs(value[0]).format("YYYY-MM-DD") : null,
              to: value[1] ? dayjs(value[1]).format("YYYY-MM-DD") : null,
            }
          : { from: null, to: null },
      }));
    } else {
      setFilters((prev) => ({
        ...prev,
        [name]: value,
      }));
    }
  };

  const Wrapper = ({ children }: { children: any }) =>
    isMobile ? (
      <Modal
        showModal={!isCollapsed}
        dismiss={collapseFilters}
        className="!w-full !pb-4"
      >
        <div className="flex justify-between items-center">
          <h1 className="font-bold">{"Report Customization"}</h1>
          <Icon className="!bg-transparent" onClick={() => collapseFilters()}>
            <IoCloseOutline color={"#000"} size={20} />
          </Icon>
        </div>
        <div className="gap-4 flex flex-col">{children}</div>
      </Modal>
    ) : (
      <div
        className={cn(
          "transition-all delay-150 duration-300 ease-in-out ",
          isCollapsed
            ? "-translate-y-full opacity-0 "
            : "translate-y-0 opacity-1"
        )}
      >
        <div
          className={cn(
            isCollapsed
              ? "hidden"
              : "mt-4 lg:mt-0 grid w-full lg:grid-cols-7 md:grid-cols-1 p-4 rounded-lg justify-between items-end border-b border-b-gray-150",
            "gap-4 lg:grid-cols-7"
            // isAnalyticsView ? "gap-2 lg:grid-cols-8" : "gap-4 lg:grid-cols-7"
          )}
        >
          {children}
        </div>
      </div>
    );
  const { report, view, user1, user2, dateRange, flow } = filters;
  const dates: [Dayjs | null | undefined, Dayjs | null | undefined] = [
    dateRange.from ? dayjs(dateRange.from) : null,
    dateRange.to ? dayjs(dateRange.to) : null,
  ];
  return (
    <Wrapper>
      <div>
        <span className="mb-1 pl-2 text-placeholder/80 text-sm">Report</span>
        <Select
          className="h-[2.5rem]"
          value={report}
          // defaultValue={filterOption.report}
          style={{ width: "100%" }}
          placeholder={"Select report"}
          // allowClear
          // onClear={() => handleFilters("report", ReportTypeEnum.STATISTICS)}
          onChange={(value) => handleFilters("report", value)}
          options={[
            {
              label: "Statistics",
              value: ReportTypeEnum.STATISTICS,
            },
            {
              label: "Connections",
              value: ReportTypeEnum.CONNECTIONS,
            },
            {
              label: "Analytics",
              value: ReportTypeEnum.ANALYTICS,
            },
          ]}
        />
      </div>
      <div>
        <span className="mb-1 pl-2 text-placeholder/80 text-sm">
          {isAnalyticsView ? "Users" : "User"}
        </span>
        <Select
          // mode={isAnalyticsView ? "multiple" : undefined}
          value={user1}
          style={{ width: "100%" }}
          placeholder={"Select user"}
          // placeholder={isAnalyticsView ? "Select users" : "Select user"}
          // maxCount={isAnalyticsView ? 2 : undefined}
          maxTagCount={"responsive"}
          onChange={(value) => handleFilters("user1", value)}
          // options={usersOptions}
          options={usersOptions.filter(
            (user: { label: string; value: string }) => user.value !== user2
          )}
          className={"h-[2.5rem]"}
        />
      </div>
      {isAnalyticsView ? (
        <div>
          <Select
            // onClear={() => handleFilters("users", [])}
            // allowClear
            value={user2}
            style={{ width: "100%" }}
            placeholder={"Select user"}
            maxTagCount={"responsive"}
            // onDeselect={(value) => console.log("onDeselect", value)}
            // onSelect={(value) => handleFilters("users", value)}
            onChange={(value) => handleFilters("user2", value)}
            // options={usersOptions}
            // options={usersOptions.map(
            //   (user: { label: string; value: string }) => ({
            //     label: user.label,
            //     value: user.value,
            //     disabled: filters?.users?.includes(user.value),
            //   })
            // )}
            options={usersOptions.filter(
              (user: { label: string; value: string }) => user.value !== user1
            )}
            className={"h-[2.5rem]"}
          />
        </div>
      ) : null}
      {isAnalyticsView ? null : (
        <div>
          <span className="mb-1 pl-2 text-placeholder/80 text-sm">View</span>
          <Select
            className="h-[2.5rem]"
            value={view}
            style={{ width: "100%" }}
            placeholder={"Select view"}
            // allowClear
            // onClear={() => handleFilters("view", FlowEnum.ALL)}
            onChange={(value) => handleFilters("view", value)}
            options={[
              {
                label: "All",
                value: ViewEnum.ALL,
              },
              {
                label: "Internal",
                value: ViewEnum.INTERNAL,
              },
              {
                label: "External",
                value: ViewEnum.EXTERNAL,
              },
            ]}
          />
        </div>
      )}

      <div>
        <span className="mb-1 pl-2 text-placeholder/80 text-sm">Flow</span>
        <Select
          className="h-[2.5rem]"
          placeholder={"Select flow"}
          value={flow}
          style={{ width: "100%" }}
          // allowClear
          // onClear={() => handleFilters("flow", FlowEnum.ALL)}
          onChange={(value) => handleFilters("flow", value)}
          options={[
            {
              label: "All",
              value: FlowEnum.ALL,
            },
            {
              label: "Inflow",
              value: FlowEnum.INFLOW,
            },
            {
              label: "OutFlow",
              value: FlowEnum.OUTFLOW,
            },
          ]}
        />
      </div>
      <div className="col-span-2 flex flex-col">
        <span className="mb-1 pl-2 text-placeholder/80 text-sm">Time</span>
        <DatePickerChunks
          selectedDates={dates}
          onChange={(value: DateType) => handleFilters("dateRange", value)}
        />
        {/* <DateRangePickerWithPresets
          selectedDates={dates}
          onChange={(value: DateType) => handleFilters("dateRange", value)}
        /> */}
        {/* <RangePicker
          value={dates}
          presets={rangePresets}
          onChange={(_, dateStrings) => handleFilters("dateRange", dateStrings)}
          size={"small"}
          className="min-h-[2.5rem]"
        /> */}
      </div>
      <button
        onClick={() => handleFilterChange(filters)}
        className="flex text-primaryBlue max-md:col-span-2 items-center justify-center gap-2 !px-4 !py-2 !max-md:px-5 border border-primaryBlue rounded-lg hover:bg-primaryBlue/10  hover:border-transparent transition-all duration-300 ease-out"
      >
        <LuSearch size={20} />
        <span>Search</span>
      </button>
    </Wrapper>
  );
};

export default Filters;
