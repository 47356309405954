import React from "react";
import ErrorBoundary from "./ErrorBoundary";
import { Outlet, useLocation } from "react-router-dom";
import Fallback from "./Fallback";
import BasicBar from "./Navbar/BasicBar";
import ScrollToTop from "../hooks/ScrollToTop";

const StarterLayout = () => {
  const location = useLocation();
  return (
    <ErrorBoundary renderError={Fallback}>
      <ScrollToTop />
      <div className="min-h-dvh">
        <BasicBar />
        {/* <AnimatePresence mode="wait"> */}
        <Outlet key={location.pathname} />
        {/* </AnimatePresence> */}
      </div>
    </ErrorBoundary>
  );
};

export default StarterLayout;
