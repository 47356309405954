import React from "react";
import { useTranslation } from "react-i18next";
import AccessibilityEN from "./AccessibilityEN";
import AccessibilityHE from "./AccessibilityHE";
import Translate from "../../layouts/Navbar/translate";

const Accessibility = () => {
  const { i18n } = useTranslation();
  const isEng = i18n.language === "en";
  return (
    <div dir={isEng ? "ltr" : "rtl"}>
      {isEng ? <AccessibilityEN /> : <AccessibilityHE />}
      <Translate />
    </div>
  );
};

export default Accessibility;
