import * as React from "react";
import "../../styles/Navbar.css";
import AnimatedLogo from "./AnimatedLogo";

const BasicBar: React.FC = () => {
  return (
    <div
      // className={`box-border flex flex-col shrink-0 w-full fixed transition-all duration-300 top-0 self-center z-40 items-center justify-center`}
      className={`h-[8dvh] box-border flex flex-col shrink-0 w-full bg-white transition-all duration-300 top-0 self-center z-40 items-center justify-center`}
    >
      <header
        className={`flex relative gap-4 w-full justify-between items-center px-12 bg-white max-md:flex-wrap max-md:px-5`}
      >
        <AnimatedLogo />
        {/* <Link to="/">
          <img
            loading="lazy"
            // src={images.logoPrimary}
            src={images.logoPrimary}
            alt="Company Logo"
            className={"w-[12rem]"}
          />
        </Link> */}
        {/* <div className="flex gap-4 items-center">
          {isMobile ? null : <span>Already have an account?</span>}
          <Button
            text={"Sign In"}
            variant={"secondary"}
            onClick={() => navigate("/get-started/Login")}
            className="!px-4 !py-2 !max-md:px-5"
          />
        </div> */}
      </header>
    </div>
  );
};

export default BasicBar;
