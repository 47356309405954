import React from "react";
import PageTitle from "../../../components/PageTitle";
import PricingTable from "./components/PricingTable";
import Section from "../../../layouts/Section";

const Subscription = () => {
  return (
    <Section className="max-w-7xl mx-auto !mt-4 !p-4">
      {/* <div className="flex justify-between sticky top-8 bg-white z-10 pb-4 mb-4 border-b"> */}
      <div className="flex flex-wrap justify-between bg-white z-10 pb-4 mb-4 border-b mt-8">
        <PageTitle
          title={"Plans"}
          subTitle={
            "View your current plan and switch to the one that fits your needs"
          }
        />
      </div>
      <PricingTable />
    </Section>
  );
};

export default Subscription;
