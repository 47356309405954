import React from "react";
import Section from "../layouts/Section";

const Help = () => {
  return (
    <Section id="help">
      <div className="px-20">
        <h1 className="font-semibold leading-7 text-gray-900">
          Help Center
        </h1>
      </div>
    </Section>
  );
};

export default Help;
