import React from "react";
import Statistics from "./Statistics";
import Analytics from "./Analytics";
import { ChartsTypeEnum, ReportTypeEnum } from "../../../utils/types";
import Connections from "./Connections";
import { IChartData } from "../hooks/data";

interface Props {
  charts: Record<ChartsTypeEnum, IChartData>;
  type?:
    | "default"
    | ReportTypeEnum.STATISTICS
    | ReportTypeEnum.CONNECTIONS
    | ReportTypeEnum.ANALYTICS;
}
const Report = (props: Props) => {
  const { type = "default", charts, ...rest } = props;
  if (type === ReportTypeEnum.STATISTICS) {
    return <Statistics charts={charts} {...rest} />;
  } else if (type === ReportTypeEnum.CONNECTIONS) {
    return <Connections charts={charts} {...rest} />;
  } else if (type === ReportTypeEnum.ANALYTICS) {
    return <Analytics charts={charts} {...rest} />;
  }
  return <Statistics {...rest} />;
};

export default Report;
