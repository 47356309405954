import React, { useRef } from "react";
import { MdArrowBackIosNew, MdArrowForwardIos } from "react-icons/md";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import { Navigation } from "swiper/modules";
import Card from "./Card";
import { Chunk1, Chunk2 } from "./data";

export const SightsOutputs = () => {
  const swiperRef = useRef<any>(null);
  return (
    <div className="my-10 hidden max-md:block">
      <Swiper
        slidesPerView={"auto"}
        centeredSlides={true}
        spaceBetween={30}
        modules={[Navigation]}
        // navigation={{
        //   prevEl: navigationPrevRef.current,
        //   nextEl: navigationNextRef.current,
        // }}
        onSwiper={(swiper: any) => {
          swiperRef.current = swiper;
        }}
        className="mySwiper mt-10"
      >
        {[...Chunk1, ...Chunk2].map((chunk, index) => (
          <SwiperSlide key={index}>
            <Card
              Icon={chunk.Icon}
              title={chunk.title}
              description={chunk.description}
              className="!block w-[90%]"
            />
          </SwiperSlide>
        ))}
      </Swiper>
      <div className="flex gap-2 mt-4 text-primaryBlue">
        <div
          className="border rounded-lg p-2 cursor-pointer"
          onClick={() => swiperRef?.current?.slidePrev()}
        >
          <MdArrowBackIosNew />
        </div>
        <div
          className="border rounded-lg p-2 cursor-pointer"
          onClick={() => swiperRef?.current?.slideNext()}
        >
          <MdArrowForwardIos />
        </div>
      </div>
    </div>
  );
};
