import { useState } from "react";
import { useAlertsContext } from "../../../../hooks/useAlertsContext";
import {
  CreateTeamForm,
  PeopleForm,
  ProfileDT,
  TableParams,
} from "../../../../utils/types";
import { extractOrderAndField } from "../../../../utils/table-sorter";
import { DeletePeople, EditPeople, GetPeople } from "../services/service";
import { TableProps } from "antd";

const useActions = () => {
  const { toastError, toastSuccess } = useAlertsContext();
  const [loading, setLoading] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [tableParams, setTableParams] = useState<TableParams>({
    pagination: {
      current: 1,
      pageSize: 10,
    },
  });
  const [data, setData] = useState<ProfileDT[]>([]);
  const Create = async (form: CreateTeamForm, cb = () => {}) => {
    setLoading(true);
    setTimeout(() => {
      toastSuccess("Profile Created Successfully");
      setLoading(false);
    }, 2000);
  };

  const Edit = async (form: PeopleForm, cb = (close: boolean) => {}) => {
    setLoading(true);
    await EditPeople(form)
      .then((response) => {
        cb && cb(true);
        toastSuccess(response?.message);
      })
      .catch((error) => {
        toastError(error);
        cb && cb(false);
      })
      .finally(() => setLoading(false));
  };

  const Delete = async (id: string, cb = (close: boolean) => {}) => {
    setLoading(true);
    await DeletePeople(id)
      .then((response) => {
        cb && cb(true);
        toastSuccess(response?.message);
      })
      .catch((error) => {
        cb && cb(false);
        toastError(error);
      })
      .finally(() => setLoading(false));
  };
  const FetchPeople = async () => {
    setLoading(true);
    const { pagination, sorter } = tableParams;
    const sorterData = extractOrderAndField(sorter);
    // Initialize columns array and order array
    const columns: any = [];
    const orderArr: any = [];

    // Fill in columns array with name and orderable properties
    sorterData.forEach(({ field }, index) => {
      if (field) {
        columns.push({ name: field, orderable: true });
      }
    });

    // Fill in order array with column and dir properties
    sorterData.forEach(({ order }, index) => {
      if (order) {
        orderArr.push({ column: index, dir: order || undefined });
      }
    });
    const filters = {
      start: pagination?.current
        ? (pagination?.current - 1) * (pagination?.pageSize || 10)
        : 0,
      length: pagination?.pageSize || 10,
      customSearch: {
        searchTerm,
        active: tableParams?.filters?.active || null,
      },
      columns,
      order: orderArr,
    };
    await GetPeople(filters)
      .then((response) => {
        setData(response.data);
        setTableParams({
          ...tableParams,
          pagination: {
            ...tableParams.pagination,
            total: response.recordsTotal,
          },
        });
      })
      .catch((e) => {
        if (e?.status && e?.status === 401) {
          toastError(e?.data || "Token Expired");
          // TODO
          //   setTimeout(() => {
          //     dispatch(logout());
          //   }, 1500);
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };
  const handleSearch = (search: string) => {
    setSearchTerm(search);
  };
  const handleTableChange: TableProps["onChange"] = (
    pagination,
    filters,
    sorter
  ) => {
    setTableParams({
      pagination,
      filters,
      sorter,
    });
    // `dataSource` is useless since `pageSize` changed
    if (pagination?.pageSize !== tableParams?.pagination?.pageSize) {
      setData([]);
    }
  };
  const handlePaginationChange = (current: number, pageSize: number) => {
    setTableParams((prevState) => ({
      ...prevState,
      pagination: { pageSize, current },
    }));
    // `dataSource` is useless since `pageSize` changed
    if (pageSize !== tableParams?.pagination?.pageSize) {
      setData([]);
    }
  };
  return {
    Delete,
    Create,
    Edit,
    data,
    loading,
    searchTerm,
    setSearchTerm,
    FetchPeople,
    tableParams,
    handleSearch,
    handleTableChange,
    handlePaginationChange,
  };
};

export default useActions;
