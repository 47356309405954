import React from "react";
import SearchBar from "./Inputs/SearchBar";

const TableHeader = ({
  searchTerm,
  handler,
  placeholder,
  leftNode = null,
}: {
  searchTerm: string;
  handler: (e: React.ChangeEvent<HTMLInputElement>) => void;
  placeholder: string;
  leftNode?: React.ReactNode;
}) => {
  return (
    <div className="flex gap-4 justify-between text-base font-medium tracking-wide leading-6 max-md:flex-wrap my-8">
      <SearchBar
        searchTerm={searchTerm}
        handler={handler}
        placeholder={placeholder}
      />
      {leftNode}
    </div>
  );
};

export default TableHeader;
