import React from "react";
import { Link, useMatch, useResolvedPath } from "react-router-dom";
import { useMediaQuery } from "react-responsive";
import classNames from "classnames";

interface NavItemProps {
  text: string;
  to: string;
  onClick?: any;
}
const NavItem: React.FC<NavItemProps> = ({
  text,
  to,
  onClick = () => {},
  ...props
}) => {
  const resolvedPath = useResolvedPath(to);
  const isActive = useMatch({ path: resolvedPath.pathname, end: true });
  const isMobile = useMediaQuery({ maxWidth: "767px" });

  return (
    <Link
      to={to}
      className={classNames(
        "relative flex flex-col whitespace-nowrap px-2 font-medium transition-all duration-300 ease-out cursor-pointer hover:text-body",
        isActive ? "font-bold" : "",
        isMobile ? "text-start" : "items-center justify-center"
      )}
      onClick={onClick}
      {...props}
    >
      <span className="navText">{text}</span>
      <div
        className={classNames(
          "absolute -bottom-2 h-0.5 rounded-md",
          isActive ? "bg-primaryBlue" : "bg-transparent",
          isMobile ? "mb-2" : "w-[110%]"
        )}
      />
    </Link>
  );
};

export default NavItem;
