import React, { forwardRef, useRef } from "react";
import { motion } from "framer-motion";
import { AnimatedBeam } from "../../../components/AnimatedBeam";
import images from "../../../data/images";
import classNames from "classnames";
import Card from "./Card";
import { Chunk1, Chunk2 } from "./data";

const Circle = forwardRef<
  HTMLDivElement,
  { className?: string; children?: React.ReactNode }
>(({ className, children }, ref) => {
  return (
    <div
      ref={ref}
      className={`
        z-10 flex size-14 items-center justify-center rounded-full border-2 bg-white p-3 lg:shadow-product shadow-primaryBlue/30
        ${className}`}
    >
      {children}
    </div>
  );
});

Circle.displayName = "Circle";

const Solution = forwardRef<
  HTMLDivElement,
  { className?: string; children?: React.ReactNode }
>(({ className, children }, ref) => {
  return (
    <div
      ref={ref}
      className={`
        z-10 flex rounded-lg border-2 bg-white p-3 lg:shadow-product
        ${className}`}
    >
      {children}
    </div>
  );
});

Solution.displayName = "Solution";

const Beams = ({ className }: { className?: string }) => {
  const containerRef = useRef<HTMLDivElement>(null);

  const refs = [
    useRef<HTMLDivElement>(null),
    useRef<HTMLDivElement>(null),
    useRef<HTMLDivElement>(null),
    useRef<HTMLDivElement>(null),
    useRef<HTMLDivElement>(null),
    useRef<HTMLDivElement>(null),
    useRef<HTMLDivElement>(null),
    useRef<HTMLDivElement>(null),
    useRef<HTMLDivElement>(null),
  ];
  // const controls = useAnimation();
  const rotationSequence = {
    rotate: [0, 40, 100, 150, 200, 360],
    transition: {
      duration: 4,
      ease: "linear",
      times: [0, 0.5],
      repeat: Infinity,
    },
  };
  // const handleHoverEnd = async () => {
  //   await controls.start({
  //     // rotate: 0,
  //     transition: { duration: 1 },
  //   });
  // };
  return (
    <div
      className={classNames(
        "relative flex flex-col w-full items-center justify-center overflow-hidden rounded-lg bg-background px-1 pb-4 lg:p-10",
        className
      )}
      ref={containerRef}
    >
      <div className="flex w-full items-stretch justify-between gap-10">
        <div className="flex flex-col justify-center gap-2">
          {Chunk1.map((chunk, index) => (
            <Solution ref={refs[index]} key={`chunk1-[${index}]`}>
              <Card
                Icon={chunk.Icon}
                title={chunk.title}
                description={chunk.description}
              />
            </Solution>
          ))}
        </div>
        <div className="flex flex-col justify-center">
          <Circle ref={refs[8]} className="size-24 lg:size-36 !p-0">
            <motion.img
              animate={rotationSequence}
              // onHoverEnd={handleHoverEnd}
              // animate={controls}
              loading="lazy"
              src={images.LogoBadge}
              alt="logo"
              className="w-full object-contain"
            />
          </Circle>
        </div>
        <div className="flex flex-col justify-center gap-2">
          {Chunk2.map((chunk, index) => (
            <Solution ref={refs[index + 4]} key={`chunk2-[${index}]`}>
              <Card
                Icon={chunk.Icon}
                title={chunk.title}
                description={chunk.description}
              />
            </Solution>
          ))}
        </div>
      </div>

      {/* AnimatedBeams */}
      <AnimatedBeam
        containerRef={containerRef}
        fromRef={refs[8]}
        toRef={refs[0]}
        duration={3}
        reverse
        delay={1}
      />
      <AnimatedBeam
        containerRef={containerRef}
        fromRef={refs[8]}
        toRef={refs[1]}
        duration={3}
        reverse
        delay={2}
      />
      <AnimatedBeam
        containerRef={containerRef}
        fromRef={refs[8]}
        toRef={refs[2]}
        duration={3}
        reverse
        delay={3}
      />
      <AnimatedBeam
        containerRef={containerRef}
        fromRef={refs[8]}
        toRef={refs[3]}
        duration={3}
        reverse
      />
      <AnimatedBeam
        containerRef={containerRef}
        fromRef={refs[8]}
        toRef={refs[4]}
        duration={3}
      />
      <AnimatedBeam
        containerRef={containerRef}
        fromRef={refs[8]}
        toRef={refs[5]}
        duration={3}
        delay={1}
      />
      <AnimatedBeam
        containerRef={containerRef}
        fromRef={refs[8]}
        toRef={refs[6]}
        duration={3}
        delay={2}
      />
      <AnimatedBeam
        containerRef={containerRef}
        fromRef={refs[8]}
        toRef={refs[7]}
        duration={3}
        delay={3}
      />
    </div>
  );
};

export default Beams;
