import React from "react";
import BorderBeam from "../../components/BorderBeam";
import Button from "../../components/Button";
import images from "../../data/images";
import { Link, useNavigate } from "react-router-dom";
import classNames from "classnames";
import NavItem from "../../components/ui/Navbar/NavItem";
import { FiPhone } from "react-icons/fi";
import MeshBg from "../../components/MeshBg";
import { HiOutlineEnvelope } from "react-icons/hi2";
import "../../styles/Navbar.css";
import { LogIn } from "lucide-react";

const MobileBar = ({
  items,
  isHome,
}: {
  items: { text: string; to: string }[];
  isHome: boolean;
}) => {
  const [isMenuOpen, setIsMenuOpen] = React.useState(false);
  const navigate = useNavigate();
  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };
  const closeMobileMenu = () => {
    setIsMenuOpen(false);
  };
  return (
    <>
      <div
        className={`box-border shrink-0 w-full transition-all duration-300 group self-center z-40 items-center justify-center fixed top-0 backdrop-filter backdrop-blur-lg bg-white/75 ${
          !isHome && "bg-white"
        }`}
      >
        <header
          className={`flex flex-wrap relative gap-4 w-full justify-between items-center px-12 max-md:px-5`}
        >
          <Link to="/">
            <img
              loading="lazy"
              src={isMenuOpen ? images.logoPrimary : images.LogoBadge}
              alt="Company Logo"
              className={
                isMenuOpen ? "mt-3 object-contain w-[10rem]" : "w-[3.5rem]"
              }
            />
          </Link>
          <div className="flex gap-4 items-center">
            {isMenuOpen ? null : (
              <div className="relative">
                <Button
                  text={"Try for free"}
                  onClick={() => navigate("/get-started/sign-up")}
                  className="justify-center items-center !px-8 !py-2 !my-0 !border-none text-white bg-primaryBlue max-md:text-xs"
                />
                <BorderBeam
                  size={50}
                  duration={8}
                  delay={9}
                  colorFrom={"#adffd8"}
                  colorTo={"#669CF4"}
                  className="rounded-lg"
                />
              </div>
            )}

            <div
              className={`hamburger ${isMenuOpen ? "active mt-3" : ""}`}
              onClick={toggleMenu}
            >
              <span className={"bar"}></span>
              <span className={"bar"}></span>
              <span className={"bar"}></span>
            </div>
          </div>
          <nav
            className={classNames(
              "gap-2 justify-center my-auto text-center navMenu",
              isMenuOpen ? "flex" : "hidden"
            )}
          >
            {items.map((item) => (
              <NavItem
                key={item.text}
                text={item.text}
                to={item.to}
                onClick={closeMobileMenu}
              />
            ))}
            <div className="mt-2 pt-4 border-t gap-4 text-sm flex">
              <Link
                to="/get-started"
                className="relative flex px-2 gap-2 font-medium items-center text-primaryBlue"
              >
                <LogIn size={20} />
                <span>Dashboard</span>
              </Link>
            </div>
            <div className="mt-8 gap-4 text-sm flex">
              <Link
                to="mailto:Service@Inter-sight.com"
                className="size-10 border rounded-lg flex items-center justify-center"
              >
                <HiOutlineEnvelope size={24} />
              </Link>
              <Link
                to="tel:+972502000111"
                className="size-10 border rounded-lg flex items-center justify-center"
              >
                <FiPhone size={20} />
              </Link>
            </div>
            <MeshBg className="-top-20 sm:-top-60" />
          </nav>
        </header>
      </div>
      {isMenuOpen ? (
        <div
          className={
            "fixed z-[39] h-full size-full transition-colors duration-300 inset-0 visible bg-black/20"
          }
        />
      ) : null}
    </>
  );
};

export default MobileBar;
