import { useState } from "react";
import { TeammateDT } from "../../../utils/types";
import { TableProps } from "antd";
import { useAlertsContext } from "../../../hooks/useAlertsContext";
import { GetTeammates } from "../services/service";
import { useParams } from "react-router-dom";
import { extractOrderAndField } from "../../../utils/table-sorter";

const useTeammates = () => {
  const { toastError } = useAlertsContext();
  const { teamId } = useParams();
  const [data, setData] = useState<TeammateDT[]>();
  const [searchTerm, setSearchTerm] = useState("");
  const [dateRange, setDateRange] = useState<{
    from?: string | null;
    to?: string | null;
  }>({
    from: null,
    to: null,
  });

  const [tableParams, setTableParams] = useState({
    pagination: {
      current: 1,
      pageSize: 10,
      total: undefined,
    },
    sorter: undefined,
    filters: { active: undefined },
  });
  const [loading, setLoading] = useState(false);
  const FetchTeammates = async () => {
    setLoading(true);
    const { pagination, sorter } = tableParams;
    const sorterData = extractOrderAndField(sorter);
    // Initialize columns array and order array
    const columns: any = [];
    const orderArr: any = [];

    // Fill in columns array with name and orderable properties
    sorterData.forEach(({ field }, index) => {
      if (field) {
        columns.push({ name: field, orderable: true });
      }
    });

    // Fill in order array with column and dir properties
    sorterData.forEach(({ order }, index) => {
      if (order) {
        orderArr.push({ column: index, dir: order || undefined });
      }
    });
    const filters = {
      start: pagination?.current
        ? (pagination?.current - 1) * (pagination?.pageSize || 10)
        : 0,
      length: pagination?.pageSize || 10,
      customSearch: {
        searchTerm,
        teamId: teamId,
        active: tableParams?.filters?.active || null,
        ...dateRange,
      },
      columns,
      order: orderArr,
    };
    await GetTeammates(filters)
      .then((response) => {
        setData(response?.data || []);
        setTableParams({
          ...tableParams,
          pagination: {
            ...tableParams.pagination,
            total: response.recordsTotal,
          },
        });
      })
      .catch((e) => {
        console.log({ e });
        toastError(e?.data?.message);
        if (e?.status && e?.status === 401) {
          toastError(e?.data?.message || "Token Expired");
          // TODO
          //   setTimeout(() => {
          //     dispatch(logout());
          //   }, 1500);
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleTableChange: TableProps<TeammateDT>["onChange"] = (
    pagination,
    filters,
    sorter
  ) => {
    setTableParams((prevState: any) => {
      const updatedParams = {
        ...prevState,
        pagination,
        filters,
        sorter,
      };

      // Reset data when pageSize changes
      if (pagination?.pageSize !== prevState.pagination?.pageSize) {
        setData([]); // Clear data when pageSize changes
      }

      return updatedParams;
    });
  };
  const handlePaginationChange = (current: number, pageSize: number) => {
    setTableParams((prevState: any) => {
      const updatedParams = {
        ...prevState,
        pagination: { current, pageSize },
      };
      // Reset data when pageSize changes
      if (pageSize !== prevState.pagination?.pageSize) {
        setData([]); // Clear data when pageSize changes
      }
      return updatedParams;
    });
  };
  const handleSearch = (search: string) => {
    setSearchTerm(search);
  };

  return {
    data,
    loading,
    tableParams,
    searchTerm,
    dateRange,
    setDateRange,
    handleSearch,
    handleTableChange,
    handlePaginationChange,
    FetchTeammates,
  };
};

export default useTeammates;
