import { useNavigate } from "react-router-dom";

/** Navigate to the top of a page so that the scroll position isn't persisted between pages. Use this instead of React Dom's build-in @see {@link useNavigate}. */
export const useNavigateToTop = () => {
  const navigate = useNavigate();
  const navigateAndReset = (
    to: string,
    replace: boolean = false,
    options: any = {}
  ) => {
    navigate(to, { replace: replace, ...options });
    window.scrollTo(0, 0);
  };
  return navigateAndReset;
};
export const useGoBackToTop = () => {
  const navigate = useNavigate();
  const navigateAndReset = () => {
    navigate(-1);
    setTimeout(() => {
      window.scrollTo(0, 0);
    }, 1000);
  };
  return navigateAndReset;
};
