import SEO from "../layouts/SEO";
import Section from "../layouts/Section";
import React, { useState } from "react";
import { Contact, ContactFormValues } from "../utils/types";
import { useAlertsContext } from "../hooks/useAlertsContext";
import Form from "../components/Form";
import ContactGrid from "../components/ContactGrid";

const inputs = [
  {
    id: 1,
    label: "Name*",
    name: "name",
    placeholder: "Name",
    required: true,
    value: "",
  },
  {
    id: 3,
    label: "Telephone*",
    name: "telephone",
    placeholder: "Telephone",
    type: "tel",
    required: true,
    value: "",
  },
  {
    id: 4,
    label: "Email*",
    name: "email",
    placeholder: "Email",
    type: "email",
    required: true,
    value: "",
  },
  {
    id: 6,
    label: "Company",
    name: "company",
    placeholder: "Company",
    required: true,
    value: "",
  },
];

const initialValues: ContactFormValues = {
  fullName: "",
  company: "",
  email: "",
  telephone: "",
  country: "",
  subject: "",
  message: "",
};
const ContactUs = () => {
  const { toastSuccess } = useAlertsContext();
  const [loading, setLoading] = useState(false);
  const [formValues, setFormValues] =
    React.useState<ContactFormValues>(initialValues);

  const handleSubmitContactUs = async (values: ContactFormValues) => {
    setLoading(true);
    const formValues: Contact = {
      name: values.fullName,
      email: values.email,
      phoneNumber: values.telephone,
      info: values.message,
      subject: values.subject,
      company: values.company,
      country: values.country,
    };
    // Handle submission for contact us form
    //     await ContactUs(formValues)
    //       .then((response) => {
    //         toastSuccess(response || "Form Submitted Successfully");
    //         setFormValues(initialValues);
    //         callBack &&
    //           setTimeout(() => {
    //             callBack();
    //           }, 1000);
    //       })
    //       .catch((e) => toastError(e?.data))
    //       .finally(() => setLoading(false));
    console.log(formValues);
    toastSuccess("Form Submitted Successfully");
    setFormValues(initialValues);
  };
  const handleResetContactUs = () => {
    // Handle reset for contact us form
    setFormValues(initialValues);
  };
  return (
    <Section
      id="ContactUs"
      title="Get in touch with us"
      badge="Contact Us"
      placeholder="Let us know if there is anything we can do for you"
      className="lg:mt-[10rem] mt-[8rem]"
    >
      <SEO title={"Contact Us | InterSight"} />
      <div className="flex flex-col justify-center px-5 lg:px-20 mt-16 lg:mt-20 w-full space-y-16">
        <div className="flex justify-center items-center">
          <Form
            inputs={inputs}
            formValues={formValues}
            setFormValues={setFormValues}
            onSubmit={handleSubmitContactUs}
            onReset={handleResetContactUs}
            loading={loading}
            marketingCheck={false}
            submitText={"Submit"}
          />
        </div>
        <ContactGrid />
      </div>
    </Section>
  );
};

export default ContactUs;
