import React from "react";
import Section from "../../layouts/Section";
import Accordion from "../../components/Accordion";
import { FAQS } from "../../utils/types";

const FAQ = ({ faqs }: { faqs: FAQS[] }) => {
  return (
    <Section
      badge="FAQs"
      title="Frequently asked questions"
      className="box !pb-0"
    >
      <section className="flex flex-col justify-center items-center px-20 mt-16 w-full max-md:px-5 max-md:mt-10">
        <div className="divide-y divide-slate-200 w-[80%] max-md:w-[95%]">
          {faqs.map((faq, index) => (
            <Accordion
              key={index}
              title={faq.question}
              id={`faqs-${index}`}
              active={faq.active}
            >
              {faq.answer}
            </Accordion>
          ))}
        </div>
      </section>
    </Section>
  );
};

export default FAQ;
