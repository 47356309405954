import { IconType } from "react-icons";

const TeamIcon: IconType = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 336.34 304.18"
    fill="currentColor"
    {...props} // Spread any additional props (e.g., size, color)
    stroke="currentColor"
    strokeWidth={10}
  >
    <g>
      <g>
        <path d="M168.17,304.18c-52.38,0-104.75.02-157.13-.07-2.64,0-5.81-.34-7.75-1.81-1.75-1.32-2.99-4.42-3.01-6.74-.2-23.99-.49-48-.01-71.99.91-46.2,45.87-79.03,90.32-66.18,3.08.89,4.91.31,7.19-1.84,39.45-37.18,102.97-36.58,141.55,1.32,1.86,1.82,3.37,2.18,5.89,1.44,45.12-13.43,90.61,20.14,91.03,67.23.21,22.87.05,45.74.04,68.61,0,7.53-2.54,10.03-10.23,10.03-52.63.01-105.25,0-157.88,0ZM256.18,287.86c0-15.28.08-30.14-.03-45-.06-7.36.12-14.8-.84-22.07-7.32-55.36-61.09-89.39-114.35-72.57-36.01,11.38-60.37,45.02-60.7,84.07-.15,17.37-.03,34.75-.02,52.13,0,1.09.13,2.17.22,3.44h175.72ZM16.08,287.83h48.02c0-1.56,0-2.9,0-4.24,0-16.62,0-33.25,0-49.87,0-16.11,3.33-31.49,10.26-46.04,2.56-5.38,5.51-10.59,8.58-16.45-6.74,0-13.32-.92-19.56.16-28.69,4.95-47.22,27.38-47.29,56.51-.05,18.62,0,37.25,0,55.87,0,1.23,0,2.46,0,4.07ZM320.01,287.85c.16-.79.29-1.14.29-1.5,0-20.24.39-40.49-.12-60.72-.59-23.83-15.51-43.64-37.64-51.33-9.39-3.26-18.97-3.44-29.4-2.26,1.04,1.69,1.72,2.81,2.41,3.93,9.49,15.34,15.74,31.86,16.32,49.97.6,19.1.33,38.22.44,57.34,0,1.46,0,2.92,0,4.58h47.69Z" />
        <path d="M168.19,120.01c-32.82-.01-59.97-27.1-60-59.87C108.15,27.26,135.63-.17,168.41,0c32.76.17,59.87,27.44,59.82,60.17-.05,32.77-27.22,59.85-60.04,59.83ZM168.1,16.13c-23.95.06-43.98,20.08-43.96,43.95.02,23.97,20.32,44.11,44.35,44,23.8-.11,43.93-20.43,43.77-44.2-.15-23.95-20.19-43.8-44.17-43.74Z" />
        <path d="M112.09,104.08c0,22.49-17.44,39.92-39.93,39.92-22.49,0-39.93-17.45-39.92-39.93,0-22.49,17.44-39.91,39.93-39.91,22.51,0,39.92,17.41,39.92,39.92ZM72.05,80.08c-13.54.05-23.93,10.54-23.88,24.11.04,13.53,10.55,23.93,24.12,23.88,13.54-.05,23.93-10.54,23.88-24.12-.05-13.54-10.53-23.92-24.12-23.88Z" />
        <path d="M304.17,104.17c-.05,22.49-17.53,39.89-40.02,39.83-22.49-.06-39.89-17.54-39.83-40.02.06-22.49,17.53-39.88,40.02-39.82,22.51.06,39.88,17.51,39.83,40.01ZM264.06,128.07c13.58.08,24.11-10.29,24.18-23.82.07-13.59-10.29-24.1-23.82-24.18-13.58-.07-24.11,10.29-24.18,23.81-.07,13.58,10.29,24.11,23.82,24.18Z" />
      </g>
    </g>
  </svg>
);
const TeamInternalIcon: IconType = (props) => (
  <svg
    viewBox="0 0 371.51 345.41"
    fill="currentColor"
    {...props}
    stroke="currentColor"
    strokeWidth={10}
  >
    <g>
      <g>
        <g>
          <path d="M171.46,191.63c-7.76,0-15.53-.01-23.29-.02-23.52-.03-47.85-.05-71.78.05-31.72.14-59.59,19.34-70.99,48.92-1.7,4.41-2.77,8.95-3.8,13.35-.45,1.92-.92,3.9-1.42,5.79l-.17.63v75.05l.42.95c2.62,6.01,7.67,9.06,15.01,9.06h.08c30.81-.09,62.15-.08,92.45-.07h30.28c11.39,0,15.45-6.97,15.46-12.95.02-6.24-4.03-12.91-15.43-12.95H25.92c0-4.09-.02-8.18-.04-12.26-.05-12.87-.1-26.18.19-39.23.12-5.34,1.1-10.91,2.83-16.1,6.93-20.79,26.2-34.25,49.09-34.3,17.87-.04,35.75-.04,53.62-.03,12.81,0,25.62,0,38.43,0,2.37,0,4.7-.07,7.05-.82,5.81-1.86,9.46-8.16,8.31-14.33-1.25-6.71-6.46-10.71-13.93-10.72Z" />
          <path d="M208.66,24.88C192.59,8.83,171.3,0,148.69,0h-.13C101.89.07,63.89,38.11,63.83,84.8c-.03,22.6,8.81,43.89,24.89,59.95,16.06,16.04,37.34,24.87,59.94,24.87h.22c46.71-.12,84.72-38.14,84.72-84.75,0-22.62-8.86-43.92-24.94-59.99ZM148.73,25.91c15.66,0,30.4,6.13,41.51,17.25,11.17,11.18,17.3,26.04,17.27,41.83-.03,15.74-6.19,30.52-17.34,41.6-11.11,11.05-25.86,17.12-41.56,17.12-.08,0-.16,0-.24,0-32.33-.13-58.55-26.61-58.45-59.03.09-32.39,26.46-58.75,58.78-58.77h.03Z" />
        </g>
        <path d="M236.52,282.48s31.95-33.58,104.29-106.01c4.91-4.91,10.26-8.02,17.38-6.44,12.58,2.78,17.52,17.41,9.24,27.33-1.35,1.62-2.91,3.06-4.4,4.55-71.88,71.89-30.97,32.57-102.85,104.46-1.43,1.43-2.79,2.93-4.98,5.24,2.98,0,4.84,0,6.69,0,40.1,0,30.06-.05,70.16.03,12.81.02,21.14,11.75,16.6,23.07-2.65,6.63-8.79,10.64-16.68,10.66-18.99.05-78.95.03-115.01.01-12.93,0-19.63-6.59-19.63-19.32-.01-55.05.01-59.96-.03-115.01,0-7.15,2.77-12.61,9.02-16.11,5.54-3.1,11.18-2.87,16.54.42,5.92,3.63,8.29,9.14,8.28,16.03-.08,39.57-.04,29-.04,68.58,0,2.1-.2,7.69-.2,7.69l5.61-5.2Z" />
      </g>
    </g>
  </svg>
);
const TeamExternalIcon: IconType = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 371.51 345.41"
    fill="currentColor"
    {...props}
    stroke="currentColor"
    strokeWidth={10}
  >
    <g>
      <g>
        <g>
          <path d="M171.46,191.63c-7.76,0-15.53-.01-23.29-.02-23.52-.03-47.85-.05-71.78.05-31.72.14-59.59,19.34-70.99,48.92-1.7,4.41-2.77,8.95-3.8,13.35-.45,1.92-.92,3.9-1.42,5.79l-.17.63v75.05l.42.95c2.62,6.01,7.67,9.06,15.01,9.06h.08c30.81-.09,62.15-.08,92.45-.07h30.28c11.39,0,15.45-6.97,15.46-12.95.02-6.24-4.03-12.91-15.43-12.95H25.92c0-4.09-.02-8.18-.04-12.26-.05-12.87-.1-26.18.19-39.23.12-5.34,1.1-10.91,2.83-16.1,6.93-20.79,26.2-34.25,49.09-34.3,17.87-.04,35.75-.04,53.62-.03,12.81,0,25.62,0,38.43,0,2.37,0,4.7-.07,7.05-.82,5.81-1.86,9.46-8.16,8.31-14.33-1.25-6.71-6.46-10.71-13.93-10.72Z" />
          <path d="M208.66,24.88C192.59,8.83,171.3,0,148.69,0h-.13C101.89.07,63.89,38.11,63.83,84.8c-.03,22.6,8.81,43.89,24.89,59.95,16.06,16.04,37.34,24.87,59.94,24.87h.22c46.71-.12,84.72-38.14,84.72-84.75,0-22.62-8.86-43.92-24.94-59.99ZM148.73,25.91c15.66,0,30.4,6.13,41.51,17.25,11.17,11.18,17.3,26.04,17.27,41.83-.03,15.74-6.19,30.52-17.34,41.6-11.11,11.05-25.86,17.12-41.56,17.12-.08,0-.16,0-.24,0-32.33-.13-58.55-26.61-58.45-59.03.09-32.39,26.46-58.75,58.78-58.77h.03Z" />
        </g>
        <path d="M332.3,232.54s-31.95,33.58-104.29,106.01c-4.91,4.91-10.26,8.02-17.38,6.44-12.58-2.78-17.52-17.41-9.24-27.33,1.35-1.62,2.91-3.06,4.4-4.55,71.88-71.89,30.97-32.57,102.85-104.46,1.43-1.43,2.79-2.93,4.98-5.24-2.98,0-4.84,0-6.69,0-40.1,0-30.06.05-70.16-.03-12.81-.02-21.14-11.75-16.6-23.07,2.65-6.63,8.79-10.64,16.68-10.66,18.99-.05,78.95-.03,115.01-.01,12.93,0,19.63,6.59,19.63,19.32.01,55.05-.01,59.96.03,115.01,0,7.15-2.77,12.61-9.02,16.11-5.54,3.1-11.18,2.87-16.54-.42-5.92-3.63-8.29-9.14-8.28-16.03.08-39.57.04-29,.04-68.58,0-2.1.2-7.69.2-7.69l-5.61,5.2Z" />
      </g>
    </g>
  </svg>
);
const svgs = {
  TeamIcon,
  TeamInternalIcon,
  TeamExternalIcon,
};
export default svgs;
