import { IoIosStats } from "react-icons/io";
import { BiNetworkChart } from "react-icons/bi";
import { IoAnalytics } from "react-icons/io5";
import { ReportTypeEnum } from "../../../utils/types";
export const Types = [
  {
    title: "Statistics",
    type: ReportTypeEnum.STATISTICS,
    Icon: <IoIosStats />,
  },
  {
    title: "Connections",
    type: ReportTypeEnum.CONNECTIONS,
    Icon: <BiNetworkChart />,
  },
  {
    title: "Analytics",
    type: ReportTypeEnum.ANALYTICS,
    Icon: <IoAnalytics />,
  },
];
