import React from "react";
import Section from "../../layouts/Section";
import images from "../../data/images";
import BentoGrid from "../../components/Bento/BentoGrid";
import BentoCard, { BentoCardProps } from "../../components/Bento/BentoCard";

// type FeatureCardProps = {
//   imageSrc: string;
//   title: string;
//   description: string;
//   className: string;
//   index: number;
//   Icon?: any;
// };

// const FeatureCard: React.FC<FeatureCardProps> = ({
//   imageSrc,
//   title,
//   description,
//   className,
//   index,
//   Icon,
// }) => (
//   <motion.article
//     initial={{ scale: 0.8, opacity: 0.7 }}
//     whileInView={{ scale: 1, opacity: 1 }}
//     transition={{
//       duration: index * 0.35,
//       ease: "easeInOut",
//     }}
//     className={`flex flex-col justify-between rounded-xl group transition-all ease-in bg-gray-50 border border-gray-100 ${className}`}
//   >
//     <div className="p-8 w-[80%]">
//       {Icon ? (
//         <Icon className="h-12 w-12 origin-left transform-gpu text-neutral-700 transition-all duration-300 ease-in-out group-hover:scale-75" />
//       ) : null}
//       <h3 className="text-xl font-semibold">{title}</h3>
//       <p className="leading-5 text-neutral-400">{description}</p>
//     </div>
//     <div className="w-[80%] max-md:w-full rounded-xl flex justify-end bg-white overflow-clip">
//       <img
//         loading="lazy"
//         src={imageSrc}
//         alt={title}
//         className="object-contain w-full rounded-xl transition-transform duration-300 ease-in-out group-hover:scale-105"
//       />
//     </div>
//   </motion.article>
// );

const features: BentoCardProps[] = [
  {
    index: 1,
    background: (
      <img src={images.feature1} alt="feature 1" loading="lazy" className="w-[70%]" />
    ),
    name: "Statistics Outlook",
    className: "col-span-3 lg:col-span-1",
    description:
      "Visualize your team Email statistics to improve your management skills such as Performance Measurement, Resources Optimization, Quality Control, Customer Satisfaction, and more.",
  },
  {
    index: 2,
    background: <img src={images.feature3} alt="feature 2" loading="lazy" className="w-[70%]" />,
    className: "col-span-3 lg:col-span-1",
    name: "Analytical Sights",
    description:
      "Search connections between internal or external Email Domains to enhance your understanding of communication between employees, Customers and providers, gain Insights, recognize Challenges & Opportunities, and eliminate Bottlenecks.",
  },
  {
    index: 3,
    background: <img src={images.feature2} alt="feature 3" loading="lazy" />,
    className: "col-span-3 lg:col-span-1",
    name: "Situational States",
    description:
      "Identify work group Interactions and build quality business processes, Increase Relevance, Improve Decision-Making, and Solve Problems in no time. Our innovative visualizations will help you foster Collaboration & coordination for better teamwork.",
  },
];
const Features = () => {
  return (
    <Section
      className="box"
      badge="Explore Our Features"
      title="Dashboards Collection"
      placeholder="Explore new possibilities, view your data in a unique perspective."
    >
      <section className="flex flex-wrap justify-center px-20 mt-16 max-md:px-5 max-md:mt-10">
        <BentoGrid className="auto-rows-auto">
          {features.map((feature, index) => (
            <BentoCard type="basic" key={index} {...feature} index={index} />
          ))}
        </BentoGrid>
      </section>
    </Section>
  );
};

export default Features;
