export const API_ENDPOINTS = {
  GET_PAYMENT_FORM: "/Payments/CreatePaymentProcess",
  PRICING: "/Pricings/Get",
  DASHBOARD_PRICING: "/Pricings/Customers/Get",
  REGISTER: "/register",
  LOGOUT: "/logout",
  LOGIN: "/User/Login",
  GET_PROFILE: "/User/Get",
  REFRESH_TOKEN: "/User/RefreshToken",
  GET_TEAMS: "/Teams/GetAllDT",
  TEAMS_SELECTION: "/Teams/SelectionList",
  GET_TEAM_BY_ID: "/Teams/GetByID",
  CREATE_TEAM: "/Teams/Create",
  EDIT_TEAM: "/Teams/Edit",
  DELETE_TEAM: "/Teams/Delete",
  GOOGLE_LOG_IN: "/Auth/Google/Signin",
  MS_LOG_IN: "/Auth/Microsoft/Signin",
  GET_TEAMMATES: "/Teammates/GetAllDT",
  GET_TEAMMATES_BY_ID: "/Teammates/GetByID",
  INVITE_TEAMMATES: "/Teammates/Invite",
  RESEND_INVITATION_TEAMMATES: "/Teammates/ReSendInvitation",
  DELETE_TEAMMATES: "/Teammates/Delete",
  FETCH_INVITE: "/Invites/Get",
  DELETE_ACCOUNT: "/Profiles/DeleteMyAccount",
  GET_STATISTICS: "/Reports/Get",
  GET_MESSAGES_VOLUME: "/Reports/GetMessagesVolume",
  GET_RESPOND_TIME_DISTRIBUTION: "/Reports/GetRespondTimeDistribution",
  GET_CONNECTIVITY_RANKS: "/Reports/GetConnectivityRanks",
  GET_CONNECTIVITY_SCOPE: "/Reports/GetConnectivityScope",
  GET_PEOPLE: "/People/GetAllDT",
  UPDATE_PEOPLE: "/People/Update",
  DELETE_PEOPLE: "/People/Delete",
};
