import React from "react";
import { RiEyeLine } from "react-icons/ri";
import { Tooltip } from "antd";
import Icon from "../../../components/ui/Buttons/Icon";
import { useNavigateToTop } from "../../../hooks/useNavigateToTop";
import { FiEdit } from "react-icons/fi";
import { TeamDT } from "../../../utils/types";
import DeleteTeam from "./DeleteTeam";

const Actions = ({
  team,
  fetchTeams,
}: {
  team: TeamDT;
  fetchTeams: Function;
}) => {
  const navigateToTop = useNavigateToTop();

  return (
    <div className="flex gap-2">
      <Tooltip title="View Team's Details">
        <div>
          <Icon
            onClick={(e) => {
              e?.stopPropagation();
              navigateToTop(`${team.id}`, false, { state: { team } });
            }}
          >
            <RiEyeLine />
          </Icon>
        </div>
      </Tooltip>
      <Tooltip title="Edit Team">
        <div>
          <Icon
            onClick={(e) => {
              e?.stopPropagation();
              navigateToTop(`Edit/${team.id}`, false, { state: { team } });
            }}
          >
            <FiEdit />
          </Icon>
        </div>
      </Tooltip>
      {/* <EditTeam fetchTeams={fetchTeams} /> */}
      <DeleteTeam teamId={team.id} fetchTeams={fetchTeams} />
    </div>
  );
};

export default Actions;
