// dayjs imports
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";
import dayjsRelativeTime from "dayjs/plugin/relativeTime";
import advancedFormat from "dayjs/plugin/advancedFormat";
import quarterOfYear from "dayjs/plugin/quarterOfYear";
import weekOfYear from "dayjs/plugin/weekOfYear";
// dayjs config
dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.extend(dayjsRelativeTime);
dayjs.extend(advancedFormat);
dayjs.extend(weekOfYear);
dayjs.extend(quarterOfYear);

// // dayjs timezone config
// let tzLocal = dayjs.tz.guess(); // gets local client timezone - Europe/Madrid (CET)
// tzLocal = "America/New_York"; // reassign to specific timezone - America/New_York (EST)
// dayjs.tz.setDefault(tzLocal); // sets default timezone ONLY when .tz() is chained !!

/**
 * NOTE: benchmarks for Number(string) VS parseInt(string, 10)
 * show Number() approx 33% faster performance
 * ref: http://phrogz.net/js/string_to_number.html
 */

export const FormatHelper = {
  formatDateLocal: (date: string) => {
    // LOCAL timezone - Europe/Madrid (CET)
    // IGNORES dayjs.tz.setDefault() setting because .tz() NOT chained
    return dayjs(Number(date)).format("DD MMMM YYYY HH:mm:ss:SSS");
  },
  formatDateUTC: (date: string) => {
    // UTC universal time
    // IGNORES dayjs.tz.setDefault() setting because .tz() NOT chained
    return dayjs.utc(Number(date)).format("DD MMMM YYYY HH:mm:ss:SSS");
  },
  formatDateNYC: (date: string) => {
    // America/New_York (EST) - set by dayjs.tz.setDefault()
    dayjs.tz.setDefault("America/New_York");
    return dayjs(Number(date)).tz().format("DD MMMM YYYY HH:mm:ss:SSS");
  },
  DateFormat: (date?: string) =>
    date ? dayjs(date).format("DD/MM/YYYY") : null,

  formatDistanceToNow: (date: string, addSuffix: boolean = true) =>
    dayjs(date).fromNow(),
  // dayjs(date).fromNow(!addSuffix),
};

declare namespace Intl {
  type Key =
    | "calendar"
    | "collation"
    | "currency"
    | "numberingSystem"
    | "timeZone"
    | "unit";

  function supportedValuesOf(input: Key): string[];
}
// const now = String(Date.now()); // timestamps will be fetched as strings
export const timezones = Intl?.supportedValuesOf("timeZone")?.map((v) => ({
  label: v,
  value: v.toLowerCase(),
}));
export const tzLocal = dayjs.tz.guess();
export const YearNow = new Date().getFullYear();
