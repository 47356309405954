import React, { ReactNode } from "react";
import { Link, useMatch, useResolvedPath } from "react-router-dom";
import { FaChevronDown, FaChevronUp } from "react-icons/fa6";
import { IconType } from "react-icons";
import { cn } from "../../../utils/utils";

interface NavProps {
  Icon?: IconType;
  badge?: ReactNode;
  text: string;
  to: string;
}
interface NavItemProps extends NavProps {
  innerList?: NavProps[];
  onClick?: any;
}
const iconCls =
  "flex-shrink-0 w-5 h-5 text-gray-400 transition-all duration-75 group-hover:text-gray-900";
const SideNavItem: React.FC<NavItemProps> = ({
  Icon,
  text,
  badge,
  to,
  innerList,
  onClick = () => {},
  ...props
}) => {
  const resolvedPath = useResolvedPath(to);
  const isActive = useMatch({ path: resolvedPath.pathname, end: true });
  const isNested = innerList && innerList.length > 0;
  const [isToggled, setIsToggled] = React.useState(false);
  const toggleMenu = () => {
    setIsToggled(!isToggled);
  };
  return !isNested ? (
    <Link
      to={to}
      onClick={onClick}
      className={`relative w-full flex items-center py-2 px-4 text-gray-900 hover:bg-gray-50 group ${
        isActive ? "font-bold text-primaryBlue" : null
      }`}
    >
      {isActive && (
        <span className="absolute w-[5px] h-full left-0 top-0 bg-primaryBlue rounded-r-full" />
      )}

      <div className="flex gap-2 items-center">
        {Icon ? (
          <Icon className={cn(iconCls, isActive && "text-primaryBlue")} />
        ) : null}
        {text}
      </div>
      {badge ? badge : null}
    </Link>
  ) : (
    <>
      <button
        type="button"
        onClick={toggleMenu}
        className={`flex w-full items-center gap-2 bg-transparent justify-between p-2 text-gray-900 rounded-lg hover:bg-gray-100 group`}
      >
        <div className="flex gap-2 items-center">
          {Icon ? (
            <Icon className={cn(iconCls, isActive && "text-primaryBlue")} />
          ) : null}
          {text}
        </div>
        {isToggled ? <FaChevronDown /> : <FaChevronUp />}
      </button>
      <ul
        id="dropdown-example"
        className={`${isToggled ? "hidden" : ""} py-2 space-y-2 text-sm`}
      >
        {innerList.map((item, index) => (
          <li key={index}>
            <Link
              to={item.to}
              className={` ${
                isActive
                  ? "font-bold border-l-2 border-l-primaryBlue bg-gray-100"
                  : null
              } flex items-center w-full p-2 text-gray-900 transition duration-75 rounded-lg pl-8 group hover:bg-gray-100`}
            >
              {item.text}
            </Link>
          </li>
        ))}
      </ul>
    </>
  );
};

export default SideNavItem;
