import React from "react";
import { Skeleton } from "../../../components/ui/skeleton";
import { Card, CardContent, CardHeader } from "../../../components/ui/card";

const LoadingParameter = () => {
  return (
    <div className="flex flex-col gap-4 md:gap-8 mt-4 md:mt-8">
      <div className="grid gap-4 md:grid-cols-2 md:gap-8 lg:grid-cols-5">
        {[1, 1, 1, 1, 1].map((stat, index) => (
          <Card key={index}>
            <CardHeader className="flex flex-row justify-between items-center">
              <Skeleton className="h-[1rem] w-[80%] rounded-xl" />
              <Skeleton className="size-5 rounded-full" />
            </CardHeader>
            <CardContent>
              <Skeleton className="h-[2rem] w-full rounded-xl" />
            </CardContent>
          </Card>
        ))}
      </div>
    </div>
  );
};

export default LoadingParameter;
