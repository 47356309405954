import React from "react";
import { ProfileDT, TableParams } from "../../../../utils/types";
import { Pagination, PaginationProps, Table, TableProps } from "antd";
import Actions from "./Actions";
import { FormatHelper } from "../../../../utils/timezone";

type ColumnsType<T> = TableProps<T>["columns"];

const ProfilesTable = ({
  data,
  loading,
  tableParams,
  handleTableChange,
  handlePaginationChange,
  fetchTeams,
}: {
  data?: any[];
  loading: boolean;
  tableParams: TableParams;
  handleTableChange: any;
  handlePaginationChange: any;
  fetchTeams: Function;
}) => {
  const { pagination } = tableParams;

  const onChange: PaginationProps["onChange"] = (page, pageSize) => {
    handlePaginationChange(page, pageSize);
  };

  const columns: ColumnsType<any> = [
    {
      title: "Index",
      dataIndex: "index",
      render: (_, record, index) =>
        ((pagination?.current || 1) - 1) * (pagination?.pageSize || 10) +
        index +
        1,
    },
    {
      title: "Email",
      dataIndex: "email",
      sorter: true,
    },
    {
      title: "Type",
      dataIndex: "type",
      render: (_: any, record: ProfileDT) => <span>{record?.type?.name}</span>,
    },
    {
      title: "Joining date",
      dataIndex: "createdAt",
      sorter: true,
      render: (_: any, record: ProfileDT) => (
        <span>{FormatHelper.DateFormat(record.createdAt)}</span>
      ),
    },
    // {
    //   title: "Group",
    //   dataIndex: "group",
    //   render: (_: any, record: any) => (
    //     <div className="flex gap-1 items-center">
    //       <div
    //         className="size-4 rounded-full"
    //         style={{ backgroundColor: record.group.color }}
    //       />
    //       {record.group.title}
    //     </div>
    //   ),
    // },
    // {
    //   title: "Status",
    //   dataIndex: "active",
    //   sorter: true,
    //   filterMultiple: false,
    //   showSorterTooltip: { target: "full-header" },
    //   filters: [
    //     {
    //       text: <Activity activity={true} small />,
    //       value: true,
    //     },
    //     {
    //       text: <Activity activity={false} small />,
    //       value: false,
    //     },
    //   ],
    //   render: (_: any, record: ProfileDT) => (
    //     <Activity activity={record?.active} />
    //   ),
    // },

    // {
    //   title: "Status",
    //   dataIndex: "status",
    //   filterMultiple: false,
    //   showSorterTooltip: { target: "full-header" },
    //   filters: [
    //     {
    //       text: <Status status={ProfileStatus.INVITE_SENT} small />,
    //       value: ProfileStatus.INVITE_SENT,
    //     },
    //     {
    //       text: <Status status={ProfileStatus.PENDING} small />,
    //       value: ProfileStatus.PENDING,
    //     },
    //     {
    //       text: <Status status={ProfileStatus.APPROVED} small />,
    //       value: ProfileStatus.APPROVED,
    //     },
    //     {
    //       text: <Status status={ProfileStatus.IGNORED} small />,
    //       value: ProfileStatus.IGNORED,
    //     },
    //   ],
    //   render: (_: any, record: any) => <Status status={record?.status} />,
    //   sorter: true,
    // },
    {
      title: "Actions",
      dataIndex: "",
      key: "x",
      render: (_: any, record: ProfileDT) => (
        <Actions team={record} fetchTeams={fetchTeams} />
      ),
    },
  ];
  return (
    <div className="my-8">
      <Table
        scroll={{ x: "max-content" }}
        columns={columns}
        rowKey={(record) => record.id}
        dataSource={data}
        pagination={pagination}
        size="small"
        sortDirections={["ascend", "descend"]}
        loading={loading}
        rowClassName={"cursor-pointer editable-row"}
        className="table-top-align"
        onChange={handleTableChange}
        // onRow={(record) => {
        //   return {
        //     onClick: (e) => {
        //       e?.stopPropagation();
        //       console.log({ record });
        //       navigateToTop(`${record.id}`, false, {
        //         state: { team: record },
        //       });
        //     },
        //   };
        // }}
      />
      <div className="mt-8">
        <Pagination
          total={pagination?.total}
          showTotal={(total, range) =>
            `${range[0]}-${range[1]} of ${total} items`
          }
          // responsive
          showSizeChanger
          onChange={onChange}
          pageSize={pagination?.pageSize}
          current={pagination?.current}
        />
      </div>
    </div>
  );
};

export default ProfilesTable;
