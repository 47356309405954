import React from "react";

const ListWithAction = ({
  leftNode,
  rightNode,
}: {
  leftNode: React.ReactNode;
  rightNode: React.ReactNode;
}) => {
  return (
    <div className="border-b border-gray-900/10 pb-6">
      <div className="grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6 grid-flow-row">
        <div className="lg:col-span-4">{leftNode}</div>
        <div className="lg:col-span-2 lg:place-self-end">{rightNode}</div>
      </div>
    </div>
  );
};

export default ListWithAction;
