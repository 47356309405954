import { useState } from "react";
import { TeamDT, TableParams } from "../../../utils/types";
import { TableProps } from "antd";
import { useAlertsContext } from "../../../hooks/useAlertsContext";
import { GetTeams } from "../services/service";
import { extractOrderAndField } from "../../../utils/table-sorter";

const useTeams = () => {
  const { toastError } = useAlertsContext();
  // const dispatch = useAppDispatch();
  // const { logout } = AuthActions();

  const [data, setData] = useState<TeamDT[]>();
  const [searchTerm, setSearchTerm] = useState("");
  const [dateRange, setDateRange] = useState<{
    from?: string | null;
    to?: string | null;
  }>({
    from: null,
    to: null,
  });
  const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);
  const [tableParams, setTableParams] = useState<TableParams>({
    pagination: {
      current: 1,
      pageSize: 10,
    },
  });
  const [loading, setLoading] = useState(false);
  const FetchTeams = async () => {
    setLoading(true);
    const { pagination, sorter } = tableParams;
    const sorterData = extractOrderAndField(sorter);
    // Initialize columns array and order array
    const columns: any = [];
    const orderArr: any = [];

    // Fill in columns array with name and orderable properties
    sorterData.forEach(({ field }, index) => {
      if (field) {
        columns.push({ name: field, orderable: true });
      }
    });

    // Fill in order array with column and dir properties
    sorterData.forEach(({ order }, index) => {
      if (order) {
        orderArr.push({ column: index, dir: order || undefined });
      }
    });
    const filters = {
      start: pagination?.current
        ? (pagination?.current - 1) * (pagination?.pageSize || 10)
        : 0,
      length: pagination?.pageSize || 10,
      customSearch: {
        searchTerm,
        active: tableParams?.filters?.active || null,
        ...dateRange,
      },
      columns,
      order: orderArr,
    };
    await GetTeams(filters)
      .then((response) => {
        setData(response.data);
        setTableParams({
          ...tableParams,
          pagination: {
            ...tableParams.pagination,
            total: response.recordsTotal,
          },
        });
      })
      .catch((e) => {
        if (e?.status && e?.status === 401) {
          toastError(e?.data || "Token Expired");
          // TODO
          //   setTimeout(() => {
          //     dispatch(logout());
          //   }, 1500);
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleTableChange: TableProps["onChange"] = (
    pagination,
    filters,
    sorter
  ) => {
    setTableParams({
      pagination,
      filters,
      sorter,
    });
    // `dataSource` is useless since `pageSize` changed
    if (pagination?.pageSize !== tableParams?.pagination?.pageSize) {
      setData([]);
    }
  };
  const handlePaginationChange = (current: number, pageSize: number) => {
    setTableParams((prevState) => ({
      ...prevState,
      pagination: { pageSize, current },
    }));
    // `dataSource` is useless since `pageSize` changed
    if (pageSize !== tableParams?.pagination?.pageSize) {
      setData([]);
    }
  };
  const onSelectChange = (newSelectedRowKeys: React.Key[]) => {
    setSelectedRowKeys(newSelectedRowKeys);
  };
  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };
  const hasSelected = selectedRowKeys.length > 0;

  const handleSearch = (search: string) => {
    setSearchTerm(search);
  };

  return {
    data,
    loading,
    hasSelected,
    tableParams,
    selectedRowKeys,
    rowSelection,
    searchTerm,
    dateRange,
    setDateRange,
    handleSearch,
    handleTableChange,
    handlePaginationChange,
    FetchTeams,
  };
};

export default useTeams;
