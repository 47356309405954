import React from "react";

interface TitleProps {
  title: string;
  subTitle?: string;
}
const PageTitle: React.FC<TitleProps> = ({ title, subTitle }) => (
  <div className="flex gap-4 items-center my-4 max-md:w-full">
    <div className="w-1.5 h-full bg-primaryBlue rounded-xl py-4" />
    <div>
      <h1 className="font-black text-2xl tracking-wide">{title}</h1>
      {subTitle ? <h6 className="text-sm text-gray-400">{subTitle}</h6> : null}
    </div>
  </div>
);

export default PageTitle;
