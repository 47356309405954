import BaseService from "../../services/BaseService";
import { API_ENDPOINTS } from "../../utils/api-endpoints";
import {
  DashboardPricing,
  Pricing,
  RequestMethod,
  ResponseType,
  SubscriptionType,
} from "../../utils/types";

export const GetPricing = async (
  requestBody: any
): Promise<ResponseType<Pricing[]>> =>
  await BaseService(API_ENDPOINTS.PRICING, RequestMethod.GET, requestBody);

export const GetDashboardPricing = async (): Promise<
  ResponseType<DashboardPricing>
> => await BaseService(API_ENDPOINTS.DASHBOARD_PRICING, RequestMethod.GET);

export const GetPaymentForm = async (data: {
  pricingId: string;
  paymentType: SubscriptionType;
}): Promise<{ data: any }> =>
  await BaseService(API_ENDPOINTS.GET_PAYMENT_FORM, RequestMethod.POST, data);
