import { AxiosError, AxiosResponse, ResponseType } from "axios";
import { RequestMethod } from "../utils/types";
import HttpRequestIntercept from "../utils/http-intercept";

export default function BaseService(
  url: string,
  method: RequestMethod,
  body?: any,
  cancelToken?: any,
  isLocalHost?: boolean,
  responseType?: ResponseType,
  isBlob?: boolean
): Promise<any> {
  const promise = new Promise<AxiosResponse<any, any>>((resolve, reject) => {
    HttpRequestIntercept(
      url,
      method,
      body,
      cancelToken,
      isLocalHost,
      responseType
    )
      .then((result: AxiosResponse) => {
        if (isBlob) {
          resolve(result);
        } else {
          resolve(result?.data);
        }
      })
      .catch((e: AxiosError) => {
        reject(e?.response);
      });
  });
  return promise;
}
