import { useLocation, Outlet, Navigate } from "react-router-dom";
import Section from "./Section";
import React, { useEffect } from "react";
import Event from "../utils/event";
import Sidebar from "./Navbar/Sidebar";
import AuthActions from "../modules/auth/actions";
import { useAppDispatch } from "../hooks/useStore";
import AuthSelectors from "../modules/auth/selectors";
import { useMediaQuery } from "react-responsive";
import { cn } from "../utils/utils";

const ProtectedRoute = () => {
  const { isLoggedIn, token } = AuthSelectors();
  const location = useLocation();
  const dispatch = useAppDispatch();
  const { logout } = AuthActions();
  const isMobile = useMediaQuery({ maxWidth: "769px" });

  useEffect(() => {
    const handleUnauthorizedError = () => {
      dispatch(logout());
    };
    Event.on("unauthorizedError", handleUnauthorizedError);
    return () => {
      Event.removeListener("unauthorizedError", handleUnauthorizedError);
    };
  }, []);

  return (
    <Section
      id={location?.pathname?.split("/")[1] || ""}
      className={"!p-0 !m-0 bg-gray-50"}
    >
      {!isLoggedIn || !token ? (
        <Navigate to="/" replace state={{ from: location }} />
      ) : (
        <>
          <Sidebar />
          <div
            className={cn(
              "lg:mt-0 bg-white min-h-dvh rounded-tl-3xl",
              !isMobile && "ml-[17rem]"
            )}
          >
            {/* <div className="flex flex-col items-center p-4 border-2 border-gray-200 border-dashed rounded-lg dark:border-gray-700 max-md:mt-4"> */}
            <Outlet />
          </div>
        </>
      )}
    </Section>
  );
};

export default ProtectedRoute;
