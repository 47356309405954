import React from "react";
import SectionBadge from "../../components/SectionBadge";
import Section from "../../layouts/Section";
import images from "../../data/images";
import { PiPlayCircleBold } from "react-icons/pi";

type StroyProps = {
  id: string | number;
  title: string;
  href: string;
  image?: string;
  description: string;
  company: {
    name: string;
    href: string;
    imageUrl: string;
  };
};
const stories: StroyProps[] = [
  {
    id: 1,
    title: "Story 1",
    href: "#",
    image:
      "https://images.unsplash.com/photo-1518770660439-4636190af475?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80",
    description:
      "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.",
    company: {
      name: "Codit Software",
      href: "#",
      imageUrl: images.logoPrimary,
    },
  },
  {
    id: 2,
    title: "Story 2",
    href: "#",
    image:
      "https://images.unsplash.com/photo-1518770660439-4636190af475?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80",
    description:
      "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.",
    company: {
      name: "Codit Software",
      href: "#",
      imageUrl: images.logoPrimary,
    },
  },
  {
    id: 3,
    title: "Story 3",
    href: "#",
    image:
      "https://images.unsplash.com/photo-1518770660439-4636190af475?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80",
    description:
      "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.",
    company: {
      name: "Codit Software",
      href: "#",
      imageUrl: images.logoPrimary,
    },
  },
  {
    id: 4,
    title: "Story 4",
    href: "#",
    image:
      "https://images.unsplash.com/photo-1518770660439-4636190af475?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80",
    description:
      "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.",
    company: {
      name: "Codit Software",
      href: "#",
      imageUrl: images.logoPrimary,
    },
  },
];
const Story = ({ story }: { story: StroyProps }) => {
  const { title, image, description, company } = story;
  return (
    <article className="col-span-2 lg:col-span-1 flex overflow-hidden max-w-xl flex-col items-start justify-between">
      <div className="relative w-full">
        <img
          src={image}
          loading="lazy"
          className="grayscale w-full aspect-square object-cover mb-4 rounded-lg"
          alt={title}
        />
        <div className="bg-gray-50 rounded-lg absolute bottom-5 left-5 z-10 p-2  mb-4">
          <img
            loading="lazy"
            src={company.imageUrl}
            className="grayscale w-20 object-contain"
            alt={company.name}
          />
        </div>
      </div>
      <h3 className="text-lg font-semibold">{title}</h3>
      <div className="relative">
        <p className="h-[4rem] overflow-hidden text-sm">{description}</p>
        <div className="absolute inset-x-0 bottom-0 h-6 bg-gradient-to-t from-white via-transparent"></div>
      </div>
    </article>
  );
};
const CaseStudies = () => {
  return (
    <Section>
      <div className="mx-auto max-w-7xl">
        <SectionBadge text={"Cases"} className="px-8" />
        <div className="mx-auto max-w-2xl lg:mx-0">
          <h2 className="text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">
            Case Studies and Success Stories
          </h2>
          <p className="mt-2 text-lg leading-8 text-gray-600">
            Example of how others have successfully used the product or service.
          </p>
        </div>
        <div className="flex max-md:flex-col gap-8 mt-8 mb-4 border-b pb-4">
          <div className="relative overflow-clip rounded-xl flex items-center justify-center w-full lg:w-3/4 cursor-pointer aspect-square lg:aspect-[4/2]">
            <PiPlayCircleBold color={"#fff"} size={50} className="z-20" />
            <div className="size-full bg-black/20 absolute z-10" />
            <img
              loading="lazy"
              src={images.company1}
              alt="company"
              className="object-cover size-full absolute z-0 grayscale"
            />
          </div>
          <div className="flex flex-col gap-4 w-full lg:w-1/4">
            <h3 className="text-2xl font-bold">
              X’s Trusted Partner for CyberSecurity
            </h3>
            <p>
              The X software company was able to scale their security production
              to drive a 28x ROI.
            </p>
          </div>
        </div>
        <div className="grid grid-cols-4 gap-4 mt-8">
          {stories.map((story, index) => (
            <Story story={story} key={index} />
          ))}
        </div>
      </div>
    </Section>
  );
};

export default CaseStudies;
