import React from "react";
import Section from "../../layouts/Section";
import Button from "../../components/Button";
import { Link, Navigate, useLocation } from "react-router-dom";
import AuthActions from "../../modules/auth/actions";
import AuthSelectors from "../../modules/auth/selectors";
import GoogleIcon from "../../data/GoogleIcon";
import MicrosoftIcon from "../../data/MicrosoftIcon";
const Authentication = () => {
  const { googleLoginAction, msLoginAction } = AuthActions();
  const { isLoggedIn, token } = AuthSelectors();
  const location = useLocation();

  return isLoggedIn && token ? (
    <Navigate to="/Dashboard" replace state={{ from: location }} />
  ) : (
    <Section id="Authentication" className="!p-0 !m-0 h-[92dvh]">
      <div className="flex flex-col justify-center items-center px-6 py-12 lg:px-8 h-full">
        <div className="sm:mx-auto sm:w-full sm:max-w-sm">
          <h2 className="mt-12 text-center text-2xl font-bold leading-9 tracking-tight">
            Log in to InterSight
          </h2>
          <div className="flex max-md:flex-col gap-2 items-center justify-center text-center">
            <span className="text-placeholder">{"Don’t have an account?"}</span>
            <Link
              to="/get-started/sign-up"
              className="text-primaryBlue font-bold"
            >
              {"Sign up for free"}
            </Link>
          </div>
        </div>
        <div className="sm:mx-auto sm:w-full sm:max-w-sm my-4">
          <Button
            text={"Continue with Google"}
            variant="secondary"
            onClick={() => googleLoginAction()}
            className="justify-center items-center !px-4 !lg:px-12 !py-3 w-full !max-md:px-5"
            icon={<GoogleIcon />}
          />
          <Button
            text={"Continue with Microsoft"}
            variant="secondary"
            className="justify-center items-center !px-4 !lg:px-12 !py-3 w-full !max-md:px-5"
            onClick={() => msLoginAction()}
            icon={<MicrosoftIcon />}
          />
        </div>
        <span className="sm:mx-auto sm:w-full sm:max-w-sm text-placeholder text-center text-sm">
          {
            "By logging in, I declare that I have read and accepted InterSight’s"
          }
          <Link to="/terms-of-service" className="font-bold mx-1">
            {"Terms and Conditions"}
          </Link>
          and
          <Link to="/privacy-policy" className="font-bold ml-1">
            {"Privacy Policy."}
          </Link>
        </span>
      </div>
    </Section>
  );
};

export default Authentication;
