import React from "react";
import "./styles/tailwind.css";
import { RouterProvider, createBrowserRouter } from "react-router-dom";
import Home from "./pages/Home";
import "./styles/App.css";
import NotFound from "./pages/NotFound";
import Loading from "./components/ui/Loading";
import { AlertsContextProvider } from "./context/AlertContext";
import ProtectedRoute from "./layouts/ProtectedRoute";
import Dashboard from "./pages/Dashboard";
import Settings from "./pages/Dashboard/Settings";
import GetStarted from "./pages/Authentication/get-started";
import Authentication from "./pages/Authentication/Authentication";
import HomeLayout from "./layouts/home-layout";
import StarterLayout from "./layouts/starter-layout";
import RequestDemo from "./pages/Authentication/RequestDemo";
import Help from "./pages/Help";
import Profile from "./pages/Dashboard/Account";
import Subscription from "./pages/Dashboard/Subscription";
import Signup from "./pages/Authentication/Signup";
import Teams from "./pages/Team";
import { Provider } from "react-redux";
import store from "./store/store";
import Subscribe from "./pages/Subscribe";
import View from "./pages/Team/View";
import Edit from "./pages/Team/Edit";
import New from "./pages/Team/New";
import GoogleCallback from "./pages/Authentication/GoogleCallback";
import Security from "./pages/Security";
import MSCallback from "./pages/Authentication/MSCallback";
import AcceptInvitation from "./pages/AcceptInvitation";
import ContactUs from "./pages/ContactUs";
import Billing from "./pages/Dashboard/Subscription/Billing";
import Resources from "./pages/Resources";
import Terms from "./pages/Terms";
import Accessibility from "./pages/Accessibility";
import Privacy from "./pages/Privacy";
import Pricing from "./pages/Pricing";
import Payment from "./pages/Dashboard/Subscription/Payment";
import Notification from "./pages/Dashboard/Notification";
import Tickets from "./pages/Dashboard/Tickets";
import People from "./pages/Dashboard/People";

const router = createBrowserRouter(
  [
    {
      element: <ProtectedRoute />,
      path: "/Dashboard/*",
      children: [
        {
          path: "",
          element: <Dashboard />,
        },
        {
          path: "notifications",
          element: <Notification />,
        },
        {
          path: "support-tickets",
          element: <Tickets />,
        },
        // {
        //   path: "Mail",
        //   element: <MailPage />,
        // },
        {
          path: "Teams",
          element: <Teams />,
        },
        {
          path: "Teams/:teamId?",
          element: <View />,
        },
        {
          path: "Teams/Edit/:teamId?",
          element: <Edit />,
        },
        {
          path: "Teams/New",
          element: <New />,
        },
        {
          path: "People",
          element: <People />,
        },
        {
          path: "Settings",
          element: <Settings />,
        },
        {
          path: "Profile",
          element: <Profile />,
        },
        {
          path: "plans",
          element: <Subscription />,
        },
        {
          path: "Billing",
          element: <Billing />,
        },
      ],
    },
    {
      path: "checkout",
      element: <Payment />,
    },
    {
      path: "/get-started/*",
      Component: StarterLayout,
      children: [
        {
          path: "",
          element: <GetStarted />,
        },
        {
          path: "request-demo",
          element: <RequestDemo />,
        },
        {
          path: "Login/*",
          element: <Authentication />,
        },
        {
          path: "sign-up/*",
          element: <Signup />,
        },
      ],
    },
    {
      path: "/google-callback/*",
      element: <GoogleCallback />,
    },
    {
      path: "/ms-callback/*",
      element: <MSCallback />,
    },
    {
      path: "/",
      Component: HomeLayout,
      children: [
        {
          path: "/",
          index: true,
          element: <Home />,
        },
        {
          path: "/Pricing",
          element: <Pricing />,
        },
        {
          path: "/Resources",
          element: <Resources />,
        },
        {
          path: "/Security",
          element: <Security />,
        },
        {
          path: "/privacy-policy",
          element: <Privacy />,
        },
        {
          path: "/accessibility-statement",
          element: <Accessibility />,
        },
        {
          path: "/contact-us",
          element: <ContactUs />,
        },
        {
          path: "/terms-of-service",
          element: <Terms />,
          // element: <Terms />,
        },

        {
          path: "/Subscribe/:pricingId?",
          element: <Subscribe />,
        },
        {
          path: "Help",
          element: <Help />,
        },
      ],
    },
    {
      path: "/accept-invitation/:invitationId?",
      element: <AcceptInvitation />,
    },
    {
      path: "*",
      element: <NotFound />,
    },
  ],
  { basename: "/" }
);
function App() {
  return (
    <Provider store={store}>
      <AlertsContextProvider>
        <RouterProvider router={router} fallbackElement={<Loading />} />
      </AlertsContextProvider>
    </Provider>
  );
}

export default App;
