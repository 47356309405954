import { useAppSelector } from "../../hooks/useStore";

const CommonSelectors = () => {
  const pricingTiers = useAppSelector((state) => state.common.pricing);
  const state = useAppSelector((state) => state);
  return {
    state,
    pricingTiers,
  };
};

export default CommonSelectors;
