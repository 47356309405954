import React, { useEffect } from "react";
import Section from "../../layouts/Section";
import images from "../../data/images";
import { gsap } from "gsap";
import { useNavigateToTop } from "../../hooks/useNavigateToTop";
import MicrosoftIcon from "../../data/MicrosoftIcon";
import GoogleIcon from "../../data/GoogleIcon";
const GetStarted = () => {
  const navigateToTop = useNavigateToTop();
  useEffect(() => {
    gsap.fromTo(
      ".card1",
      { x: "-70%" },
      {
        x: 0,
        duration: 1.2,
        delay: 0.2,
      }
    );
    gsap.fromTo(
      ".card2",
      { x: "70%" },
      {
        x: 0,
        duration: 1.2,
        delay: 0,
      }
    );
  }, []);
  const cards = [
    {
      title: "Google/Microsoft Private Account",
      icons: [<GoogleIcon />, <MicrosoftIcon />],
      buttonText: "Available for Google and Microsoft",
      link: "Login",
      img: images.analyticsInterface,
    },
    {
      title: "Google/Microsoft Business Account",
      icons: [<GoogleIcon />, <MicrosoftIcon />],
      buttonText: "Available for Google and Microsoft",
      link: "request-demo",
      img: images.analyticsInterface2,
    },
  ];
  return (
    <Section
      isPage
      title="Choose the best solution for you"
      // placeholder="What’s your main goal with email analytics?"
      className="box overflow-hidden !pt-0"
    >
      <div className="px-20 max-md:px-8">
        <div className="mt-12 grid grid-cols-2 flex-wrap w-full max-w-7xl mx-auto items-center justify-center gap-4">
          {cards.map((card, index) => (
            <div
              onClick={() => navigateToTop(card.link)}
              // onClick={() => navigate('Login')}
              className={`card${
                index + 1
              } cursor-pointer flex flex-col gap-2 items-center justify-center col-span-1 max-md:col-span-2`}
            >
              <div className="flex flex-col gap-4 items-center justify-center border border-gray-150 p-6 w-full rounded-xl">
                <img
                  src={card.img}
                  alt="analytics Interface"
                  loading="lazy"
                  className="h-[10rem]"
                />
                <h2 className="text-center text-xl font-medium">
                  {card.title}
                </h2>
              </div>
              <div className="flex py-1 items-center gap-1 justify-center w-fit px-2 capitalize text-sm  max-md:text-xs font-regular text-center border border-gray-300 rounded-full">
                {card.icons.map((icon: any) => icon)}
                <span>{card.buttonText}</span>
              </div>
            </div>
          ))}
        </div>
      </div>
    </Section>
  );
};

export default GetStarted;
