import React, { useEffect } from "react";
import { TeammateDT } from "../../../../utils/types";
import { Pagination, PaginationProps, Table, TableProps } from "antd";
import useTeammates from "../../hooks/useTeammates";
import { FormatHelper } from "../../../../utils/timezone";
import Status from "./Status";
import Actions from "./Actions";
import InviteMember from "./InviteMember";
import TableHeader from "../../../../components/ui/TableHeader";

type ColumnsType<T> = TableProps<T>["columns"];

const TeammatesTable = () => {
  const {
    data,
    loading,
    tableParams,
    handleTableChange,
    handlePaginationChange,
    FetchTeammates,
    searchTerm,
    handleSearch,
  } = useTeammates();

  useEffect(() => {
    FetchTeammates();
  }, []);
  const { pagination } = tableParams;
  const columns: ColumnsType<TeammateDT> = [
    {
      title: "Index",
      dataIndex: "index",
      render: (_, record, index) =>
        ((pagination?.current || 1) - 1) * (pagination?.pageSize || 10) +
        index +
        1,
    },
    {
      title: "Name",
      dataIndex: "name",
      render: (_: any, record: TeammateDT) => (
        <span>{`${record.firstName} ${record.lastName}`}</span>
      ),
    },
    {
      title: "Email",
      dataIndex: "email",
      render: (_: any, record: TeammateDT) => <span>{record.email}</span>,
    },
    {
      title: "Type",
      dataIndex: "role",
      render: (_: any, record: TeammateDT) => <span>{record.role}</span>,
    },
    {
      title: "Joining Date",
      dataIndex: "createdAt",
      sorter: true,
      render: (_: any, record: TeammateDT) => (
        <span>{FormatHelper.DateFormat(record.createdAt)}</span>
      ),
    },
    {
      title: "Status",
      dataIndex: "status",
      render: (_: any, record: TeammateDT) => <Status status={record.status} />,
      sorter: true,
    },
    {
      title: "",
      dataIndex: "",
      key: "x",
      render: (_: any, record: TeammateDT) => (
        <Actions team={record} fetchTeams={FetchTeammates} />
      ),
    },
  ];
  const onChange: PaginationProps["onChange"] = (page, pageSize) => {
    handlePaginationChange(page, pageSize);
    // console.log({ ...pagination, current: page, pageSize });
  };
  return (
    <>
      <div className="gap-4 my-8">
        <TableHeader
          handler={(e: React.ChangeEvent<HTMLInputElement>) =>
            handleSearch(e.target.value)
          }
          placeholder={"Find by name or email"}
          searchTerm={searchTerm}
          leftNode={<InviteMember fetchTeams={FetchTeammates} />}
        />
        <Table
          scroll={{ x: true }}
          columns={columns}
          rowKey={(record) => record.id}
          dataSource={data}
          pagination={pagination}
          size="small"
          sortDirections={["ascend", "descend", "ascend"]}
          loading={loading}
          // rowSelection={rowSelection}
          rowClassName={"cursor-pointer editable-row"}
          onChange={handleTableChange}
          // onRow={(record) => {
          //   return {
          //     onClick: (e) => {
          //       e?.stopPropagation();
          //       navigateToTop(`${record.id}`);
          //     },
          //   };
          // }}
        />
      </div>
      <div className="mt-8">
        <Pagination
          total={pagination?.total}
          showTotal={(total, range) =>
            `${range[0]}-${range[1]} of ${total} items`
          }
          // responsive
          showSizeChanger
          onChange={onChange}
          pageSize={pagination?.pageSize}
          current={pagination?.current}
        />
      </div>
    </>
  );
};

export default TeammatesTable;
