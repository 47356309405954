import React from "react";
import Section from "../../layouts/Section";
import SEO from "../../layouts/SEO";
import { Link } from "react-router-dom";

const AccessibilityEN = () => {
  return (
    <Section
      id="AccessibilityStatement"
      badge={"Accessibility"}
      title={"Accessibility Statement"}
    >
      <SEO title={"Accessibility Statement | InterSight"} />
      <div className="items-center justify-center flex flex-col">
        <div className="my-16 w-[90%] lg:w-[50%]">
          <p className="font-bold text-primaryBlue text-center">
            Last Update on: 14-08-2024
          </p>
          <div className="items-center justify-center mt-8">
            <div className="mb-10">
              <ul className="start-custom space-y-4 list-inside list-decimal">
                <li>
                  <h2 className="inline-block text-lg md:text-xl lg:text-2xl text-heading font-bold mb-2">
                    {"Introduction"}
                  </h2>
                  <p className="italic pl-4 text-lg font-medium border-l-2 border-l-primaryBlue">
                    The owner of the "
                    <strong className="underline">InterSight Ltd</strong>" ID
                    517017349, website and platform (hereinafter "
                    <strong>the website owner</strong>") works to provide equal
                    opportunities for visitors and surfers with disabilities
                    through the accessibility of the site and necessary
                    adjustments, so that different audiences can read the
                    contents published on it, as well as receive additional
                    information.
                  </p>
                </li>
                <li>
                  Therefore, the website owner made an effort to make his
                  website accessible. The domain address is:
                  <Link
                    to="https://www.inter-sight.com"
                    target="_blank"
                    className="font-bold mx-2 text-primaryBlue"
                  >
                    www.inter-sight.com
                  </Link>
                </li>
                <li>
                  An accessibility plugin on the site allows convenient use and
                  browsing for those with disabilities. For details, click on
                  the accessibility button.
                </li>
                <li>
                  The website owner makes efforts to make the website accessible
                  and adapted according to Israeli standards and law
                  requirements. At the same time, you may find certain
                  components on the site that are not fully accessible. If you
                  encounter any problem in the field of accessibility, please
                  contact the website owner, and he will make an effort to fix
                  it within a reasonable time from the date of receipt of the
                  reference. You should attach to your application these
                  details:
                  <ul className="nested-list list-disc list-inside pl-4 mt-2">
                    <li>The action you tried to perform.</li>
                    <li>The relevant page on the website.</li>
                    <li>
                      The problem you encountered and technical details such as:
                      <ul className="nested-list list-disc list-inside pl-4 mt-2">
                        <li>
                          The operating system on the device you surfed from.
                        </li>
                        <li>Browser type and version.</li>
                      </ul>
                    </li>
                  </ul>
                </li>
                <li>
                  The site provides services and markets products digitally
                  only. There is no physical reception.
                </li>
                <li>
                  <h2 className="inline-block text-lg md:text-xl lg:text-2xl text-heading font-bold mb-2">
                    Contact Us
                  </h2>
                  <p>For inquiries about accessibility, contact us:</p>
                  <div className="italic [&>p]:!mb-1 mt-2 pl-4 text-lg font-medium border-l-2 border-l-primaryBlue">
                    <p>Contact: Yotam Zakai</p>
                    <p>
                      Email Address:
                      <Link
                        to="mailto:service@inter-sight.com"
                        className="font-bold mx-2 text-primaryBlue"
                      >
                        service@inter-sight.com.
                      </Link>
                    </p>
                    <p>
                      Phone:
                      <Link
                        dir="ltr"
                        to="tel:+972502000111"
                        className="mx-2 text-primaryBlue"
                      >
                        +972502000111
                      </Link>
                    </p>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </Section>
  );
};

export default AccessibilityEN;
