import React from "react";
import PageTitle from "../../../components/PageTitle";
import Button from "../../../components/Button";
import AuthSelectors from "../../../modules/auth/selectors";
import Section from "../../../layouts/Section";

const Profile = () => {
  const { user } = AuthSelectors();
  const onChange = (value: string) => {
    console.log(`selected ${value}`);
  };

  return (
    <Section className="max-w-7xl mx-auto !mt-4 !p-4">
      <div className="flex flex-wrap justify-between z-10 pb-4 mb-4 border-b mt-8">
        <PageTitle title={"Profile"} subTitle={"Manage your profile"} />
        <Button
          text={"Save changes"}
          type={"submit"}
          // loading={loading}
          className="!px-4 !max-md:px-5"
        />
      </div>
      <form>
        <div className="space-y-4">
          <div className="pb-4 mb-4 border-b">
            <h2 className="text-base font-semibold leading-7 text-gray-900">
              Personal Information
            </h2>
            <p className="text-sm leading-6 text-gray-600">
              Use a permanent address where you can receive mail.
            </p>
          </div>
          <div className="border-b border-gray-900/10 pb-6">
            <div className="grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
              <div className="sm:col-span-2">
                <label
                  htmlFor="first-name"
                  className="block text-sm font-medium leading-6 text-gray-900"
                >
                  Name
                </label>
                <div className="mt-2">
                  <input
                    type="text"
                    name="fullName"
                    id="fullName"
                    disabled
                    value={user?.fullName}
                    className="block w-full rounded-md cursor-not-allowed px-2 border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-primaryBlue sm:text-sm sm:leading-6"
                  />
                </div>
                <span className="block text-sm font-medium leading-6 text-placeholder">
                  Your name is automatically synced from your email account
                </span>
              </div>
              <div className="sm:col-span-4">
                <label
                  htmlFor="email"
                  className="block text-sm font-medium leading-6 text-gray-900"
                >
                  Email address
                </label>
                <div className="mt-2">
                  <input
                    id="email"
                    name="email"
                    type="email"
                    disabled
                    value={user?.email}
                    className="block w-full rounded-md cursor-not-allowed border-0 py-1.5 text-gray-900 shadow-sm ring-1 px-2 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-primaryBlue sm:text-sm sm:leading-6"
                  />
                </div>
                <span className="block text-sm font-medium leading-6 text-placeholder">
                  Your email address cannot be changed directly. If you need to
                  update it, please contact support for assistance.
                </span>
              </div>
              <div className="sm:col-span-full">
                <label
                  htmlFor="organization"
                  className="block text-sm font-medium leading-6 text-gray-900"
                >
                  Organization
                </label>
                <div className="mt-2">
                  <input
                    id="organization"
                    name="organization"
                    type="organization"
                    value={user?.company}
                    className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 px-2 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-0 focus:ring-inset focus:ring-primaryBlue sm:text-sm sm:leading-6"
                  />
                </div>
                <span className="block text-sm font-medium leading-6 text-placeholder">
                  Lorem ipsum dolor, sit amet consectetur adipisicing elit.
                  Molestias, quod commodi eveniet enim veniam distinctio
                  quisquam facilis corrupti voluptas nemo dolores doloribus
                  quibusdam ea impedit explicabo aut sapiente, beatae est?
                </span>
              </div>
            </div>
          </div>
        </div>
      </form>
    </Section>
  );
};

export default Profile;
