import React from "react";

const ListItem = ({
  title,
  info,
  loading,
}: {
  title: string;
  info: string | React.ReactNode;
  loading?: boolean;
}) => {
  return (
    <div className="px-4 py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
      <dt className="text-sm font-semibold leading-6 text-gray-900">{title}</dt>
      {loading ? (
        <div className="mt-1 animate-pulse h-3 bg-gray-200 rounded-full mb-2.5 sm:col-span-2 sm:mt-0" />
      ) : (
        <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
          {info}
        </dd>
      )}
    </div>
  );
};

export default ListItem;
