import axios, { AxiosResponse, ResponseType } from "axios";
import { getToken, setToken } from "./get-token";
import { getLanguage } from "./get-language";
import { API_ENDPOINTS } from "./api-endpoints";
import { RequestMethod } from "./types";
import Event from "./event";

interface RefreshSubscriber {
  resolve: (
    value?:
      | AxiosResponse<any, any>
      | PromiseLike<AxiosResponse<any, any>>
      | undefined
  ) => void;
  reject: (reason?: any) => void;
}

let isRefreshing = false;
let refreshSubscribers: RefreshSubscriber[] = [];

const onRefreshed = () => {
  refreshSubscribers.forEach(({ resolve }) => resolve());
  refreshSubscribers = [];
};

const addRefreshSubscriber = (
  resolve: (
    value?: AxiosResponse<any> | PromiseLike<AxiosResponse<any>> | undefined
  ) => void,
  reject: (reason?: any) => void
) => {
  refreshSubscribers.push({ resolve, reject });
};

const HttpRequestIntercept = (
  url: string,
  method: RequestMethod,
  data?: any,
  cancelToken?: any,
  isLocalHost?: boolean,
  responseType?: ResponseType
): Promise<AxiosResponse<any>> => {
  const lang = getLanguage() ?? "en";
  const token = getToken() ?? "";
  const headers = {
    ApiKey: process.env.REACT_APP_API_KEY,
    Language: lang,
    Authorization: token ? `Bearer ${token}` : undefined,
  };
  const baseURL = process.env.REACT_APP_API_ENDPOINT;
  const instance = axios.create({
    baseURL,
    timeout: 30000,
    headers,
    withCredentials: false,
    cancelToken: cancelToken ? cancelToken : undefined,
    responseType,
  });

  instance.interceptors.request.use(
    (config) => {
      const token = getToken();
      if (token) {
        config.headers["Authorization"] = `Bearer ${token}`;
      }
      return config;
    },
    (error) => Promise.reject(error)
  );

  instance.interceptors.response.use(
    (response) => response,
    async (error) => {
      const { config, response } = error;
      const originalRequest = config;
      if (response && response.status === 401) {
        if (!isRefreshing) {
          isRefreshing = true;
          try {
            const refreshToken = getToken();
            const { data: refreshedTokenResponse } = await axios.post(
              `${process.env.REACT_APP_API_ENDPOINT}${API_ENDPOINTS.REFRESH_TOKEN}`,
              { accessToken: refreshToken },
              { withCredentials: true }
            );
            const { refreshTokenExpiresAt, token: newToken } =
              refreshedTokenResponse;
            setToken(newToken, refreshTokenExpiresAt);
            isRefreshing = false;
            onRefreshed();
            const originalRequest = error.config;
            originalRequest.headers["Authorization"] = `Bearer ${newToken}`;
            return axios(originalRequest);
          } catch (err) {
            isRefreshing = false;
            refreshSubscribers.forEach(({ reject }) => reject(err));
            refreshSubscribers = [];
            Event.emit("unauthorizedError");
            return Promise.reject(err);
          }
        }

        // originalRequest._retry = true;
        const retryOriginalRequest = new Promise<AxiosResponse>(
          (resolve, reject) => {
            addRefreshSubscriber(async (newToken) => {
              try {
                originalRequest.headers["Authorization"] = `Bearer ${newToken}`;
                resolve(await instance(originalRequest));
              } catch (err) {
                reject(err);
              }
            }, reject);
          }
        );
        return retryOriginalRequest;
      }
      return Promise.reject(error);
    }
  );
  return instance.request({ url, method, data, cancelToken, responseType });
};

export default HttpRequestIntercept;
