import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import Button from "../../components/Button";
import { HiTranslate } from "react-icons/hi";

const Translate = () => {
  const { i18n } = useTranslation();
  const [currentLang, setCurrentLang] = useState("en");

  const handleSwitchLanguage = () => {
    if (currentLang === "en") {
      i18n.changeLanguage("he");
      setCurrentLang("he");
    } else if (currentLang === "he") {
      i18n.changeLanguage("en");
      setCurrentLang("en");
    }
  };
  const isEng = i18n.language === "en";
  return (
    <Button
      text={isEng ? "HE" : "EN"}
      variant={"secondary"}
      icon={<HiTranslate />}
      onClick={handleSwitchLanguage}
      className="!px-4 !py-2 !max-md:px-5 fixed bottom-5 right-5 bg-white z-10"
    />
  );
};

export default Translate;
