import Button from "../../../components/Button";
import React from "react";
import { FcUnlock } from "react-icons/fc";
import { useNavigate } from "react-router-dom";

const NeedSubscription = ({ title }: { title: string }) => {
  const navigate = useNavigate();
  return (
    <div className="absolute size-full z-10 top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 flex justify-center items-center bg-black/15 rounded-lg">
      <div className="bg-white gap-2 flex flex-col justify-center items-center max-w-[50%] max-md:size-[80%] rounded-lg p-6">
        <FcUnlock size={32} />
        <span className="text-placeholder text-center">
          Unlock <span className="font-bold">{title}</span>, by upgrading
          InterSight Plan
        </span>
        <Button
          text="Get Plus"
          className="min-w-[60%]"
          onClick={() => navigate("/Dashboard/plans")}
        />
      </div>
    </div>
  );
};

export default NeedSubscription;
