import React from "react";
import { MdOutlineLocationOn } from "react-icons/md";
import { FaLinkedinIn } from "react-icons/fa6";
import { FiPhone } from "react-icons/fi";
import { HiOutlineEnvelope } from "react-icons/hi2";
import Feature from "./Feature";

const features = [
  {
    title: "Phone",
    description: "+972502000111 ",
    icon: <FiPhone size={30} />,
  },
  {
    title: "Email",
    description: "Service@Inter-sight.com",
    icon: <HiOutlineEnvelope size={30} />,
  },
  {
    title: "Address",
    description: "Arnon 27, Ramat Gan",
    icon: <MdOutlineLocationOn size={30} />,
  },
  {
    title: "Follow Us",
    description:
      "Stay updated with our latest news and updates by following us on LinkedIn.",
    icon: <FaLinkedinIn size={30} />,
  },
];
const ContactGrid = () => {
  return (
    <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4  relative z-10 py-10 max-w-7xl mx-auto">
      <div className="col-span-4 mb-4">
        <h3 className="font-bold text-xl">Contact Us</h3>
        <h4>{"For more information or to get started, contact us today"}</h4>
      </div>
      {features.map((feature, index) => (
        <Feature key={feature.title} {...feature} index={index} />
      ))}
    </div>
  );
};

export default ContactGrid;
