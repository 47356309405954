import React, { useEffect } from "react";
import Icon from "../../components/ui/Buttons/Icon";
import { IoCloseOutline } from "react-icons/io5";
import PageTitle from "../../components/PageTitle";
import { useNavigate, useParams } from "react-router-dom";
import TeamStatus from "./components/TeamStatus";
import ListItem from "../../components/list/ListItem";
import TeammatesTable from "./Teammate/components/TeammatesTable";
import useActions from "./hooks/useActions";
import { FormatHelper } from "../../utils/timezone";
import Section from "../../layouts/Section";

const View = () => {
  const navigate = useNavigate();
  // const { state } = useLocation();
  // const data: TeamDT = state?.team;
  const { teamId } = useParams();

  const { loading, data, GetById } = useActions();
  useEffect(() => {
    let mounted = false;
    if (teamId && !mounted) {
      console.log({ teamId, data });
      GetById(teamId);
    }
    return () => {
      mounted = true;
    };
  }, [teamId]);

  return (
    <Section className="max-w-7xl mx-auto !mt-4 !p-4">
      <div className="flex justify-between sticky top-0 bg-white z-10">
        <PageTitle title={`${data?.name || ""} team`} />
        {/* <PageTitle title={`Edit Order #${fetchedOrder?.number || orderId}`} /> */}
        <Icon className="my-4" onClick={() => navigate(-1)}>
          <IoCloseOutline color={"#000"} />
        </Icon>
      </div>
      <div className="px-4 sm:px-0 mt-4">
        <h3 className="text-lg font-semibold leading-7 text-gray-900">
          Team Information
        </h3>
      </div>
      <div className="mt-4 px-4">
        <dl className="divide-y divide-gray-100">
          <ListItem title={"Team Name"} info={data?.name} loading={loading} />
          <ListItem
            title={"Department"}
            info={data?.department}
            loading={loading}
          />
          <ListItem
            title={"Created By"}
            info={data?.createdBy}
            loading={loading}
          />
          <ListItem
            title={"Created at"}
            info={FormatHelper.DateFormat(data?.createdAt)}
            loading={loading}
          />
          <ListItem
            title={"Updated at"}
            info={FormatHelper.DateFormat(data?.updatedAt)}
            loading={loading}
          />
          <ListItem
            title={"Status"}
            info={<TeamStatus status={!!data?.active} />}
            loading={loading}
          />
          {/* <div className="pt-6 flex flex-col gap4">
            <span className="text-sm font-bold leading-6 text-gray-900">
              {"Teammates"}
            </span>
            <span className="text-sm leading-6 text-placeholder">
              {"Track email statistics for multiple mailboxes"}
            </span>
          </div> */}
        </dl>
        <div className="mt-2 py-4 border-t border-t-gray-100">
          <div className="flex flex-col gap4">
            <span className="text-sm font-bold leading-6 text-gray-900">
              {"Members"}
            </span>
            <span className="text-sm leading-6 text-placeholder">
              {"Manage your team members and their mailboxes"}
            </span>
          </div>
          <TeammatesTable />
        </div>
      </div>
    </Section>
  );
};

export default View;
