import React from "react";
import {
  CardContentFilter,
  FlowEnum,
  IChartDetails,
  ICommunicationMetric,
} from "../../../../utils/types";
import {
  ChartConfig,
  ChartContainer,
  ChartLegend,
  ChartLegendContent,
  ChartTooltip,
  ChartTooltipContent,
} from "../../../../components/ui/chart";
import {
  PolarAngleAxis,
  PolarGrid,
  Radar as RechartRadar,
  RadarChart,
  PolarRadiusAxis,
} from "recharts";
import CardWrapper from "../../../../components/CardWrapper";
import { MdErrorOutline } from "react-icons/md";
import LoadingChart from "../LoadingChart";

const chartConfig = {
  currentPeriod: {
    label: "Current Period",
  },
  lastPeriod: {
    label: "Last Period",
  },
  yearlyAvg: {
    label: "Yearly Avg",
  },
} satisfies ChartConfig;

const Radar = ({
  className,
  filters,
  details,
}: {
  className?: string;
  filters: CardContentFilter;
  details: IChartDetails<ICommunicationMetric>;
}) => {
  const isOutFlow = filters.flow === FlowEnum.OUTFLOW ? "out" : "in";
  const { data, error, loading } = details;
  return (
    <CardWrapper
      className={className}
      title={"Communication Metrics"}
      description={"Understand users patterns of Interactions"}
      tooltip={"Calculated parameters by period selected"}
      innerClassName={"flex items-center justify-center p-0 mb-5"}
      content={
        <>
          {loading ? (
            <div className="w-full p-6">
              <LoadingChart />
            </div>
          ) : !(error && error.isOccurred) ? (
            <ChartContainer
              config={chartConfig}
              className="h-[400px] aspect-square max-md:size-[250px] max-md:w-[100%]"
            >
              <RadarChart
                data={data}
                // margin={{
                //   top: 20,
                // }}
              >
                <ChartTooltip
                  cursor={false}
                  content={<ChartTooltipContent indicator="line" />}
                />
                <PolarRadiusAxis
                  angle={60}
                  orientation="middle"
                  axisLine={false}
                />
                <PolarAngleAxis
                  dataKey="parameter"
                  // tick={{ fontSize: 12 }}
                />
                <PolarGrid gridType="circle" />
                <RechartRadar
                  dataKey="currentPeriod"
                  fill={`var(--chart-current-period-${isOutFlow})`}
                  fillOpacity={0.5}
                  dot={{
                    r: 2,
                    fillOpacity: 1,
                  }}
                />
                <RechartRadar
                  dataKey="lastPeriod"
                  fill={`var(--chart-last-period-${isOutFlow})`}
                  fillOpacity={0.5}
                  dot={{
                    r: 2,
                    fillOpacity: 1,
                  }}
                />
                <RechartRadar
                  dataKey="yearlyAvg"
                  fill={`var(--chart-yearly-avg-${isOutFlow})`}
                  fillOpacity={0.5}
                  dot={{
                    r: 2,
                    fillOpacity: 1,
                  }}
                />
                <ChartLegend content={<ChartLegendContent />} />
              </RadarChart>
            </ChartContainer>
          ) : null}
          {!loading && error && error.isOccurred ? (
            <div className="flex gap-2 items-center p-2 bg-gray-50 rounded-lg">
              <MdErrorOutline className="text-red-700" />
              <p className="text-red-700">{error?.message}</p>
            </div>
          ) : null}
        </>
      }
    />
  );
};

export default Radar;
