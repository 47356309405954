import React from "react";
import { cn } from "../../../../utils/utils";
import StatCard from "./Card";
import { FlowEnum, StatProp, ViewEnum } from "../../../../utils/types";

const Parameters = ({
  flow,
  view,
  stats,
}: {
  flow: FlowEnum;
  view: ViewEnum;
  stats: StatProp[];
}) => {
  return (
    <div
      className={cn(
        "grid gap-4 md:grid-cols-2 md:gap-8",
        ` lg:grid-cols-${stats?.length || 5}`
      )}
    >
      {stats?.map((stat, index) => (
        <StatCard
          key={index}
          stat={stat}
          flow={flow}
          view={view}
          index={index}
        />
      ))}
    </div>
  );
};

export default Parameters;
