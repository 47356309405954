import React from "react";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuGroup,
  DropdownMenuItem,
  DropdownMenuLabel,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from "../../components/ui/dropdown-menu";
import AuthActions from "../../modules/auth/actions";
import AuthSelectors from "../../modules/auth/selectors";
import { ChevronDown, LogOut, Settings, User } from "lucide-react";
import { useNavigate } from "react-router-dom";
import { useAppDispatch } from "../../hooks/useStore";
import {
  Avatar,
  AvatarFallback,
  AvatarImage,
} from "../../components/ui/avatar";

const AvatarDropdown = () => {
  const { logout } = AuthActions();
  const { user } = AuthSelectors();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  return (
    <DropdownMenu>
      <DropdownMenuTrigger asChild>
        <div className="flex gap-2 ml-2 items-center cursor-pointer">
          <Avatar>
            <AvatarImage src={user?.picture} alt={user?.fullName} />
            <AvatarFallback>
              {user?.fullName
                .split(" ")
                .map((chunk) => chunk[0])
                .join("")}
            </AvatarFallback>
          </Avatar>

          {/* <div className="flex flex-col items-start max-md:hidden">
            <span className="font-medium text-sm">{user?.fullName}</span>
            <span className="text-xs text-gray-800">{user.email}</span>
          </div> */}
          <ChevronDown className="size-4 text-placeholder" />
        </div>
      </DropdownMenuTrigger>
      <DropdownMenuContent className="w-56">
        <DropdownMenuLabel>My Account</DropdownMenuLabel>
        <div className="flex flex-col items-start p-2">
          <span className="font-medium text-sm">{user?.fullName}</span>
          <span className="text-xs text-gray-800">{user.email}</span>
        </div>
        <DropdownMenuSeparator />
        <DropdownMenuGroup>
          <DropdownMenuItem onSelect={() => navigate("Profile")}>
            <User className="mr-2 h-4 w-4" />
            <span>Profile</span>
          </DropdownMenuItem>
          <DropdownMenuItem onSelect={() => navigate("Settings")}>
            <Settings className="mr-2 h-4 w-4" />
            <span>Settings</span>
          </DropdownMenuItem>
        </DropdownMenuGroup>
        <DropdownMenuSeparator />
        <DropdownMenuItem onSelect={() => dispatch(logout())}>
          <LogOut className="mr-2 h-4 w-4" />
          <span>Log out</span>
        </DropdownMenuItem>
      </DropdownMenuContent>
    </DropdownMenu>
  );
};

export default AvatarDropdown;
