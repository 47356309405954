import React, { useState } from "react";
import Modal from "../../../components/Modal";
import Icon from "../../../components/ui/Buttons/Icon";
import { IoCloseOutline } from "react-icons/io5";
import { Form, Input } from "antd";
import Button from "../../../components/Button";
import { MdOutlineAdd } from "react-icons/md";
import { CreateTeamForm } from "../../../utils/types";
import useActions from "../hooks/useActions";

const NewTeamModal = ({
  showModal,
  dismiss,
  submit,
  loading,
}: {
  showModal: boolean;
  dismiss: Function;
  submit: Function;
  loading: boolean;
}) => {
  const [form] = Form.useForm();
  const onFinish = (values: any) => {
    submit(values);
    form.resetFields();
  };
  return (
    <Modal
      showModal={showModal}
      dismiss={dismiss}
      className="w-[30dvw] max-md:min-w-full"
    >
      {/*header*/}
      <div className="flex justify-between items-center">
        <h1 className="font-bold text-black">{"Add New Team"}</h1>
        <Icon className="!bg-transparent" onClick={() => dismiss()}>
          <IoCloseOutline color={"#000"} size={20} />
        </Icon>
      </div>
      <p className="text-placeholder leading-4 text-sm">
        Adding a new team is a simple process. Please follow the instructions
        below to ensure all necessary information is provided accurately:
      </p>
      <div className="mt-8">
        <Form
          form={form}
          layout="vertical"
          autoComplete="off"
          onFinish={onFinish}
        >
          <Form.Item
            required
            name="name"
            initialValue={""}
            rules={[
              {
                required: true,
                message: `name is required!`,
              },
            ]}
          >
            <Input placeholder="team name..." className="py-2" />
          </Form.Item>
          <Form.Item
            className="mt-2"
            required
            name="department"
            initialValue={""}
            rules={[
              {
                required: true,
                message: `department is required!`,
              },
            ]}
          >
            <Input placeholder="department..." className="py-2" />
          </Form.Item>
          <div className="flex gap-2 items-center justify-end mt-4 text-xl text-center whitespace-nowrap">
            <Button
              text={"Cancel"}
              type="button"
              disabled={loading}
              variant="secondary"
              onClick={() => dismiss()}
              className="justify-center items-center !py-2"
            />
            <Button
              text={"Add"}
              type={"submit"}
              loading={loading}
              className="justify-center items-center !px-12 !py-2  text-white bg-primaryBlue !max-md:px-5"
            />
          </div>
        </Form>
      </div>
    </Modal>
  );
};

const NewTeam = ({ fetchTeams }: { fetchTeams: Function }) => {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [modalVisible, setModalVisible] = useState(false);
  const { Create } = useActions();
  // const { FetchTeams } = useTeams();
  const AddTeam = (form: CreateTeamForm) => {
    setIsSubmitting(true);
    Create(form, () => {
      fetchTeams();
      setIsSubmitting(false);
      setModalVisible(false);
    });
  };
  return (
    <>
      <Button
        text="New Team"
        onClick={() => setModalVisible(true)}
        variant="primary"
        className={`flex items-center gap-2 p-4 rounded-xl cursor-pointer shadow-sm h-full max-md:h-auto  !my-0`}
        icon={<MdOutlineAdd />}
      />
      <NewTeamModal
        showModal={modalVisible}
        loading={isSubmitting}
        dismiss={() => {
          setModalVisible(false);
        }}
        submit={AddTeam}
      />
    </>
  );
};
export default NewTeam;
