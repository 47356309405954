export default function GoogleIcon() {
  return (
    <svg
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
    >
      <g clipPath="url(#google__clip0_1983_3985)">
        <path
          d="M15.626 8.1497C15.626 7.49419 15.5728 7.01584 15.4577 6.51978H7.97241V9.47843H12.3661C12.2776 10.2137 11.7992 11.321 10.7362 12.0651L10.7213 12.1641L13.088 13.9976L13.252 14.0139C14.7579 12.6232 15.626 10.5769 15.626 8.1497Z"
          fill="#4285F4"
        ></path>
        <path
          d="M7.97248 15.945C10.125 15.945 11.9321 15.2363 13.2521 14.0139L10.7363 12.065C10.063 12.5345 9.15947 12.8623 7.97248 12.8623C5.8642 12.8623 4.07483 11.4716 3.43697 9.54932L3.34347 9.55726L0.882525 11.4618L0.850342 11.5513C2.16136 14.1556 4.85431 15.945 7.97248 15.945Z"
          fill="#34A853"
        ></path>
        <path
          d="M3.43701 9.54936C3.2687 9.0533 3.1713 8.52176 3.1713 7.97257C3.1713 7.42332 3.2687 6.89184 3.42815 6.39577L3.42369 6.29013L0.931908 4.35498L0.850382 4.39376C0.310046 5.47449 0 6.68811 0 7.97257C0 9.25703 0.310046 10.4706 0.850382 11.5513L3.43701 9.54936Z"
          fill="#FBBC05"
        ></path>
        <path
          d="M7.97248 3.08269C9.46952 3.08269 10.4793 3.72934 11.0552 4.26974L13.3052 2.07285C11.9233 0.788397 10.125 0 7.97248 0C4.85431 0 2.16136 1.78937 0.850342 4.39371L3.42811 6.39573C4.07483 4.47347 5.8642 3.08269 7.97248 3.08269Z"
          fill="#EB4335"
        ></path>
      </g>
      <defs>
        <clipPath id="google__clip0_1983_3985">
          <rect width="16" height="16" fill="white"></rect>
        </clipPath>
      </defs>
    </svg>
  );
}
