import React, { ErrorInfo } from "react";

// const Fallback = () => {
//   return (
//     <p className="w-full h-full flex items-center justify-center text-center font-semibold">
//       {"Something went wrong"}
//     </p>
//   );
// };
const Fallback = (error: Error, errorInfo: ErrorInfo) => {
  return (
    <div>
      <h2>Something went wrong.</h2>
      <details style={{ whiteSpace: "pre-wrap" }}>
        {error && error.toString()}
        <br />
        {errorInfo.componentStack}
      </details>
    </div>
  );
};
export default Fallback;
