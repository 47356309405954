import React from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import PageTitle from "../../components/PageTitle";
import Icon from "../../components/ui/Buttons/Icon";
import { IoCloseOutline } from "react-icons/io5";
import { Form, Input, Switch } from "antd";
import Button from "../../components/Button";
import { TeamDT } from "../../utils/types";
import useActions from "./hooks/useActions";
import Section from "../../layouts/Section";

const Edit = () => {
  const navigate = useNavigate();
  const { teamId } = useParams();
  const { state } = useLocation();
  const { Edit } = useActions();

  const data: TeamDT = state?.team;
  const [form] = Form.useForm();
  const onFinish = (values: any) => {
    console.log("onFinish");
    Edit({ ...values, id: teamId }, () => navigate(-1));
  };
  return (
    <Section className="max-w-7xl mx-auto !mt-4 !pt-4">
      <div className="flex justify-between bg-white">
        <PageTitle title={`Edit Team`} />
        <Icon className="my-4" onClick={() => navigate(-1)}>
          <IoCloseOutline color={"#000"} />
        </Icon>
      </div>
      <p className="text-placeholder mb-4 leading-4 text-sm">
        Customize and change the team's information.
      </p>
      <div className="mt-8">
        <Form
          form={form}
          labelCol={{ span: 2 }}
          wrapperCol={{ span: 10 }}
          layout={"horizontal"}
          autoComplete={"off"}
          initialValues={data}
          onFinish={onFinish}
          className="flex flex-col"
        >
          <Form.Item
            required
            name="name"
            label="Name"
            labelAlign="left"
            rules={[
              {
                required: true,
                message: `name is required!`,
              },
            ]}
          >
            <Input placeholder="team name..." className="py-2" />
          </Form.Item>
          <Form.Item
            className="mt-2"
            required
            labelAlign="left"
            label="Department"
            name="department"
            rules={[
              {
                required: true,
                message: `department is required!`,
              },
            ]}
          >
            <Input placeholder="department..." className="py-2" />
          </Form.Item>
          <Form.Item
            label="Active?"
            labelAlign="left"
            valuePropName="checked"
            name="active"
          >
            <Switch />
          </Form.Item>
          <div className="flex gap-2 items-center  mt-4 text-xl text-center whitespace-nowrap">
            <Button
              text={"Cancel"}
              // disabled={loading}
              type="button"
              variant="secondary"
              onClick={() => navigate(-1)}
              className="justify-center items-center !py-2"
            />
            <Button
              text={"Update"}
              type={"submit"}
              // loading={loading}
              className="justify-center items-center !px-12 !py-2 text-white bg-primaryBlue !max-md:px-5"
            />
          </div>
        </Form>
      </div>
    </Section>
  );
};
export default Edit;
