import React from "react";
import {
  Card,
  CardContent,
  CardDescription,
  CardHeader,
  CardTitle,
} from "../../../../components/ui/card";
import { cn } from "../../../../utils/utils";
import { Tooltip } from "antd";
import { IoMdInformationCircleOutline } from "react-icons/io";
import { CardContentFilter, IError } from "../../../../utils/types";
import { getCardContent } from "./WrapperInfoHandler";
import LoadingChart from "../LoadingChart";
import { MdErrorOutline } from "react-icons/md";
import NeedSubscription from "../NeedSubscription";

const Wrapper = ({
  className,
  innerClassName,
  content,
  cardFilters,
  error,
  loading,
  needSubscription,
}: {
  className?: string;
  innerClassName?: string;
  content: React.ReactNode;
  cardFilters: CardContentFilter;
  error?: IError;
  loading?: boolean;
  needSubscription?: boolean;
}) => {
  const { report, graphType, view, flow } = cardFilters;
  const cardInfo = getCardContent(report, graphType, view, flow);
  const { title, description, tooltip } = cardInfo;
  return (
    <Card className={cn(className, "flex flex-col justify-between")}>
      <CardHeader>
        <Tooltip
          color={"#fff"}
          overlayInnerStyle={{ color: "#000" }}
          placement={"topLeft"}
          arrow={false}
          title={tooltip}
          className="cursor-pointer flex gap-1 items-center"
        >
          <CardTitle>{title}</CardTitle>
          <IoMdInformationCircleOutline color={"#707070 "} />
        </Tooltip>
        <CardDescription>{description}</CardDescription>
      </CardHeader>
      <CardContent className={cn(innerClassName, "max-md:px-2 relative")}>
        {!loading && !error?.isOccurred && needSubscription ? (
          <NeedSubscription title={title} />
        ) : null}
        {loading ? (
          <LoadingChart />
        ) : !(error && error.isOccurred) ? (
          content
        ) : null}
        {!loading && error && error.isOccurred ? (
          <div className="flex gap-2 items-center p-2 bg-gray-50 rounded-lg">
            <MdErrorOutline className="text-red-700" />
            <p className="text-red-700">{error?.message}</p>
          </div>
        ) : null}
      </CardContent>
    </Card>
  );
};

export default Wrapper;
