import React, { useState } from "react";
import PageTitle from "../../../components/PageTitle";
import Section from "../../../layouts/Section";
import { v4 as uuidv4 } from "uuid";
import { Button } from "../../../components/ui/button";
import { Badge } from "../../../components/ui/badge";
import { Input, Select } from "antd";
import TextArea from "antd/es/input/TextArea";
import CardWrapper from "../../../components/CardWrapper";
import Faqs from "./components/Faqs";

type Ticket = {
  id: any;
  subject: string;
  description: string;
  status: string;
};
const Tickets = () => {
  const [tickets, setTickets] = useState<Ticket[]>([]);
  const [newTicket, setNewTicket] = useState({ subject: "", description: "" });
  // Function to handle creating a new ticket
  const handleCreateTicket = () => {
    if (newTicket.subject && newTicket.description) {
      setTickets([
        ...tickets,
        {
          id: uuidv4(),
          subject: newTicket.subject,
          description: newTicket.description,
          status: "open",
        },
      ]);
      setNewTicket({ subject: "", description: "" });
    }
  };
  // Function to handle closing a ticket
  const handleCloseTicket = (id: any) => {
    setTickets(
      tickets.map((ticket) =>
        ticket.id === id ? { ...ticket, status: "closed" } : ticket
      )
    );
  };
  return (
    <Section className="max-w-7xl mx-auto !mt-4 !p-4">
      <div className="flex flex-wrap justify-between bg-white z-10 pb-4 mb-4 border-b mt-8">
        <PageTitle
          title={"Support Tickets"}
          subTitle={"When customers have problems, they open support tickets."}
        />
      </div>
      <div className="grid grid-cols-3 gap-8">
        <CardWrapper
          className="col-span-3"
          title={"Create New Ticket"}
          description={
            "Fill up all the information here, then click submit button"
          }
          content={
            <div className="flex flex-col gap-4">
              <div className="flex gap-4 flex-wrap">
                <Select
                  showSearch
                  placeholder={"Select request type"}
                  onChange={console.log}
                  options={[]}
                  size={"large"}
                  className={"w-1/2 !h-[2.5rem]"}
                />
                <Select
                  showSearch
                  placeholder={"Choose reissue reason"}
                  onChange={console.log}
                  options={[]}
                  size={"large"}
                  className={"w-1/2 !h-[2.5rem]"}
                />
              </div>
              <Input
                placeholder="Subject"
                value={newTicket?.subject}
                onChange={(e: any) =>
                  setNewTicket({ ...newTicket, subject: e.target.value })
                }
              />
              <TextArea
                placeholder="Description"
                value={newTicket?.description}
                onChange={(e: any) =>
                  setNewTicket({ ...newTicket, description: e.target.value })
                }
              />
              <Button
                onClick={handleCreateTicket}
                //   colorScheme="blue"
                className="w-fit"
              >
                Submit Ticket
              </Button>
            </div>
          }
        />

        {/* Tickets List */}
        <CardWrapper
          className="col-span-2  max-md:col-span-3"
          title={"Latest Support History"}
          description="Here is your most recent history"
          innerClassName="flex flex-1"
          content={
            tickets.length === 0 ? (
              <div className="flex flex-1 py-4 items-center justify-center rounded-lg border border-dashed border-gray-300 shadow-sm">
                <div className="flex flex-col items-center gap-1 text-center">
                  <h3 className="text-xl font-bold tracking-tight">
                    No tickets available
                  </h3>
                  <p className="text-sm text-muted-foreground">
                    You can start see as soon as you add a new ticket.
                  </p>
                </div>
              </div>
            ) : (
              tickets.map((ticket) => (
                <div
                  key={ticket.id}
                  className="border p-4 rounded-md flex justify-between"
                >
                  <div>
                    <h3 className="text-lg font-bold">{ticket.subject}</h3>
                    <p>{ticket.description}</p>
                    <Badge
                      // colorScheme={ticket.status === "open" ? "green" : "red"}
                      className="mt-2"
                    >
                      {ticket.status}
                    </Badge>
                  </div>
                  {ticket.status === "open" && (
                    <Button
                      onClick={() => handleCloseTicket(ticket.id)}
                      // colorScheme="red"
                    >
                      Close Ticket
                    </Button>
                  )}
                </div>
              ))
            )
          }
        />
        <Faqs />
      </div>
    </Section>
  );
};

export default Tickets;
