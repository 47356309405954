import React from "react";
import { CiSearch } from "react-icons/ci";

const SearchBar = ({
  searchTerm,
  handler,
  placeholder,
}: {
  searchTerm: string;
  placeholder: string;
  handler: (e: React.ChangeEvent<HTMLInputElement>) => void;
}) => {
  return (
    <div className="flex w-1/3 max-md:w-full items-center gap-3 p-4 h-fit rounded-xl bg-neutral-50 text-slate-400 max-md:flex-wrap">
      <CiSearch />
      <input
        type="text"
        placeholder={placeholder}
        className={`flex-1 max-md:w-full bg-transparent outline-none ${
          searchTerm ? "!text-black" : "text-slate-400"
        }`}
        name={"searchTerm"}
        value={searchTerm}
        onChange={handler}
      />
    </div>
  );
};

export default SearchBar;
