import React from "react";
import Section from "../../layouts/Section";
import images from "../../data/images";
import classNames from "classnames";
import { motion } from "framer-motion";

const cards = [
  {
    title: "",
    description:
      "We are inspired to develop innovative tools for Individuals and teams to improve management standards.",
    image: images.card2,
    hoveredImage: images.cardHover2,
  },
  {
    title: "",
    description:
      "We are enthusiastic builders of data flows that allow you to produce instant management reports.",
    image: images.card1,
    hoveredImage: images.cardHover1,
  },
  {
    title: "",
    description:
      "Our email-based reports are designed to deliver real-time data insights. With user-friendly interactive visualizations, you can monitor any type of performance.",
    image: images.card3,
    hoveredImage: images.cardHover3,
  },
];
const About = () => {
  return (
    <Section
      className="box"
      badge="Email Analytics"
      title="Unlock the Power of Your Communication"
      placeholder="InterSight specializes in top-tier Data Analytics services, providing deep insights into how communication shapes business processes."
    >
      <section className="flex-wrap justify-center px-5 lg:px-20 mt-10 lg:mt-16 lg:w-full max-w-full">
        <div className="grid md:grid-cols-3 gap-6 max-md:max-w-xs mx-auto">
          {cards.map((card, index) => (
            <motion.div
              initial={{ scale: 0.8, opacity: 0.7 }}
              whileInView={{ scale: 1, opacity: 1 }}
              transition={{
                duration: index * 0.35,
                ease: "easeInOut",
              }}
              key={index}
              className={classNames(
                "group overflow-hidden [background:linear-gradient(theme(colors.white),theme(colors.gray.100))_padding-box,linear-gradient(60deg,theme(colors.slate.100),theme(colors.slate.200/.8),theme(colors.slate.200))_border-box]",
                "relative rounded-xl border border-transparent"
                // "relative before:absolute before:inset-0 before:bg-noise before:bg-[length:352px_382px] rounded-xl border border-transparent"
              )}
            >
              <div className="relative flex flex-col items-center justify-center text-center">
                <div className="px-6 py-5">
                  {/* <div className="font-nycd text-lg text-indigo-500 mb-1">
                Label
              </div> */}
                  {/* <div className="text-lg font-bold mb-1">Daily Reports</div> */}
                  <p className="text-lg max-md:text-sm">
                    {/* <p className="text-sm"> */}
                    {card.description}
                  </p>
                </div>
                <div className="relative group-hover:-translate-y-1 transition-transform duration-500 ease-in-out">
                  <img
                    className="group-hover:opacity-0 max-md:opacity-0 transition-opacity duration-500"
                    src={card.image}
                    width="350"
                    height="240"
                    alt="Card  01"
                  />
                  <img
                    className="absolute top-0 left-0 opacity-0 group-hover:opacity-100 max-md:opacity-100 transition-opacity duration-500"
                    src={card.hoveredImage}
                    width="350"
                    height="240"
                    alt="Card image 01 displaying on hover"
                    aria-hidden="true"
                  />
                </div>
              </div>
            </motion.div>
          ))}
        </div>
      </section>
    </Section>
  );
};

export default About;
