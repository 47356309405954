import { useEffect, useState } from "react";
import { useAlertsContext } from "../../../hooks/useAlertsContext";
import {
  ChartsTypeEnum,
  FlowEnum,
  OptionsFilterType,
  ReportTypeEnum,
  StatProp,
  ViewEnum,
} from "../../../utils/types";
import type { SelectProps } from "antd";
import {
  GetConnectivityRanks,
  GetConnectivityScope,
  GetMessagesVolume,
  GetRespondTimeDistribution,
  GetStatistics,
} from "../api/service";
import { IChartData, initData } from "./data";

const initialFilterOption = {
  flow: FlowEnum.ALL,
  view: ViewEnum.ALL,
  report: ReportTypeEnum.STATISTICS,
  user2: undefined,
  user1: undefined,
  dateRange: {
    from: null,
    to: null,
  },
};
const useActions = () => {
  const usersOptions: SelectProps["options"] = [
    {
      label: "text@example.com",
      value: "text@example.com",
    },
    {
      label: "text1@example.com",
      value: "text1@example.com",
    },
    {
      label: "text3@example.com",
      value: "text3@example.com",
    },
  ];
  const { toastError } = useAlertsContext();
  const [loading, setLoading] = useState(false);
  const [chartStates, setChartStates] =
    useState<Record<ChartsTypeEnum, IChartData>>(initData);
  const [statisticsParameters, setStatisticsParameters] = useState<StatProp[]>(
    []
  );
  const [filterOption, setFilterOption] =
    useState<OptionsFilterType>(initialFilterOption);
  const [isCollapsed, setIsCollapsed] = useState(true);
  const collapseFilters = () => {
    setIsCollapsed(!isCollapsed);
  };
  const fetchReportData = async (filters = filterOption) => {
    const chartEndpoints: Record<
      ReportTypeEnum,
      { type: ChartsTypeEnum; call: Function }[]
    > = {
      [ReportTypeEnum.STATISTICS]: [
        { type: ChartsTypeEnum.VOLUME, call: GetMessagesVolume },
        {
          type: ChartsTypeEnum.RESPOND_TIME_DISTRIBUTION,
          call: GetRespondTimeDistribution,
        },
        {
          type: ChartsTypeEnum.CONNECTIVITY_SCOPE,
          call: GetConnectivityScope,
        },
      ],
      [ReportTypeEnum.CONNECTIONS]: [
        {
          type: ChartsTypeEnum.CONNECTIVITY_RANKS,
          call: GetConnectivityRanks,
        },
      ],
      [ReportTypeEnum.ANALYTICS]: [
        { type: ChartsTypeEnum.VOLUME, call: GetMessagesVolume },
        {
          type: ChartsTypeEnum.RESPOND_TIME_DISTRIBUTION,
          call: GetRespondTimeDistribution,
        },
      ],
    };
    const reportTypeEndpoints = chartEndpoints[filters.report];
    reportTypeEndpoints.forEach(({ type }) => {
      setChartStates((prevState) => ({
        ...prevState,
        [type]: {
          ...prevState[type],
          loading: true,
          error: { isOccurred: false, message: "" },
        },
      }));
    });
    Promise.allSettled(reportTypeEndpoints.map(({ call }) => call(filters)))
      .then((results) => {
        results.forEach((result, index) => {
          const { type } = reportTypeEndpoints[index];
          if (result.status === "fulfilled") {
            setChartStates((prevState) => ({
              ...prevState,
              [type]: {
                ...prevState[type],
                loading: false,
                data: result.value.data,
              },
            }));
          } else {
            setChartStates((prevState) => ({
              ...prevState,
              [type]: {
                ...prevState[type],
                loading: false,
                error: {
                  isOccurred: true,
                  message: result.reason?.message || "Error occurred",
                },
              },
            }));
          }
        });
      })
      .catch((err) => toastError(err?.data?.message || "Error Occurred"));
  };
  const fetchStatistics = async (filters = filterOption) => {
    setLoading(true);
    await GetStatistics(filters)
      .then((stats) => {
        setStatisticsParameters(stats.data);
      })
      .catch((err) => toastError(err?.data?.message || "Error Occurred"))
      .finally(() => setLoading(false));
  };
  const handleFilterChange = (filters: OptionsFilterType) => {
    //  TODO handle users on report change
    setFilterOption(filters);
    fetchStatistics(filters);
    fetchReportData(filters);
    setIsCollapsed(!isCollapsed);
  };
  const changeReportType = (report: ReportTypeEnum) => {
    setFilterOption({ ...filterOption, report });
  };
  const ExportReport = () => {
    console.log("exportReport");
  };

  useEffect(() => {
    fetchReportData();
    fetchStatistics();
  }, []);

  return {
    statisticsParameters,
    loading,
    changeReportType,
    usersOptions,
    ExportReport,
    filterOption,
    setFilterOption,
    handleFilterChange,
    isCollapsed,
    collapseFilters,
    chartStates,
  };
};
export default useActions;
