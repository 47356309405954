import AuthSelectors from "../../../modules/auth/selectors";
import React from "react";
import { Sparkles } from "lucide-react";

const WelcomeBar = () => {
  const { user } = AuthSelectors();
  return (
    <div className="flex flex-col w-full">
      <div className="flex gap-2 w-full">
        <div className="animate-wiggle">
          <Sparkles color={"#ffbf00"} className="animate-pulse" />
        </div>
        <h3 className="font-bold">Welcome, {user?.fullName}</h3>
      </div>
      {/* <div className="flex text-placeholder text-xs space-x-2 ">
        <span>Observe,</span>
        <span>Discover,</span>
        <span>Understand,</span>
        <span>Drive Results,</span>
        <span className="font-bold">we’re here to help!</span>
      </div> */}
    </div>
  );
};

export default WelcomeBar;
