import { ReactNode } from "react";

const BentoGrid = ({
  children,
  className,
}: {
  children: ReactNode;
  className?: string;
}) => {
  return (
    <div
      className={`grid w-full auto-rows-[22rem] grid-cols-3 gap-4 
        ${className}`}
    >
      {children}
    </div>
  );
};

export default BentoGrid;
