import React from "react";
import { ProfileDT } from "../../../../utils/types";
import EditProfile from "./EditProfile";

const Actions = ({
  team,
  fetchTeams,
}: {
  team: ProfileDT;
  fetchTeams: Function;
}) => {
  return (
    <div className="flex gap-2">
      <EditProfile team={team} fetchTeams={fetchTeams} />
      {/* <DeleteProfile teamId={team.id} fetchTeams={fetchTeams} /> */}
    </div>
  );
};

export default Actions;
