import React, { useMemo } from "react";
import Button from "../../../components/Button";
import { Types } from "./ReportTypesList";
import { ReportTypeEnum } from "../../../utils/types";
import { useMediaQuery } from "react-responsive";
import { Download, SlidersHorizontal } from "lucide-react";
import WelcomeBar from "./WelcomeBar";

const Header = ({
  reportType,
  ExportReport,
  collapseFilters,
}: {
  reportType: ReportTypeEnum;
  ExportReport: Function;
  collapseFilters: Function;
}) => {
  const selectedReport = useMemo(
    () => Types.find((type) => type.type === reportType),
    [reportType]
  );
  const isMobile = useMediaQuery({ maxWidth: "767px" });

  return (
    <div className="flex flex-wrap gap-2 justify-between items-end lg:px-4 max-md:mt-12">
      {isMobile ? <WelcomeBar /> : null}
      <div className="flex gap-2 items-center text-primaryBlue">
        {selectedReport?.Icon}
        <span className="text-2xl font-bold capitalize">
          {selectedReport?.title}
        </span>
      </div>
      <div className="flex gap-2 items-center">
        <Button
          text={isMobile ? undefined : "Sights"}
          variant={"secondary"}
          icon={<SlidersHorizontal size={20} />}
          // onClick={() => ExportReport()}
          onClick={() => collapseFilters()}
          className="!py-2  !m-0"
        />
        <Button
          text={isMobile ? undefined : "Export to CSV"}
          //   variant={"secondary"}
          onClick={() => ExportReport()}
          icon={<Download size={20} />}
          className="!py-2  !m-0"
        />
      </div>
    </div>
  );
};

export default Header;
