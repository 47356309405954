import classNames from "classnames";
import React from "react";

const Card = ({
  Icon,
  title,
  description,
  className,
}: {
  Icon: any;
  title: string;
  description: string;
  className?: string;
}) => {
  return (
    <div className="flex flex-col gap-1">
      <Icon size={20} className="text-primaryBlue" />
      <div className={classNames("max-md:hidden max-w-sm", className)}>
        <h3 className="font-bold">{title}</h3>
        <span className="max-w-sm text-neutral-400 text-sm">{description}</span>
      </div>
    </div>
  );
};

export default Card;
