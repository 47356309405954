import React from "react";
import toast from "react-hot-toast";

const ShowDetails = (id: string) => {
  return (
    <button
      style={{ textDecoration: "underline", cursor: "pointer" }}
      onClick={() => {
        toast.dismiss(id);
      }}
    >
      Details
    </button>
  );
};

export default ShowDetails;
