import classNames from "classnames";
import * as React from "react";

interface ButtonProps {
  text: string;
  className?: string;
}

const SectionBadge: React.FC<ButtonProps> = ({ text, className }) => {
  return (
    <div
      className={
        // "justify-center w-fit min-w-32 px-4 py-2 mb-4 uppercase text-base max-md:text-xs font-body text-center rounded-full border border-primaryBlue text-primaryBlue bg-white"
        "justify-center w-fit min-w-32 px-4 py-2 mb-4 uppercase text-base max-md:text-xs font-body text-center section-badge"
      }
    >
      <div className="blob1"></div>
      <div className="blob2"></div>
      <div className="inner">
        <h2 className={classNames("", className)}>{text}</h2>
      </div>
    </div>
  );
};

export default SectionBadge;
