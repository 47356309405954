import React, { useEffect } from "react";
import SideNavItem from "../../components/ui/Navbar/SideNavItem";
import { GoPackage } from "react-icons/go";
import images from "../../data/images";
import { useMediaQuery } from "react-responsive";
import AuthActions from "../../modules/auth/actions";
import { useAppDispatch } from "../../hooks/useStore";
import AuthSelectors from "../../modules/auth/selectors";
import { Link } from "react-router-dom";
import { cn } from "../../utils/utils";
import { motion } from "framer-motion";
import AvatarDropdown from "./AvatarDropdown";
import {
  ChartPie,
  CircleDollarSign,
  LogOut,
  MessageCircleQuestion,
  Settings,
} from "lucide-react";
import Notification from "../../pages/Dashboard/components/notifications";
import {
  Avatar,
  AvatarFallback,
  AvatarImage,
} from "../../components/ui/avatar";
import SubscriptionBar from "../../pages/Dashboard/components/SubscriptionBar";
import WelcomeBar from "../../pages/Dashboard/components/WelcomeBar";
import svgs from "../../data/svgs";

const Sidebar = () => {
  const { logout } = AuthActions();
  const { user } = AuthSelectors();
  const dispatch = useAppDispatch();
  const [isMenuOpen, setIsMenuOpen] = React.useState(false);
  const menuRef = React.useRef(null);
  const buttonRef = React.useRef(null);
  const isMobile = useMediaQuery({ maxWidth: "769px" });
  const badgeVariation = {
    initial: { opacity: 0, rotate: 0 },
    animate: {
      opacity: 1,
      rotate: 0,
      transition: {
        duration: 0,
        ease: "linear",
      },
    },
    hover: {
      opacity: 1,
      rotate: [0, 100, 200, 360],
      transition: {
        duration: 2,
        repeat: Infinity,
        ease: "linear",
      },
    },
  };
  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };
  const handleOutsideClick = (event) => {
    if (
      menuRef.current &&
      !menuRef.current?.contains(event.target) &&
      event.target !== buttonRef.current
    ) {
      setIsMenuOpen(false);
    }
  };
  useEffect(() => {
    document.addEventListener("mousedown", handleOutsideClick);
    return () => {
      document.removeEventListener("mousedown", handleOutsideClick);
    };
  }, []);

  return (
    <div>
      <nav
        className={cn(
          isMobile
            ? "fixed top-0 z-50  border-b bg-white border-gray-300 w-full"
            : "ml-[17rem] max-w-[calc(100dvw-18rem)] rounded-3xl mt-4"
          // isMobile ? "" : "hidden",
          // "fixed top-0 z-50 w-full bg-white border-b border-gray-200"
        )}
      >
        <div className="px-4 py-1 lg:px-5 lg:pl-3">
          <div className="flex items-center justify-between">
            <div className="flex items-center justify-start rtl:justify-end">
              <button
                ref={buttonRef}
                type="button"
                onClick={toggleMenu}
                className="inline-flex items-center p-2 text-sm text-gray-500 rounded-lg hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-gray-200"
              >
                <span className="sr-only">Open sidebar</span>
                <div className={`hamburger ${isMenuOpen ? "active" : ""}`}>
                  <span className={"bar"}></span>
                  <span className={"bar"}></span>
                  <span className={"bar"}></span>
                </div>
              </button>
              <Link
                to="/"
                className={cn("flex items-center", !isMobile && "hidden")}
              >
                <img
                  loading="lazy"
                  src={images.LogoBadge}
                  className="lg:w-16 w-12"
                  alt="Badge Logo"
                />
              </Link>
            </div>
            {isMobile ? null : <WelcomeBar />}
            <div className="flex lg:gap-2 items-center bg-white lg:border border-gray-200 rounded-full lg:px-4 lg:py-2">
              {/* <Button
                variant={"ghost"}
                className="rounded-full group max-md:px-2"
                onClick={() => navigate("Mail")}
              >
                <FaRegEnvelope
                  className={cn(
                    "size-5 text-gray-400 group-hover:text-primaryBlue group-hover:scale-95",
                    pathname === "/Dashboard/Mail" && "text-primaryBlue"
                  )}
                />
              </Button> */}
              <Notification />
              <AvatarDropdown />
            </div>
          </div>
        </div>
      </nav>
      <aside
        ref={menuRef}
        id="logo-sidebar"
        className={cn(
          "fixed top-0 left-0 flex flex-col justify-between z-[51] w-64 h-screen transition-transform",
          isMenuOpen ? "translate-x-0" : "-translate-x-full",
          "bg-white",
          !isMobile &&
            "translate-x-0 rounded-3xl border border-gray-200 top-[1rem] left-[1rem] w-[15rem] h-[calc(100dvh-2rem)]"
        )}
        aria-label="Sidebar"
      >
        <div className="overflow-y-auto space-y-2">
          <div className="flex gap-2 items-center  mb-4  mt-4">
            <button
              ref={buttonRef}
              type="button"
              onClick={toggleMenu}
              className="inline-flex items-center p-2 text-sm text-gray-500 rounded-lg sm:hidden hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-gray-200"
            >
              <span className="sr-only">Open sidebar</span>
              <div className={`hamburger ${isMenuOpen ? "active" : ""}`}>
                <span className={"bar"}></span>
                <span className={"bar"}></span>
                <span className={"bar"}></span>
              </div>
            </button>
            <Link onClick={toggleMenu} to="/" className="flex items-center">
              <motion.img
                className="lg:w-16 w-12 object-contain"
                alt="Badge Logo"
                loading="lazy"
                src={images.LogoBadge}
                variants={badgeVariation}
                initial="initial"
                animate="animate"
                whileHover="hover"
              />
              <img
                loading="lazy"
                src={images.logoSlogan}
                className="w-24"
                alt="Slogan Logo"
              />
            </Link>
          </div>
          <ul className="space-y-4">
            <li>
              <SideNavItem
                text="Sights"
                onClick={toggleMenu}
                to=""
                Icon={ChartPie}
              />
            </li>
            {/* <li>
              <SideNavItem
                text="Reports"
                onClick={toggleMenu}
                to="Reports"
                Icon={<IoDocumentTextOutline />}
              />
            </li> */}
            {user?.isAdmin ? (
              <li>
                <SideNavItem
                  text="Outsiders"
                  // text="People"
                  onClick={toggleMenu}
                  to="People"
                  Icon={svgs.TeamExternalIcon}
                />
              </li>
            ) : null}
            {/* <li>
              <SideNavItem
                text="Teams"
                onClick={toggleMenu}
                to="Teams"
                Icon={svgs.TeamIcon}
              />
            </li> */}
            <li>
              <SideNavItem
                text="Insiders"
                onClick={toggleMenu}
                to="Teams"
                Icon={svgs.TeamInternalIcon}
              />
            </li>
          </ul>
          <hr className="w-[90%] bg-gray-150" />
          <ul className="pt-4 mt-4 space-y-2 font-medium">
            <li>
              <SideNavItem
                text="Plans"
                onClick={toggleMenu}
                to="plans"
                Icon={GoPackage}
              />
            </li>
            <li>
              <SideNavItem
                text="Billing"
                onClick={toggleMenu}
                to="billing"
                Icon={CircleDollarSign}
              />
            </li>
            {/* <li>
                <SideNavItem
                text="Settings"
                to=""
                Icon={<IoSettings />}
                innerList={[
                  { text: "Settings", to: "Settings" },
                  { text: "Profile", to: "Profile" },
                  { text: "Plans", to: "plans" },
                  { text: "Billing", to: "Billing" },
                ]}
              />
            </li> */}
            <li>
              <SideNavItem
                text="Settings"
                onClick={toggleMenu}
                to="Settings"
                Icon={Settings}
              />
            </li>
          </ul>
        </div>
        <div className="flex flex-col gap-2 border-t px-2 justify-between my-2 cursor-pointer">
          <SideNavItem
            text="Support"
            to="support-tickets"
            onClick={toggleMenu}
            Icon={MessageCircleQuestion}
          />
          {/* <SideNavItem
            text="Help"
            to="/Help"
            onClick={toggleMenu}
            Icon={IoHelpBuoy}
          /> */}
          <Link
            to={"/Dashboard/plans"}
            onClick={toggleMenu}
            className="cursor-pointer p-4 rounded-lg bg-blue-50"
            role="alert"
          >
            <div className="flex items-center mb-3">
              <span className="bg-primaryBlue/10 text-primaryBlue text-sm font-semibold me-2 px-2.5 py-0.5 rounded-full">
                Plan
              </span>
            </div>
            <SubscriptionBar searches={80} />
            {/* <p className="mb-3 text-sm text-blue-800">
              You are on the Free plan. Upgrade to Plus to unlock advanced
              features.
            </p> */}
            <span className="text-sm text-blue-800 underline font-medium hover:text-blue-900">
              Upgrade Now
            </span>
          </Link>
          <div className="flex items-center justify-between cursor-pointer rounded-lg my-2 p-2 transition-all duration-75 hover:bg-gray-150">
            <Link onClick={toggleMenu} to="Profile" className="flex gap-2">
              <Avatar>
                <AvatarImage src={user.picture} alt={user?.fullName} />
                <AvatarFallback>
                  {user?.fullName
                    .split(" ")
                    .map((chunk) => chunk[0])
                    .join("")}
                </AvatarFallback>
              </Avatar>
              <div className="flex flex-col items-start">
                <span className="font-medium text-sm">{user?.fullName}</span>
                <span className="text-xs text-gray-800 truncate">
                  {user.email}
                </span>
              </div>
            </Link>
            {/* <Link to="/get-started"> */}
            <LogOut
              size={25}
              className="text-gray-800"
              onClick={() => dispatch(logout())}
            />
            {/* </Link> */}
          </div>
        </div>
      </aside>
      {isMobile && isMenuOpen ? (
        <div
          className={cn(
            "fixed z-50  size-full transition-colors duration-300 inset-0 ",
            isMenuOpen ? "visible bg-black/20" : "invisible"
          )}
        />
      ) : null}
      {/* {isMenuOpen ? <div className="backdrop-blur" /> : null} */}
    </div>
  );
};

export default Sidebar;
