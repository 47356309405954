import React from "react";
import {
  CardContentFilter,
  FlowEnum,
  IChartDetails,
  IVolume,
} from "../../../../utils/types";
import {
  ChartConfig,
  ChartContainer,
  ChartLegend,
  ChartLegendContent,
  ChartTooltip,
  ChartTooltipContent,
} from "../../../../components/ui/chart";
import {
  Bar,
  CartesianGrid,
  ComposedChart,
  LabelList,
  Line,
  XAxis,
} from "recharts";
import Wrapper from "./Wrapper";
import { useMediaQuery } from "react-responsive";

const chartConfig = {
  currentPeriod: {
    label: "Current Period",
  },
  lastPeriod: {
    label: "Last Period",
  },
  yearlyAvg: {
    label: "Yearly Avg",
  },
} satisfies ChartConfig;
const Timing = ({
  className,
  filters,
  details,
}: {
  className?: string;
  filters: CardContentFilter;
  details: IChartDetails<IVolume>;
}) => {
  const isMobile = useMediaQuery({ maxWidth: "767px" });
  const lsLastPeriod = filters.flow === FlowEnum.OUTFLOW ? "out" : "in";
  const { data, error, loading } = details;
  return (
    <Wrapper
      className={className}
      cardFilters={filters}
      loading={loading}
      error={error}
      content={
        <ChartContainer
          config={chartConfig}
          className="max-md:h-[200px] max-md:w-[270px]"
        >
          <ComposedChart
            accessibilityLayer
            data={data}
            margin={{
              top: 20,
            }}
          >
            <CartesianGrid vertical={false} />
            <XAxis
              dataKey="date"
              tickLine={false}
              tickMargin={8}
              axisLine={false}
              // tickFormatter={(value) => value.slice(0, 3)}
            />
            {/* <YAxis tickLine={false} tickMargin={8} axisLine={false} /> */}
            <ChartTooltip
              cursor={false}
              content={<ChartTooltipContent indicator="line" />}
            />
            <Bar
              dataKey="currentPeriod"
              fill={`var(--chart-current-period-${lsLastPeriod})`}
              radius={4}
            >
              {isMobile ? null : (
                <LabelList
                  position="top"
                  offset={12}
                  className="fill-foreground"
                  fontSize={12}
                />
              )}
            </Bar>

            <Bar
              dataKey="lastPeriod"
              fill={`var(--chart-last-period-${lsLastPeriod})`}
              radius={4}
            >
              {isMobile ? null : (
                <LabelList
                  position="top"
                  offset={12}
                  className="fill-foreground"
                  fontSize={12}
                />
              )}
            </Bar>
            <Line
              type="monotone"
              dataKey="yearlyAvg"
              stroke={`var(--chart-yearly-avg-${lsLastPeriod})`}
              strokeWidth={1.5}
              // hide
              // dot={false}
            />
            {/* <ReferenceLine
              y={166}
              strokeWidth={1.5}
              stroke={`var(--chart-yearly-avg-${lsLastPeriod})`}
            /> */}
            <ChartLegend content={<ChartLegendContent />} />
          </ComposedChart>
        </ChartContainer>
      }
    />
  );
};

export default Timing;
