import React from "react";
import SectionBadge from "../components/SectionBadge";
import SectionTitle from "../components/SectionTitle";
import { motion } from "framer-motion";
import classNames from "classnames";

type SectionProps = {
  children: React.ReactNode;
  id?: string;
  isPage?: boolean;
  className?: string;
  innerClassName?: string;
  badge?: string;
  title?: string;
  placeholder?: string;
};

const Section: React.FC<SectionProps> = ({
  children,
  className,
  innerClassName,
  id,
  isPage,
  badge,
  title,
  placeholder,
  ...props
}) => {
  return (
    <section
      className={classNames(
        "lg:mt-20 mt-16 pb-12 sm:pb-28 lg:pb-36 flex",
        isPage ? "lg:pt-[8rem] pt-[6rem]" : "",
        className
      )}
      {...props}
      id={id ? id : ""}
    >
      <motion.div
        initial={{ opacity: 0 }}
        whileInView={{ opacity: 1 }}
        transition={{ duration: 1 }}
        className={classNames(innerClassName, "flex-1 w-full")}
      >
        <div
          className={classNames(
            "flex flex-col items-center justify-center w-full",
            badge || title || placeholder ? "" : "hidden"
          )}
        >
          <div className="flex flex-col items-center justify-center max-w-[90%] lg:max-w-3xl">
            {badge ? <SectionBadge text={badge} /> : null}
            {title ? <SectionTitle title={title} /> : null}
            {placeholder ? (
              <p className="mt-4 text-[1rem] lg:text-xl text-center tracking-tight text-gray-600">
                {placeholder}
              </p>
            ) : null}
          </div>
        </div>
        {children}
      </motion.div>
    </section>
  );
};

export default Section;
