import React from "react";
import { Skeleton } from "../../../components/ui/skeleton";
import { CgSpinner } from "react-icons/cg";

const LoadingChart = () => {
  return (
    <div className="relative">
      <div className="flex max-md:flex-col max-md:text-center  max-w-[70%]  gap-2 items-center absolute z-10 inset-0 justify-self-center self-center">
        <CgSpinner className="animate-spin size-6 text-primaryBlue" />
        <h1 className="animate-shine text-sm text-placeholder md:leading-4 text-wrap">
          Please wait while we set things up for you...
          <br />
          this might take a few seconds.
        </h1>
      </div>
      <Skeleton className="w-full min-h-[250px] rounded-xl" />
    </div>
  );
};

export default LoadingChart;
