import React from "react";
import Section from "../../layouts/Section";
import SectionBadge from "../../components/SectionBadge";
type BlogProps = {
  id: string | number;
  title: string;
  href: string;
  image?: string;
  description: string;
  date: string;
  datetime: string;
  category: { title: string; href: string };
  author: {
    name: string;
    role: string;
    href: string;
    imageUrl: string;
  };
};
const posts: BlogProps[] = [
  {
    id: 1,
    title: "Blog 1",
    href: "#",
    image:
      "https://images.unsplash.com/photo-1518770660439-4636190af475?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80",
    description:
      "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.",
    date: "Mar 16, 2020",
    datetime: "2020-03-16",
    category: { title: "Marketing", href: "#" },
    author: {
      name: "Michael Foster",
      role: "Co-Founder / CTO",
      href: "#",
      imageUrl:
        "https://images.unsplash.com/photo-1519244703995-f4e0f30006d5?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80",
    },
  },
  {
    id: 2,
    title: "Blog 2",
    href: "#",
    image:
      "https://images.unsplash.com/photo-1496128858413-b36217c2ce36?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=3603&q=80",
    description:
      "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.",
    date: "Mar 16, 2020",
    datetime: "2020-03-16",
    category: { title: "R&D", href: "#" },
    author: {
      name: "Michael Foster",
      role: "Co-Founder / CTO",
      href: "#",
      imageUrl:
        "https://images.unsplash.com/photo-1519244703995-f4e0f30006d5?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80",
    },
  },
  {
    id: 2,
    title: "Blog 3",
    href: "#",
    image:
      "https://images.unsplash.com/photo-1547586696-ea22b4d4235d?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=3270&q=80",
    description:
      "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.",
    date: "Mar 16, 2020",
    datetime: "2020-03-16",
    category: { title: "Security", href: "#" },
    author: {
      name: "Michael Foster",
      role: "Co-Founder / CTO",
      href: "#",
      imageUrl:
        "https://images.unsplash.com/photo-1519244703995-f4e0f30006d5?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80",
    },
  },

  // More posts...
];
const BlogPost = ({ post }: { post: BlogProps }) => {
  const { id, datetime, date, image, author, title } = post;
  return (
    <article
      key={`${id}-blog`}
      className="cursor-pointer flex max-w-xl flex-col rounded-xl items-end overflow-clip justify-between relative"
    >
      <div className="size-full gradient bg-gradient-to-t from-black/70 to-black/5 z-10 pt-[12rem] lg:pt-[20rem] pb-4 px-4">
        <div className="flex flex-col gap-y-2 text-xs gap-x-2 text-gray-500">
          <div className="flex items-center gap-x-2">
            <img
              loading="lazy"
              alt=""
              src={author.imageUrl}
              className="size-8 rounded-full bg-gray-50"
            />
            <span>{author.name}</span>
          </div>
          <time dateTime={datetime}>{date}</time>
        </div>
        <h3 className="text-white text-lg font-semibold mt-2">{title}</h3>
      </div>
      <img
        loading="lazy"
        src={image}
        alt={title}
        className="object-cover absolute z-0 size-full "
      />
    </article>
  );
};
const Article = ({ post }: { post: BlogProps }) => {
  const { id, datetime, date, category, author, href, title, description } =
    post;
  return (
    <article
      key={`${id}-article`}
      className="flex max-w-xl flex-col items-start justify-between"
    >
      <div className="flex items-center gap-x-4 text-xs">
        <time dateTime={datetime} className="text-gray-500">
          {date}
        </time>
        <a
          href={category.href}
          className="relative z-10 rounded-full bg-gray-50 px-3 py-1.5 font-medium text-gray-600 hover:bg-gray-100"
        >
          {category.title}
        </a>
      </div>
      <div className="group relative">
        <h3 className="mt-3 text-lg font-semibold leading-6 text-gray-900 group-hover:text-gray-600">
          <a href={href}>
            <span className="absolute inset-0" />
            {title}
          </a>
        </h3>
        <p className="mt-5 line-clamp-3 text-sm leading-6 text-gray-600">
          {description}
        </p>
      </div>
      <div className="relative mt-8 flex items-center gap-x-4">
        <img
          loading="lazy"
          alt=""
          src={author.imageUrl}
          className="h-10 w-10 rounded-full bg-gray-50"
        />
        <div className="text-sm leading-6 flex flex-col">
          <a href={author.href} className="font-semibold text-gray-900">
            <span className="absolute inset-0" />
            {author.name}
          </a>
          <span className="text-gray-600">{author.role}</span>
        </div>
      </div>
    </article>
  );
};
const Blogs = () => {
  return (
    <Section>
      <div className="mx-auto max-w-7xl">
        <SectionBadge text={"Blog"} className="!px-8" />
        <div className="mx-auto max-w-2xl lg:mx-0">
          <h2 className="text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">
            Latest Articles
          </h2>
          <p className="mt-2 text-lg leading-8 text-gray-600">
            Learn how to grow your business with our expert advice.
          </p>
        </div>
        <div className="mx-auto grid max-w-2xl grid-cols-1 gap-8 pt-10 sm:pt-16 lg:mx-0 lg:max-w-none lg:grid-cols-3">
          {posts.map((post: BlogProps, index: number) => (
            <BlogPost post={post} key={index} />
          ))}
        </div>
        <div className="mx-auto mt-10 grid max-w-2xl grid-cols-1 gap-x-8 gap-y-16 border-t border-gray-200 pt-10 sm:mt-16 sm:pt-16 lg:mx-0 lg:max-w-none lg:grid-cols-3">
          {posts.map((post: BlogProps, index: number) => (
            <Article post={post} key={index} />
          ))}
        </div>
      </div>
    </Section>
  );
};

export default Blogs;
